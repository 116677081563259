$side_padding: 0 20px;
$navbar_width: 242px;


.is-navsidebar{
	.site{
		padding-left: $navbar_width;
	}
}

.navsidebar{
	background: #ffffff;
	border-color: rgba(0,0,0,.1);
	color: rgba(0,0,0,.75);
	z-index: 99999;
	position: fixed;
	top: 0;
	left: 0;
	outline: 0;
	backface-visibility: hidden;
	box-sizing: border-box;
	box-shadow: 0px 1px 5.95px 1.05px rgba(100, 100, 100, 0.08);
    transition-property: transform;
    transition-duration: 0.3s;
    transition-delay: 0.2s;
    transition: all .6s ease-in-out;
	.navbar-wrap{
		display: flex;
		flex-direction: column;
		height: 100vh;
		width: calc(#{$navbar_width} - 2px);
	}

	.top-header {
		position: relative;
		padding: $side_padding;
		margin-top: 25px;
		.login {
			position: absolute;
			right: 0px;
			top: 0;
			background: #ebf3ff;
			width: 50px;
			height: 50px;
			text-align: center;
			line-height: 50px;
			color: #17b212;
			a {
				color: #17b212;
				font-size: 20px;
			}
		}
	}

	.navbar--nav{
		padding: $side_padding;
		margin: 45px 0;
		.submenu{
			display: none;
			margin-left: 38px;
			margin-top: 10px;
		}
	}
	ul{
		li{
			margin-bottom: 10px;
			a{
				color: #63799b;
				font-size: 14px;
				@include font-three-3;
				@extend .font-primary;
				display: block;
				&.link{
					position: relative;
					&:after{
						position: absolute;
						content: '\f1b8';
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						font-weight: 900;
						font-family: 'Font Awesome\ 5 Free';
					}
				}
			}
			i{
				font-size: 16px;
				margin-right: 22px;
				color: #63799b;
			}
		}
	}

	.bottom-header{
		padding: $side_padding;
		margin-top: auto;
		.contact-num{
			margin-bottom: 50px;
			position: relative;
			padding-left: 40px;
			._icon{
				height: 40px;
				width: 40px;
				line-height: 40px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				border: 1px dashed #8a9ebc;
				text-align: center;
				border-radius: 50%;
				color: #8a9ebc;
			}
			a{
				font-size: 14px;
				color: #8a9ebc;

				&:not(:last-child) {
					padding-bottom: 5px;
					margin-bottom: 5px;
					border-bottom: 1px dashed #8a9ebc;
				}
			}
		}
		.whm-thumb{
			margin-bottom: 40px;
			text-align: center;
		}
	}
	.live-chat-wrap{
		padding-left: 1px;
		a{
			display: block;
			background-color: rgb(23, 178, 18);
			position: relative;
			text-align: center;
			color: #fff;
			padding: 16px;
			@extend .font-primary;
			font-weight: 500;
			i{
				position: absolute;
				top: 50%;
				right: 16px;
				transform: translateY(-50%);
			}
			&:after{
				position: absolute;
				content: '';
				top: 50%;
				left: 100%;
				transform: translateY(-50%);    
				height: 0;
				width: 0;
				border-top: 12px solid transparent;
				border-left: 12px solid;
				border-left-color: rgb(23, 178, 18);
				border-bottom: 12px solid transparent;
			} 
		}
	}
}

.moibe_nav_toggler_1{
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: 500;
	background: #000;
	color: #fff;
	border: 0;
	height: 60px;
	width: 60px;
	border-radius: 0 50% 50% 50%;
	.wrap{
		position: relative;
		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&:focus{
		outline: 0;
	}
}


// Search Modal 
#navbar_search_modal{
	.input-group{
		margin-bottom: 0!important;
		border: 1px solid #63799b;
		border-radius: 4px;
		input{
			padding: 0 15px;
		}
		button{
			border: 0;
			border-left: 1px solid #63799b;
		}
	}
	.form-control:focus {
		border-bottom: 0;
	}
}

@media (min-width: 576px){
	#navbar_search_modal{
		.modal-dialog {
			max-width: 700px;
		}
	}
}