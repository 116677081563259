.faq_1_tab_wrap{
    display: flex;
    flex-wrap: wrap;
    .nav{
        flex-direction: column;
        width: 100%;
        max-width: 25%;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1),inset 0px 8px 33.82px 4.18px rgba(221, 234, 255, 0.39);
        padding: 31px 35px;
        li{
            border-bottom: 1px solid #f4f2f2;
            &:last-child{
                border-bottom: 0;
            }
        }
        a{
            font-size: 15px;
            @extend .font-primary;
            font-weight: 600;
            color: #67778e;
            display: flex;
            align-items: center;
            padding: 15px 0;
            &.active,
            &:hover{
                @extend .main-two;
            }
            img{
                width: 26px;
                margin-right: 15px;
            }
        }
    }
    .tab-content{
        width: 100%;
        max-width: 75%;
        padding-left: 30px;
        .content_wrap{
            display: flex;
            .item-img{
                width: 100%;
                max-width: 40%;
                img{
                    width: 100%
                }
            }
            .item-detail{
                padding-left: 30px;
                width: 100%;
                max-width: 60%;
                h4{
                    margin-top: -5px;
                    font-size: 26px;
                    color: #2a384c;
                    font-weight: 800;
                    margin-bottom: 34px;
                }
                p{
                    line-height: 30px;
                    font-size: 15px;
                }
                ul{
                    margin: 32px 0;
                    li{
                        position: relative;
                        padding-left: 20px;
                        font-size: 15px;
                        color: #67778e;
                        font-weight: 600;
                        margin: 15px 0;
                        &:before{
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            height: 7px;
                            width: 7px;
                            border-radius: 50%;
                            background: #565a5f;
                        }
                    }
                }
            }
        }
    }
}

@include mq-max(1449px) {
    .faq_1_tab_wrap{
        .nav{
            max-width: 100%;
            flex-direction: row;
            margin-bottom: 50px;
            li{
                border: 0;
                padding: 0 15px;
                position: relative;
                &::after{
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    transform: translate(50%, -50%);
                    height: 25px;
                    width: 1px;
                    background: #f4f2f2;
                }
                &:last-child{
                    &::after{
                        content: none;
                    }
                }
            }
        }
        .tab-content{
            max-width: 100%;
            padding-left: 0;
        }
    }
}
@include mq(992px, 1199px) {
    .faq_1_tab_wrap {
        .tab-content {
            .content_wrap{
                .item-img{
                    max-width: 100%;
                }
                .item-detail{
                    h4{
                        margin-bottom: 20px;
                    }
                    ul{
                        margin: 28px 0;
                    }
                }
            }
        }
    }
}

@include mq-max(757px) {
    .faq_1_tab_wrap {
        .nav{
            padding: 30px 20px;
        }
        .tab-content {
            .content_wrap{
                flex-direction: column;
                .item-img{
                    max-width: 100%;
                }
                .item-detail{
                    padding-left: 0;
                    margin-top: 30px;
                    max-width: 100%;
                    h4{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .faq_1_tab_wrap {
        .nav {
            padding: 30px 15px;
        }
        .tab-content .content_wrap .item-detail h4{
            font-size: 23px;
        }
    }
}