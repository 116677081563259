.pricing_2{
	background: url('../../media/images/bg/price_bg.png') no-repeat;
	background-position: center right;
	.toggle-btn-1{
		float: left;
		margin-top: 20px;
	}
	.inner-content-box{
		background: url('../../media/images/bg/snow_circle.png') no-repeat;
		background-position: bottom center;
	}
}

.single-pricing-2{
	box-shadow: 0px 5px 27px 0px rgba(151, 151, 151, 0.15);
	padding: 55px 0;
	text-align: center;
	border-radius: 5px;
	background: #fff;
	h6{
		font-size: 20px;
		color: #2a384c;
		font-weight: 900;
		@extend .font-heading;
		text-transform: uppercase;
		margin-bottom: 35px;

	}
	.price-wrap{
		background: #f7fcff;    
		padding: 12px 15px;
	}
	.price{
		font-size: 50px;
		font-weight: 800;
		@extend .font-primary;
		@extend .main-color;
		transition: all .3s linear;
		sup{
			font-size: 30px;
			color: #2a384c;
		}
		sub{
			@extend .font-primary;
			color: #67778e;
			font-size: 15px;
			font-weight: 600;
			bottom: 0px;
			left: -9px;
		}
	}
	.package-info-list {
		padding: 0 55px;
		margin-top: 30px;
		li{
			color: #67778e;
			text-transform: capitalize;
			padding: 10px 0;
			text-align: center;
			position: relative;
			span{
				font-weight: 600;
			}
			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background: linear-gradient(to right, transparent 0%, #dceaff 50%, transparent 100%)
			}
		}
	}
	> p{
		color: #808c9e;
		padding: 0 25px;
		margin-bottom: 30px;

	}
	.btn-3{
		margin-top: 40px;
		padding: 7px 25px;
		font-size: 14px;
		width: auto;
		font-weight: 600;
	}
	.monthly{
		display: block;
	}
	.yearly{
		display: none;
	}
	&:hover{
		.price{
			@extend .main-two;
		}
		.btn-3 {
			background: $main_two;
			color: #fff;
		}
	}
	
}


.single-pricing-2.yearly{
	.monthly{
		display: none;
	}
	.yearly{
		display: block;
	}
}

@include mq(992px, 1449px) {
	.pricing_2 {
		.section_header h3 {
			font-size: 28px;
		}
	}
}

@include mq-max(1199px) {
	.single-pricing-2 {
		margin-top: 50px;
	}
}