.center .single-testimonial-2{
	text-align: center;
	padding: 50px 40px;
	background: #fff;
	box-shadow: 0px 1px 13px 0px rgba(209, 206, 206, 0.36);
	border-radius: 15px;
	position: relative;
	overflow-x: hidden;
	i{
		text-align: center;
		color: $main_three;
		font-size: 28px;
	}
	p{
		color: #808c9e;
		font-size: 15px;
		@include font-three-6;
		text-align: center;
		margin-top: 25px;
	}
	.bottom-content{
		text-align: left;
		overflow: hidden;
		margin-top: 25px;
		margin-left: 22px;
		.tes-image{
			float: left;
			border-radius: 50px;
			overflow: hidden;
			border: 2px solid #0b67f4;
			padding: 3px;
			margin-right: 20px;
			img{
				border-radius: 50px;
			}
		}
		h6{
			color: #2a384c;
			font-size: 16px;
			margin-top: 7px;

		}
		span{
			font-size: 14px;
			color: #808c9e;
		}
	}
}

.single-testimonial-2{
	text-align: center;
	padding: 50px 40px;
	background: #fcfdff;
	box-shadow: 0px 1px 13px 0px rgba(209, 206, 206, 0.36);
	border-radius: 15px;
	position: relative;
	@include transition(all .3s);
	i{
		text-align: center;
		color: #95aefc;
		font-size: 28px;
	}
	p{
		color: #808c9e;
		font-size: 15px;
		@include font-three-6;
		text-align: center;
		margin-top: 25px;
	}
	.bottom-content{
		text-align: left;
		overflow: hidden;
		margin-top: 25px;
		margin-left: 22px;
		.tes-image{
			float: left;
			border-radius: 50px;
			overflow: hidden;
			border: 2px solid #0b67f4;
			padding: 3px;
			margin-right: 20px;
			img{
				border-radius: 50px;
			}
		}
		h6{
			color: #9199a5;
			font-size: 16px;
			margin-top: 7px;


		}
		span{
			font-size: 14px;
			color: #9aa1ac;
		}
	}
}

.single-testimonial-2::after {
	content: '';
	background: #fff;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0.3;
	z-index: 99999;
	border-radius: 12px;
}

.testimonial-2 .center .single-testimonial-2::after{
	opacity:0;
}

.testimonial-2 .item-wrap {
	padding: 5px 15px;
}
.testimonial-2.owl-theme .owl-nav [class*="owl-"] {
	color: $main_three;
	font-size: 28px;
	margin: 5px;
	padding: 0;
	background: transparent;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	top: 37%;
	border: 1px solid $main_three;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	line-height: 50px;
}
.testimonial-2.owl-theme .owl-nav .owl-next {
	right: -200px;
}
.testimonial-2.owl-theme .owl-nav .owl-prev {
	left: -200px;
}

.testimonial-2.owl-theme .owl-nav [class*="owl-"]:hover {
	background: transparent;
	color: $main_three;
}

.testimonial-2 .owl-item.center{
	transform: translateY(-70px);
	@include transition(all .3s);
}

.testimonial-2 .owl-item{
	padding-top: 80px;
	@include transition(all .3s);
}

@include mq-max(991px) {
	.testimonial-2 .owl-item{
		padding-top: 0;
	}
}

/*--------------------------------------------------------------
  ##  Testimonial four
  --------------------------------------------------------------*/


  .testimonial_4{
  	background: #f8faff;
  }



.single-testimonial-4{
	text-align: center;
	padding: 50px 40px;
	background: #fff;
	box-shadow: 0px 1px 13px 0px rgba(209, 206, 206, 0.36);
	border-radius: 15px;
	position: relative;
	@include transition(all .3s);
	i{
		text-align: center;
		color: #3263f9;
		font-size: 28px;
	}
	p{
		color: #67778e;
		font-size: 15px;
		@include font-three-6;
		text-align: center;
		margin-top: 25px;
	}
	.bottom-content{
		overflow: hidden;
		margin-top: 25px;
		margin-left: 22px;
		.tes-image{
			float: left;
			border-radius: 50px;
			overflow: hidden;
			border: 2px solid #0b67f4;
			padding: 3px;
			margin-right: 20px;
			img{
				border-radius: 50px;
			}
		}
		h6{
			color: #2a384c;
			font-size: 16px;
			margin-top: 7px;
			font-weight: 700;


		}
		span{
			font-size: 14px;
			color: #67778e;
		}
	}
}




.testimonial-4.owl-theme .owl-nav [class*="owl-"] {
	color: $main_three;
	font-size: 28px;
	margin: 5px;
	padding: 0;
	background: transparent;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	top: 50%;
	border: 1px solid $main_three;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	line-height: 42px;
	transform: translateY(-50%);
}
.testimonial-4.owl-theme .owl-nav .owl-next {
	right: -110px;
}
.testimonial-4.owl-theme .owl-nav .owl-prev {
	left: -110px;
}

.testimonial-4.owl-theme .owl-nav [class*="owl-"]:hover {
	background: transparent;
	color: $main_three;
}

.testimonial-4 .owl-item.center{
	transform: translateY(-70px);
	@include transition(all .3s);
}

.testimonial-4 .owl-item{
	@include transition(all .3s);
}
.testimonial-4.owl-theme .owl-nav .owl-next img {
	transform: rotate(180deg);
}





@include mq(1200px, 1429px) {
	.center .single-testimonial-2 {
		padding: 50px 25px;
	}
}

@include mq(992px, 1199px) {
	.center .single-testimonial-2 {
		padding: 50px 25px;
	}
	.center .single-testimonial-2 .bottom-content,
	.single-testimonial-2 .bottom-content{
		margin-left: 0;
		text-align: center;
	}
	.center .single-testimonial-2 .bottom-content .tes-image,
	.single-testimonial-2 .bottom-content .tes-image{
		width: 66px;
		float: none;
		margin: 0 auto;
	}
}
@include mq-max(1199px) {
	.testimonial-4{
		.owl-nav{
			opacity: 0;
			transition: .3s ;
		}
		&:hover{
			.owl-nav{
				opacity: 1;
			}
		}
	}
	.testimonial-4.owl-theme .owl-nav .owl-next {
		right: 15px;
	}
	.testimonial-4.owl-theme .owl-nav .owl-prev {
		left: 15px;
	}
}
@include mq-max(575px) {
	.single-testimonial-2 {
		padding: 50px 25px;
	}
	.center .single-testimonial-2 .bottom-content,
	.single-testimonial-2 .bottom-content{
		margin-left: 0;
		text-align: center;
	}
	.center .single-testimonial-2 .bottom-content .tes-image,
	.single-testimonial-2 .bottom-content .tes-image{
		width: 66px;
		float: none;
		margin: 0 auto;
	}
	.single-testimonial-4 {
		padding: 40px 20px;
	}
}