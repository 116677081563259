.pricing_1 {
	position: relative;
}
.single-pricing {
    overflow: hidden;
    border-radius: 6px;
    position: relative;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 5px;
		background: $main_color;
		z-index: 99;
		&:hover:after {
			@extend .main-two-bg;
			transition: all 0.3s ease-in-out;
		}
	}
    .top-box {
        padding: 50px 40px;
        border-radius: 6px;
        background-color: rgb(255, 255, 255);
        position: relative;

        h5 {
            text-transform: uppercase;
            font-weight: 700;
            @extend .main-color;
        }

        p {
            @include font-three-4;
            color: $para_color;
            font-size: 15px;
        }
        .inner-box {
            width: 46%;
            float: left;
            overflow: hidden;

            i {
                font-size: 33px;
            	@extend .main-color;
                float: right;
            }
        }
        .inner-box-left {
            width: 54%;
            float: left;
            overflow: hidden;
        }
        .top-heading {
            overflow: hidden;
            padding-bottom: 60px;
        }
        .price-toggle {
            overflow: hidden;
            .montly-price {
                width: 50%;
                float: left;
                span {
                    font-size: 14px;
                    @include font-one-6;
                    color: #83868b;
                    padding-left: 18px;
                    text-decoration: line-through;
					sup{
						top: 0;
					}
                }
                p {
                    font-size: 24px;
                    @include font-one-8;
                    color: $main_color;
					sup{
						top: 0;
					}
                }
                display:none;
            }

            .yearly-price {
                width: 50%;
                float: left;

                span {
                    font-size: 14px;
                    @include font-one-6;
                    color: #83868b;
                    padding-left: 18px;
                    text-decoration: line-through;
					sup{
						top: 0;
					}
                }

                p {
                    font-size: 24px;
                    @include font-one-8;
                    color: $main_color;
					sup{
						top: 0;
					}
                }
            }

            .tog-button {
                overflow: hidden;
                text-align: right;

                p {
                    margin: 0;
                    color: $sub_head_two;
                    font-size: 15px;
                    text-transform: capitalize;

                }

                .monthly {
                    display: none
                }

            }
        }
		.order-now {
			font-size: 16px;
			color: #fff;
			padding: 10px 27px;
			border-radius: 26px;
			font-weight: 500;
			position: absolute;
			left: 50%;
			transform: translate(-50%, 50%);
			bottom: 0;
			z-index: 50;
			@extend .main-color-bg;
			text-transform: uppercase;
			box-shadow: 0px 1px 11.05px 1.95px rgba(100, 100, 100, 0.18);
			border: 3px solid #fff;
		}
    }

    .bottom-box {
        padding: 75px 40px 50px;
        background-color: #effbfd;
        position: relative;

        .package-info-list {
            ul {
                li {
                    color: $sub_head_two;
                    text-transform: capitalize;
                    margin-bottom: 20px;

                    i {
                        font-size: 15px;
                        margin-right: 10px;
                    }

                }
            }
        }

	}
	// Hover
	&:hover {

		.top-box {
			h5 {
				@extend .main-two;
			}
			.inner-box {
				i {
	
					@extend .main-two;
	
				}
			}
			.price-toggle {
				.montly-price {
					p {
						@extend .main-two;
	
					}
	
				}
				.yearly-price {
	
					p {
	
						@extend .main-two;
	
					}
				}
	
			}
			.order-now {
				background: $main_two;
			}
		}
		.toggle-btn {
			border: 1px solid $main_two;
		}
		.toggle-btn .round-btn {
			@extend .main-two-bg;
		}
	}
}

.price-toggle.active {
    .montly-price {
        display: block !important;
    }
    .yearly-price {
        display: none;
    }
    .tog-button {
        .monthly {
            display: block !important
        }
        .yearly {
            display: none;
        }

    }

}



.toggle-btn {
    width: 50px;
    height: 21px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    border: 1px solid $main_color;
    cursor: pointer;
    -webkit-transition: background-color .40s ease-in-out;
    -o-transition: background-color .40s ease-in-out;
    transition: background-color .40s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-align: right;

    .round-btn {
        width: 15px;
        height: 15px;
        background-color: $main_color;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 31px;
        top: 17px;
        margin-top: -15px;
        -webkit-transition: all .30s ease-in-out;
        -o-transition: all .30s ease-in-out;
        transition: all .30s ease-in-out;
    }

    .round-btn.active {
        left: 2px;
    }

    .cb-value {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
        cursor: pointer;
        filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

@include mq(1200px, 1439px) {
	.single-pricing {
		.top-box {
			padding: 38px 30px;
			border-radius: 6px;
			.inner-box-left{
				width: 71%;
			}
			.inner-box {
				width: 29%;
				i{
					font-size: 25px;
				}
			}
			h5{
				font-size: 17px;
			}
			p{
				margin-bottom: 0px;
				font-size: 14px;
			}
			.order-now {
				font-size: 14px;
				padding: 10px 20px;
			}
		}
		.bottom-box {
			padding: 75px 30px 50px;
			.package-info-list ul li {
				font-size: 15px;
			}
		}
	}
}

@include mq-max(991px) {
    .single-pricing {
		.top-box {
			padding: 38px 30px;
			border-radius: 6px;
			.inner-box-left{
				width: 71%;
			}
			.inner-box {
				width: 29%;
				i{
					font-size: 25px;
				}
			}
			h5{
				font-size: 17px;
			}
			p{
				margin-bottom: 0px;
				font-size: 14px;
			}
			.order-now {
				font-size: 14px;
				padding: 10px 20px;
			}
		}
		.bottom-box {
			padding: 75px 30px 50px;
			.package-info-list ul li {
				font-size: 15px;
			}
		}
	}
}