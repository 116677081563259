/*!
  Theme Name: Comercio
  Theme URI: themeim.com
  Author: ThemeIM
  Description: Hosting Providers
  Version: 1.0.0
  Tags: Domain, Hosting, vps hosting, wordpress hosting
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Mobile Menu
    ## Dropdown menu
    ## Mega menu area
    ## Header 2
    ## Navbar Three
    ## Slider
    ## Slider Style two
    ## Slider Style three
    ## Product Banner
    ## Product Filter
    ## Product Filter Style 2
    ## Product three
    ## Features
    ## Banner and  Product area
    ## Banner and  Product area 2
    ## Countdown
    ## Small Product
    ## category
    ## logo_carousel
    ## testimonial
    ## breadcrumb
    ## Shop Page
    ## Product Page
    ## Cart Page
    ## Blog Page
    ## Blog sidebar
    ## Contact page
    ## Quickview
    ## Starting popup news letter
    ## Subscribe
    ## Instagram
    ## Footer
    ## Footer style 2
    ## Backtotop
    ## Responsive




  --------------------------------------------------------------*/
@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Open+Sans:300i,300,400,600,700,800|Poppins:300,400,500,600,700,800,900";
:root {
  --main-color: #197beb;
}

/* Font  */
@-webkit-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-ms-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-o-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  -webkit-animation: slide-down 5s 3;
  -moz-animation: slide-down 5s 3;
  -ms-animation: slide-down 5s 3;
  -o-animation: slide-down 5s 3;
  animation: slide-down 5s 3;
}

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.main-color, .header-two .menu-2 .submenu li a:hover, .mobile-navbar-wrap .mobile-menu .submenu li a, .mobile-navbar-wrap .mobile-menu-close, .mobile-navbar-wrap .mobile-footer form button, .single-pricing .top-box h5, .single-pricing .top-box .inner-box i, .single-pricing-2 .price, .map-location .nav-tabs .nav-item a span, .comment-list .comment-item .comment-content .name-wrap .date, .comment-list .comment-item .comment-content .reply {
  color: #197beb;
}

.main-color-bg, .single-pricing .top-box .order-now, .hosting_plan_content_item .detail-item .item-detail h4:after {
  background-color: #197beb;
}

.main-color-border {
  border-color: #197beb;
}

.main-two, .single-mega-menu:hover .mega-menu-content h6, .mobile-navbar-wrap .mobile-menu > li > a:hover, .mobile-navbar-wrap .mobile-menu > li > a:hover:after, .mobile-navbar-wrap .mobile-menu .caret-dropdown.open > a, .mobile-navbar-wrap .mobile-menu .caret-dropdown.open > a:after, .mobile-navbar-wrap .mobile-footer form button:hover, .mobile-navbar-wrap .mobile-footer .social_link ul li a:hover, .single-pricing:hover .top-box h5, .single-pricing:hover .top-box .inner-box i, .single-pricing:hover .top-box .price-toggle .montly-price p, .single-pricing:hover .top-box .price-toggle .yearly-price p, .single-pricing-2:hover .price, .single-pricing-four.active h3,
.single-pricing-four.active h4, .single-pricing-four:hover h3,
.single-pricing-four:hover h4, .working-items li:hover h4, .faq_1_tab_wrap .nav a.active, .faq_1_tab_wrap .nav a:hover {
  color: #00c1ff;
}

.main-two-bg, .banner_5_content form button, .single-pricing:hover .toggle-btn .round-btn, .single-pricing-four .badge, .single-pricing-four.active:before, .single-pricing-four:hover:before, .single-pricing-four.active:after, .single-pricing-four:hover:after, .single-pricing-four.active .btn-3, .single-pricing-four:hover .btn-3, .hosting_plan_tab .nav-tabs li a:hover, .hosting_plan_tab .nav-tabs li a.active {
  background-color: #00c1ff;
}

.main-two-border {
  border-color: #00c1ff;
}

.main-three {
  color: #0b67f4;
}

.main-three-bg {
  background-color: #0b67f4;
}

.main-three-border {
  border-color: #0b67f4;
}

.main-four {
  color: #85e0fd;
}

.main-four-bg, .single-feature-style-4:hover:before {
  background-color: #85e0fd;
}

.main-four-border {
  border-color: #85e0fd;
}

.font-primary, p, .navsidebar ul li a, .navsidebar .live-chat-wrap a, .banner_5_content form input, .banner_5_content ul li h5, .banner_5_content ul li .detail, .single-pricing-2 .price, .single-pricing-2 .price sub, .single-pricing-four h3, .single-pricing-four ul li, .widget-post .post-content h5, .related-article-items .article-item .public-date, .comment-list .comment-item .comment-content .name-wrap a, .footer-two .footer-address li, .faq_1_tab_wrap .nav a {
  font-family: "OpenSans", sans-serif;
}

.font-heading, h1, h2, h3, h4, h5, h6, .banner_5_content h1, .banner_5_content form button, .bb-breadcrumb .des, .single-pricing-2 h6, .single-pricing-four h4, .single-pricing-four .badge, .widget-post .post-content span a, .hosting_plan_tab .nav-tabs li a, .working-items li h4 {
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 1450px) {
  .container {
    max-width: 1430px;
  }
}

@media (min-width: 1450px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1450px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

/*--------------------------------------------------------------
	##  Fonts
	--------------------------------------------------------------*/
html {
  font-size: 16px;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #3f3f3f;
  font-weight: 400;
  font-size: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
  border-radius: 0px;
  padding: 0px;
}

input:focus, textarea:focus {
  outline: none;
}

img {
  max-width: 100%;
}

svg {
  display: block;
}

a {
  transition: all 0.15s ease-in-out;
  display: inline-block;
  outline: 0;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.container-small {
  max-width: 880px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 28px;
  color: #67778e;
  font-weight: 600;
}

.section-padding {
  padding: 120px 0;
}

.padding-50 {
  padding: 50px 0;
}

.padding-120 {
  padding: 120px 0 !important;
}

.container-two {
  max-width: 1430px;
}

.padding-150 {
  padding: 150px 0;
}

.bg-one {
  background: #f7fcff;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-50 {
  padding-bottom: 50px;
}

.pad-45 {
  padding: 45px 0 !important;
}

.pb-30 {
  padding-bottom: 30px;
}

.no-padding {
  padding: 0px;
}

.bg-two {
  background-color: #f7fcff;
}

.bg-three {
  background: #e7f3f5;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-top-90 {
  padding-top: 90px;
}

.bb-content-wrapper {
  padding-left: 242px;
}

.header,
.content,
.footer {
  text-align: center;
}

.header,
.footer {
  background: #777;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 40px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0 50px;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
}

.section_header {
  text-align: center;
  width: 100%;
  margin-bottom: 100px;
}

.section_header > span {
  color: #2F5774;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  line-height: 16px;
  letter-spacing: 2px;
}

.section_header h3 {
  color: #1c2c43;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin-top: 20px;
  position: relative;
  padding-bottom: 35px;
}

.section_header h3:before {
  content: '';
  position: absolute;
  width: 300px;
  height: 1px;
  background-image: linear-gradient(to right, transparent 0%, #01c0f6 50%, transparent 100%);
  bottom: 3px;
  left: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  transform: translateX(-50%);
}

.section_header h3:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 1px;
  background-image: linear-gradient(to right, transparent 0%, #01c0f6 50%, transparent 100%);
  bottom: 0;
  left: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  transform: translateX(-50%);
}

.section_header h3 span {
  color: #197beb;
}

.section_header.light-style > span {
  color: #fff;
}

.section_header.light-style h3 {
  color: #fff;
}

.section_header.light-style h3:after {
  display: none;
}

.section_header.style-two {
  text-align: left;
  margin-bottom: 20px;
}

.section_header.style-two h3 {
  margin-top: 15px;
  color: #2a384c;
  padding-bottom: 0;
}

.section_header.style-two h3 span {
  color: #0b67f4;
}

.section_header.style-two h3::after {
  display: none;
}

.section_header.style-two h3::before {
  display: none;
}

.section_header.style-two > span {
  font-size: 15px;
  color: #2F5774;
}

.section_header_3 {
  text-align: left;
  width: 100%;
}

.section_header_3 > span {
  color: #2F5774;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  line-height: 16px;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

.section_header_3 h3 {
  color: #1c2c43;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}

.section_header_3 h3 span {
  color: #197beb;
}

.section_header_3 h3 .font-light {
  font-weight: 300;
}

.section-head-3 {
  font-size: 26px;
  color: #1c2c43;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}

.section-head-3 span {
  color: #197beb;
}

.section_header.light-style {
  margin-bottom: 90px;
}

.section_header.no-line h3 {
  padding-bottom: 0;
}

.section_header.no-line h3:before, .section_header.no-line h3::after {
  content: none;
}

.paralax {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .section_header h3 {
    font-size: 26px;
  }
  .section_header.style-2 h3 {
    margin-top: 11px;
  }
}

.wave_right {
  padding-top: 290px !important;
  background-image: url(../../media/images/bg/wave_right.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Header style One or Navsidebar or default
--------------------------------------------------------------*/
.is-navsidebar .site {
  padding-left: 242px;
}

.navsidebar {
  background: #ffffff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  outline: 0;
  backface-visibility: hidden;
  box-sizing: border-box;
  box-shadow: 0px 1px 5.95px 1.05px rgba(100, 100, 100, 0.08);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0.2s;
  transition: all .6s ease-in-out;
}

.navsidebar .navbar-wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: calc(242px - 2px);
}

.navsidebar .top-header {
  position: relative;
  padding: 0 20px;
  margin-top: 25px;
}

.navsidebar .top-header .login {
  position: absolute;
  right: 0px;
  top: 0;
  background: #ebf3ff;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #17b212;
}

.navsidebar .top-header .login a {
  color: #17b212;
  font-size: 20px;
}

.navsidebar .navbar--nav {
  padding: 0 20px;
  margin: 45px 0;
}

.navsidebar .navbar--nav .submenu {
  display: none;
  margin-left: 38px;
  margin-top: 10px;
}

.navsidebar ul li {
  margin-bottom: 10px;
}

.navsidebar ul li a {
  color: #63799b;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  display: block;
}

.navsidebar ul li a.link {
  position: relative;
}

.navsidebar ul li a.link:after {
  position: absolute;
  content: '\f1b8';
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-weight: 900;
  font-family: 'Font Awesome\ 5 Free';
}

.navsidebar ul li i {
  font-size: 16px;
  margin-right: 22px;
  color: #63799b;
}

.navsidebar .bottom-header {
  padding: 0 20px;
  margin-top: auto;
}

.navsidebar .bottom-header .contact-num {
  margin-bottom: 50px;
  position: relative;
  padding-left: 40px;
}

.navsidebar .bottom-header .contact-num ._icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px dashed #8a9ebc;
  text-align: center;
  border-radius: 50%;
  color: #8a9ebc;
}

.navsidebar .bottom-header .contact-num a {
  font-size: 14px;
  color: #8a9ebc;
}

.navsidebar .bottom-header .contact-num a:not(:last-child) {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px dashed #8a9ebc;
}

.navsidebar .bottom-header .whm-thumb {
  margin-bottom: 40px;
  text-align: center;
}

.navsidebar .live-chat-wrap {
  padding-left: 1px;
}

.navsidebar .live-chat-wrap a {
  display: block;
  background-color: #17b212;
  position: relative;
  text-align: center;
  color: #fff;
  padding: 16px;
  font-weight: 500;
}

.navsidebar .live-chat-wrap a i {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.navsidebar .live-chat-wrap a:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  height: 0;
  width: 0;
  border-top: 12px solid transparent;
  border-left: 12px solid;
  border-left-color: #17b212;
  border-bottom: 12px solid transparent;
}

.moibe_nav_toggler_1 {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 500;
  background: #000;
  color: #fff;
  border: 0;
  height: 60px;
  width: 60px;
  border-radius: 0 50% 50% 50%;
}

.moibe_nav_toggler_1 .wrap {
  position: relative;
}

.moibe_nav_toggler_1 .wrap i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moibe_nav_toggler_1:focus {
  outline: 0;
}

#navbar_search_modal .input-group {
  margin-bottom: 0 !important;
  border: 1px solid #63799b;
  border-radius: 4px;
}

#navbar_search_modal .input-group input {
  padding: 0 15px;
}

#navbar_search_modal .input-group button {
  border: 0;
  border-left: 1px solid #63799b;
}

#navbar_search_modal .form-control:focus {
  border-bottom: 0;
}

@media (min-width: 576px) {
  #navbar_search_modal .modal-dialog {
    max-width: 700px;
  }
}

/*--------------------------------------------------------------
  ##  Header style two
  --------------------------------------------------------------*/
.header-two {
  position: relative;
  z-index: 999;
  margin: 0 auto;
  border-radius: 11px;
  top: 30px;
  /* Mobile Menu */
}

.header-two.navbar-transparent .container {
  background: transparent;
  box-shadow: none;
}

.header-two.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  transition-property: transform;
  transition-duration: 0.3s;
  transition: all .3s ease-in-out;
}

.header-two .container {
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(101, 114, 129, 0.11);
}

.header-two .right-element {
  display: flex;
  align-items: center;
}

.header-two .menu-2 > ul > li {
  display: inline-block;
}

.header-two .menu-2 > ul > li > a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #838fa0;
  font-size: 16px;
  text-transform: capitalize;
  padding: 35px 30px;
}

.header-two .menu-2 > ul > li > a:hover {
  color: #00c1ff;
}

.header-two .menu-2 > ul > li.dropdown {
  position: relative;
}

.header-two .menu-2 > ul > li.dropdown:hover .submenu {
  opacity: 1;
  visibility: visible;
}

.header-two .menu-2 .submenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 280px;
  background: #fff;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 0px 32px 0px rgba(101, 114, 129, 0.11);
}

.header-two .menu-2 .submenu li a {
  font-size: 14px;
  padding: 5px 0;
  font-weight: 600;
  text-transform: capitalize;
  color: #838fa0;
}

.header-two .top-search {
  position: relative;
}

.header-two .top-search a {
  color: #00c1ff;
}

.header-two .top-search input {
  display: block;
  border: 1px solid #00c1ff;
  border-radius: 2px;
  padding: 5px;
  width: 300px;
  margin-left: 10px;
  margin-top: 12px;
}

.header-two .top-search .search-input-wrapper {
  position: absolute;
  right: 5px;
  top: 60px;
  background: #00c1ff;
  height: 0;
  width: 320px;
  overflow: hidden;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: .1;
}

.header-two .top-search .search-input-wrapper.active {
  height: 60px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
}

.header-two .mobile-menu-toggler {
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: transparent;
  font-size: 25px;
  padding: 0px 15px;
  color: #1c2e42;
}

.header-two-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.megamenu {
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: -7;
  top: 89px;
  padding: 50px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

.megamenu .column {
  width: 33%;
  float: left;
}

.position-initial {
  position: initial;
}

.single-mega-menu {
  margin-top: 35px;
}

.single-mega-menu .icon {
  width: 77px;
  float: left;
}

.single-mega-menu .mega-menu-content {
  overflow: hidden;
  padding-right: 25px;
}

.single-mega-menu .mega-menu-content h6 {
  font-size: 18px;
  color: #505b67;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.single-mega-menu .mega-menu-content p {
  color: #808c9e;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.single-megamenu-feature {
  text-align: center;
  box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.8px 4.2px rgba(221, 234, 255, 0.39);
  padding: 50px 0 50px;
  margin: 15px 15px;
}

.single-megamenu-feature p {
  font-size: 12px;
  color: #7f8793;
  font-weight: 400;
  margin-bottom: 5px;
}

.single-megamenu-feature h6 {
  color: #0b67f4;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 50px;
}

.single-megamenu-feature h6:after {
  position: absolute;
  content: '';
  width: 150px;
  height: 1px;
  background: url(../../media/images/icon/line.png);
  left: 50%;
  margin-left: -75px;
  bottom: -20px;
}

.single-megamenu-feature h3 {
  color: #0b67f4;
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.single-megamenu-feature h3 span {
  color: #8198aa;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: lowercase;
}

.single-megamenu-feature .image {
  width: 20%;
  margin: 0 auto;
  margin-bottom: 25px;
  text-align: center;
  min-height: 50px;
}

.single-megamenu-feature .image img {
  display: inline;
}

.header-two .menu-2 ul li:hover .megamenu {
  opacity: 1;
  visibility: visible;
  transition: all .3s;
}

@media (min-width: 1200px) and (max-width: 1429px) {
  .header-two .menu-2 > ul > li > a {
    padding: 35px 20px;
  }
  .header-two .top-search {
    float: right;
    padding-left: 8px;
  }
}

@media only screen and (max-width: 991px) {
  .header-two .header-two-wrap {
    padding: 15px 0;
  }
  .header-two .mobile-menu-toggler {
    display: block;
  }
  .header-two .menu-2 {
    display: none;
  }
  .header-two .top-search {
    margin-left: 14px;
    display: none;
  }
}

/* ==================================================== */
/* ==================== sidebar Menu =================== */
/* ==================================================== */
.mobile-navbar-wrap {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  max-width: 315px;
  background: #fff;
  z-index: 99999;
  height: 100%;
  min-height: 100vh;
  overflow: visible;
  overflow-y: scroll;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  transition: all .6s ease-in-out;
  z-index: 999999;
}

.mobile-navbar-wrap .mobile-box {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 17px 18px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mobile-navbar-wrap .mobile-box:after {
  content: '';
  position: absolute;
  left: 0;
  top: 21%;
  height: 82px;
  width: 36px;
  border: 5px solid;
  border-left: 0;
  border-color: rgba(0, 0, 0, 0.5);
  opacity: .09;
  border-radius: 0 150px 150px 0;
}

.mobile-navbar-wrap .mobile-box:before {
  content: '';
  position: absolute;
  right: 0;
  top: 45%;
  height: 62px;
  width: 32px;
  border: 3px solid;
  border-right: 0;
  border-color: rgba(0, 0, 0, 0.5);
  opacity: .09;
  border-radius: 150px 0 0 150px;
}

.mobile-navbar-wrap .mobile-menu {
  position: relative;
  z-index: 90;
  text-align: center;
  margin-bottom: 30px;
}

.mobile-navbar-wrap .mobile-menu > li {
  transition: all .4s;
  border-radius: 2px;
  margin-bottom: 13px;
}

.mobile-navbar-wrap .mobile-menu > li > a {
  font-size: 16px;
  color: #63799b;
  font-weight: 500;
  letter-spacing: .1px;
  opacity: .8;
  display: inline-block;
}

.mobile-navbar-wrap .mobile-menu > li:last-child {
  margin-bottom: 0;
}

.mobile-navbar-wrap .mobile-menu .submenu {
  background: rgba(255, 255, 255, 0.3);
  display: none;
  border-radius: 0 0 5px 5px;
}

.mobile-navbar-wrap .mobile-menu .submenu li a {
  padding: 5px 15px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  opacity: .8;
}

.mobile-navbar-wrap .mobile-menu .caret-dropdown > a {
  position: relative;
  padding-right: 17px;
}

.mobile-navbar-wrap .mobile-menu .caret-dropdown > a:after {
  position: absolute;
  content: "\f107";
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: 'Font Awesome\ 5 Free';
  color: #63799b;
  font-weight: 700;
  transition: all .3s linear;
  font-size: 15px;
}

.mobile-navbar-wrap .mobile-nav-header {
  margin-bottom: 84px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  padding-bottom: 22px;
}

.mobile-navbar-wrap .mobile-menu-close {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
}

.mobile-navbar-wrap .mobile-menu-close i {
  font-size: 18px;
}

.mobile-navbar-wrap .mobile-footer {
  margin-top: auto;
}

.mobile-navbar-wrap .mobile-footer form {
  display: flex;
  background: #f5f8fb;
  border: 1px solid #e6e9ff;
  padding: 0 15px;
  margin-bottom: 23px;
  justify-content: space-between;
  border-radius: 2px;
}

.mobile-navbar-wrap .mobile-footer form input {
  border: 0;
  font-size: 12px;
  color: #000;
  line-height: 24px;
  padding: 11px 0;
  background: transparent;
  width: 100%;
}

.mobile-navbar-wrap .mobile-footer form button {
  background: transparent;
  border: 0;
  font-size: 12px;
  transition: all .3s linear;
}

.mobile-navbar-wrap .mobile-footer .social_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 25px;
  margin-bottom: 6px;
}

.mobile-navbar-wrap .mobile-footer .social_link ._title {
  font-size: 12px;
  font-weight: 500;
  color: #63799b;
  letter-spacing: .5px;
}

.mobile-navbar-wrap .mobile-footer .social_link ul {
  margin-bottom: 0;
}

.mobile-navbar-wrap .mobile-footer .social_link ul li {
  display: inline-block;
}

.mobile-navbar-wrap .mobile-footer .social_link ul li a {
  margin-right: 0px;
  width: 32px;
  height: 32px;
  border: 1px solid #63799b;
  border-radius: 50px;
  text-align: center;
  line-height: 30px;
  color: #63799b;
  font-size: 12px;
}

.mobile-navbar-wrap .mobile-footer .social_link ul li:first-child a {
  margin-left: 0;
}

body.active .mobile-navbar-wrap {
  left: 0 !important;
}

body.active .sidebar-manu-close {
  left: 100%;
}

.mask-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

@media only screen and (max-width: 575px) {
  .mobile-navbar-wrap .mobile-nav-header {
    margin-bottom: 45px;
  }
}

/*--------------------------------------------------------------
   						Header Responsive
--------------------------------------------------------------*/
@media (min-width: 1200px) {
  .navsidebar {
    display: block !important;
  }
}

@media (max-width: 1199px) {
  .is-navsidebar .site {
    padding-left: 0;
  }
  .navsidebar {
    left: -100%;
  }
  .navsidebar .navbar-wrap {
    overflow-y: scroll;
  }
  .navsidebar.show {
    left: 0;
  }
  .moibe_nav_toggler_1 {
    display: inherit;
  }
}

/*--------------------------------------------------------------
  ##  Slider
  --------------------------------------------------------------*/
.slider-wrapper .item {
  background-size: cover !important;
  min-height: 100vh;
}

.slider-text h1 {
  color: #197beb;
  margin-bottom: 10px;
  font-size: 60px;
  line-height: 70px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-weight: 400;
}

.slider-text h1 span {
  display: block;
  font-weight: 700;
}

.slider-text h4 {
  font-size: 60px;
  color: #197beb;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.slider-text p {
  font-size: 20px;
  margin-bottom: 25px;
  color: #197beb;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 40px;
  margin-top: 10px;
  max-width: 519px;
}

a.btn-one {
  font-size: 20px;
  color: #fff;
  background: #3f3f3f;
  padding: 13px 44px;
  border-radius: 29px;
  margin-top: 10px;
}

a.btn-one:hover {
  background: #197beb;
}

.slider-img {
  padding-top: 60px;
  text-align: center !important;
}

.owl-carousel .owl-item .slider-img img {
  max-width: 100%;
  width: auto;
}

.slider-wrapper .owl-theme .owl-dots .owl-dot.active span {
  width: 10px;
  height: 32px;
}

.slider-wrapper .owl-theme .owl-dots .owl-dot span {
  width: 16px;
  margin: 0px 4px;
  background: #1d1b1b;
  display: inline-block;
  border-radius: 3px;
  width: 10px;
  height: 14px;
}

.slider-wrapper .owl-theme .owl-dots .owl-dot {
  display: block;
}

.slider-wrapper .owl-theme .owl-nav + .owl-dots {
  margin-top: 10px;
  position: absolute;
  top: 50%;
  right: 150px;
}

.delay {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
}

.slider-text h4 {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
}

.slider-text h1 {
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
}

.slider-text p {
  -webkit-animation-delay: .7s;
  -moz-animation-delay: .7s;
}

.slider-text a {
  -webkit-animation-delay: .8s;
  -moz-animation-delay: .8s;
}

.slider-one .slider-text {
  padding: 275px 0;
}

/* ----------------------------------------------------------------
	Slider Style Two
-----------------------------------------------------------------*/
a.btn-one {
  font-size: 18px;
  color: #197beb;
  background: transparent;
  padding: 13px 28px;
  margin-top: 10px;
  text-transform: capitalize;
  border: 1px solid #197beb;
  border-radius: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-right: 10px;
  box-shadow: 0.968px 3.881px 8.19px 0.81px rgba(109, 109, 109, 0.26);
}

a.btn-one:hover {
  background: #197beb;
  color: #fff;
}

a.btn-two {
  font-size: 18px;
  color: #fff;
  background: #197beb;
  padding: 13px 28px;
  margin-top: 10px;
  text-transform: capitalize;
  border: 1px solid #197beb;
  border-radius: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-right: 10px;
  box-shadow: 0.968px 3.881px 8.19px 0.81px rgba(109, 109, 109, 0.26);
}

a.btn-two:hover {
  background: #197beb;
  color: #fff;
}

/* ----------------------------------------------------------------
	Slider Style Four
-----------------------------------------------------------------*/
.slider-four {
  margin-top: -100px;
}

.slider-four .slider-text {
  padding: 320px 0 256px;
}

@media (min-width: 1200px) and (max-width: 1423px) {
  .slider-one .slider-text {
    padding: 200px 0 205px;
  }
  .slider-four .slider-text {
    padding: 256px 0 185px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slider-one .slider-text {
    padding: 135px 0 140px;
  }
  .slider-one .slider-text h1 {
    font-size: 50px;
    line-height: 60px;
  }
  .slider-wrapper .owl-theme .owl-nav + .owl-dots {
    right: 90px;
  }
  .slider-four .slider-text {
    padding: 266px 0 199px;
  }
  .slider-four .slider-text h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-one .slider-text {
    padding: 135px 0 140px;
  }
  .slider-one .slider-text h1 {
    font-size: 50px;
    line-height: 60px;
  }
  .slider-wrapper .owl-theme .owl-nav + .owl-dots {
    right: 90px;
  }
  .slider-four .slider-text {
    padding: 288px 0 180px;
  }
  .slider-four .slider-text h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .slider-four .slider-text p {
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 34px;
  }
  .slider-four .slider-text a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-one .slider-text {
    padding: 135px 0 140px;
  }
  .slider-one .slider-text h1 {
    font-size: 39px;
    line-height: 48px;
  }
  .slider-one .slider-text p {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 18px;
  }
  .slider-one .slider-text .btn-two,
  .slider-one .slider-text .btn-one {
    font-size: 15px;
    padding: 11px 24px;
  }
  .slider-wrapper .owl-theme .owl-nav + .owl-dots {
    right: 30px;
  }
  .slider-four .slider-text {
    padding: 288px 0 180px;
  }
  .slider-four .slider-text h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .slider-four .slider-text p {
    font-size: 16px;
    margin-bottom: 18px;
    line-height: 30px;
    margin-top: 15px;
  }
  .slider-four .slider-text a {
    font-size: 14px;
    padding: 12px 22px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-one .slider-text h1 {
    font-size: 29px;
    line-height: 39px;
  }
  .slider-one .slider-text p {
    font-size: 15px;
    line-height: 29px;
    margin-bottom: 18px;
  }
  .slider-one .slider-text .btn-two,
  .slider-one .slider-text .btn-one {
    font-size: 14px;
    padding: 10px 21px;
  }
  .slider-wrapper .owl-theme .owl-nav + .owl-dots {
    display: none;
  }
  .slider-four .slider-text {
    padding: 239px 0 152px;
  }
  .slider-four .slider-text h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .slider-four .slider-text p {
    font-size: 15px;
    margin-bottom: 18px;
    line-height: 30px;
    margin-top: 15px;
  }
  .slider-four .slider-text a {
    font-size: 14px;
    padding: 9px 22px;
  }
}

.banner_5 {
  background-repeat: no-repeat !important;
}

.banner_5_content {
  text-align: center;
  padding: 290px 0;
}

.banner_5_content h1 {
  font-size: 44px;
  line-height: 65px;
  letter-spacing: .55px;
  font-weight: 800;
  text-transform: uppercase;
  max-width: 675px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.banner_5_content form {
  margin-top: 40px;
  max-width: 595px;
  margin-left: auto;
  margin-right: auto;
  border: 7px solid #ffffff;
  background: #ffffff;
  display: flex;
  border-radius: 48px;
  overflow: hidden;
}

.banner_5_content form input {
  width: 100%;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px 0 26px;
  color: #808c9e;
}

.banner_5_content form button {
  height: 47px;
  padding: 0 39px;
  border: 0;
  font-size: 17px;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  border-radius: 40px;
}

.banner_5_content form button:focus {
  outline: none;
}

.banner_5_content ul {
  margin-bottom: 0;
  margin-top: 30px;
}

.banner_5_content ul li {
  display: inline-block;
  text-align: center;
  opacity: .7;
  cursor: pointer;
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}

.banner_5_content ul li:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  height: 50px;
  width: 1px;
  transform: translate(50%, -50%);
  background-image: linear-gradient(transparent 0%, #ffffff 58%, transparent 100%);
}

.banner_5_content ul li h5 {
  font-size: 18px;
  color: #ffffff;
}

.banner_5_content ul li .detail {
  font-size: 14px;
  color: #ffffff;
}

.banner_5_content ul li:hover {
  opacity: 1;
}

.banner_5_content ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.banner_5_content ul li:last-child:after {
  content: none;
}

/*--------------------------------------------------------------
  ##  Breadcrumb
  --------------------------------------------------------------*/
.bb-breadcrumb {
  background: #fff;
  padding-top: 370px;
  margin-bottom: 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bb-breadcrumb h2 {
  font-size: 60px;
  color: #197beb;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}

.bb-breadcrumb h2 span {
  font-weight: 400;
}

.bb-breadcrumb .des {
  font-weight: 400;
  max-width: 500px;
  font-size: 18px;
  margin-bottom: 0;
}

.bb-breadcrumb .breadcrumb-inner {
  padding-top: 200px;
  padding-bottom: 17px;
}

.bb-breadcrumb .breadcrumb-inner p {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #00c1ff;
}

.bb-breadcrumb .breadcrumb-inner p a {
  color: #197beb;
  margin-right: 8px;
}

@media only screen and (max-width: 991px) {
  .bb-breadcrumb {
    padding-top: 300px;
  }
  .bb-breadcrumb h2 {
    font-size: 47px;
  }
  .bb-breadcrumb .breadcrumb-inner {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .bb-breadcrumb {
    padding-top: 270px;
  }
  .bb-breadcrumb h2 {
    font-size: 34px;
  }
}

/*--------------------------------------------------------------
  ##  Feature 1
  --------------------------------------------------------------*/
.single-feature {
  text-align: center;
  padding: 0 40px;
}

.single-feature h5 {
  text-transform: uppercase;
  font-weight: 600;
  color: #354358;
  margin-bottom: 15px;
  margin-top: 30px;
}

.single-feature p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #4f5d72;
  margin-bottom: 17px;
}

.single-feature a {
  font-size: 13px;
  color: #197beb;
  text-transform: uppercase;
}

.single-feature a img {
  margin-left: 5px;
}

.single-feature:hover h5 {
  color: #00c1ff;
}

.single-feature:hover a {
  color: #00c1ff;
}

.feature_1 {
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .single-feature {
    padding: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-feature {
    padding: 0;
  }
}

@media only screen and (max-width: 991px) {
  .single-feature {
    padding: 0;
  }
}

/*--------------------------------------------------------------
  ##  Feature 2
  --------------------------------------------------------------*/
.single-feature-two {
  width: 100%;
  max-width: 50%;
  background: #fff;
  position: relative;
  /* ---------------------- */
}

.single-feature-two .feature-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
}

.single-feature-two .feature-img img {
  height: 100%;
  width: 100%;
}

.single-feature-two .hide-content {
  width: 100%;
  max-width: 50%;
  transition: all 0.15s ease-in-out;
  padding: 60px 45px;
  background: #faf9f9;
}

.single-feature-two .hide-content > span {
  color: #00c1ff;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.single-feature-two .hide-content .feature-list {
  margin-top: 15px;
}

.single-feature-two .hide-content .feature-list ul {
  border-bottom: 1px dashed #d7d7d7;
}

.single-feature-two .hide-content .feature-list ul li {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #4f5d72;
  padding: 15px 0;
  border-top: 1px dashed #d7d7d7;
}

.single-feature-two .hide-content .feature-list ul li span {
  float: right;
}

.single-feature-two .feature-two-contnt {
  overflow: hidden;
  display: flex;
}

.single-feature-two .btn-2 {
  font-size: 15px;
  color: #fff;
  padding: 7px 22px;
  border-radius: 26px;
  font-weight: 500;
  z-index: 50;
  background: #00c1ff;
  text-transform: uppercase;
  border: 3px solid #ebebeb;
  margin-top: 30px;
}

.single-feature-two .btn-2:hover {
  background: #197beb;
  color: #fff;
}

.single-feature-two .show-content {
  width: 100%;
  max-width: 50%;
  background: #fff;
  z-index: 5;
  padding: 60px 45px;
  border-left: 2px solid #fff;
  transition: all 0.7s ease-in-out;
}

.single-feature-two .show-content:after {
  content: '';
  position: absolute;
  left: -12px;
  top: 50%;
  width: 24px;
  height: 24px;
  background: #fff;
  transform: rotate(45deg);
  margin-top: -12px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.single-feature-two .show-content h5 {
  color: #354358;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  position: relative;
  margin-bottom: 50px;
}

.single-feature-two .show-content h5:after {
  content: '';
  bottom: -15px;
  left: 0;
  width: 80px;
  height: 1px;
  background: #83b4ff;
  position: absolute;
}

.single-feature-two .show-content p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.single-feature-two .show-content .icon {
  margin-bottom: 30px;
  height: 82px;
}

.single-feature-two .btn-2 {
  font-size: 15px;
  color: #fff;
  padding: 7px 22px;
  border-radius: 26px;
  font-weight: 500;
  z-index: 50;
  background: #00c1ff;
  text-transform: uppercase;
  border: 3px solid #ebebeb;
  margin-top: 30px;
}

.single-feature-two .btn-2:hover {
  background: #197beb;
  color: #fff;
}

.single-feature-two:nth-child(-n+2) .show-content {
  transform: translateX(100%);
}

.single-feature-two:nth-child(n+3):nth-child(-n+4) .feature-img {
  left: inherit;
  right: 0;
}

.single-feature-two:nth-child(n+3):nth-child(-n+4) .show-content {
  order: 2;
  transform: translateX(-100%);
}

.single-feature-two:nth-child(n+3):nth-child(-n+4) .show-content:after {
  right: -12px;
  left: inherit;
}

.single-feature-two:hover:nth-child(-n+2) .show-content {
  transform: translateX(0);
}

.single-feature-two:hover:nth-child(n+3):nth-child(-n+4) .show-content {
  transform: translateX(0);
}

.single-feature-two:hover .show-content:after {
  background-color: transparent;
}

@media (min-width: 1200px) and (max-width: 1429px) {
  .single-feature-two .show-content {
    padding: 30px 20px;
  }
  .single-feature-two .show-content h5 {
    font-size: 16px;
    margin-bottom: 37px;
  }
  .single-feature-two .show-content p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0px;
  }
  .single-feature-two .hide-content {
    padding: 30px 20px;
  }
  .single-feature-two .hide-content .feature-list ul li {
    padding: 12px 0;
  }
  .single-feature-two .btn-2 {
    margin-top: 23px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-feature-two {
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .single-feature-two {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-feature-two {
    max-width: 100%;
  }
  .single-feature-two .show-content {
    padding: 30px 20px;
  }
  .single-feature-two .show-content h5 {
    font-size: 16px;
    margin-bottom: 37px;
  }
  .single-feature-two .show-content p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0px;
  }
  .single-feature-two .hide-content {
    padding: 30px 20px;
  }
  .single-feature-two .hide-content .feature-list ul li {
    padding: 12px 0;
  }
  .single-feature-two .btn-2 {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 575px) {
  .single-feature-two .feature-img {
    width: 100%;
    height: 50%;
    display: none;
  }
  .single-feature-two .feature-two-contnt {
    flex-direction: column;
  }
  .single-feature-two .show-content {
    min-width: 100%;
  }
  .single-feature-two .hide-content {
    max-width: 100%;
  }
  .single-feature-two:nth-child(-n+2) .show-content {
    transform: translateX(0);
  }
  .single-feature-two:nth-child(n+3):nth-child(-n+4) .show-content {
    transform: translateX(0%);
  }
}

/*--------------------------------------------------------------
  ##  Feature 4
  --------------------------------------------------------------*/
.single-feature-style-4 {
  position: relative;
  z-index: 1;
  padding-left: 15px;
  padding-right: 7px;
}

.single-feature-style-4 .inner-wrapper {
  box-shadow: 0px 1px 13.6px 2.4px rgba(100, 100, 100, 0.1);
  background: #fff;
  border-radius: 10px;
  padding: 25px 35px;
}

.single-feature-style-4 i {
  font-size: 44px;
  color: #197beb;
  margin-bottom: 14px;
  display: inline-block;
}

.single-feature-style-4 h4 {
  font-size: 18px;
  color: #404a59;
}

.single-feature-style-4 p {
  font-size: 14px;
  color: #808c9e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.single-feature-style-4:before {
  content: '';
  position: absolute;
  left: -5px;
  top: -20px;
  width: 90%;
  height: 100%;
  background: #ecf2fa;
  z-index: -6;
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 13.6px 2.4px rgba(100, 100, 100, 0.1);
  box-shadow: 0px 1px 13.6px 2.4px rgba(100, 100, 100, 0.1);
  transition: all .3s;
}

.single-feature-style-4:hover i {
  color: #00c1ff;
}

.single-feature-style-4:hover h4 {
  color: #00c1ff;
}

.single-feature-style-4:hover p {
  color: #808c9e;
}

.Rectangle_2 {
  border-radius: 4px;
  background-color: #fcfeff;
  position: absolute;
  left: 394px;
  top: 830px;
  width: 352px;
  height: 220px;
  z-index: 227;
}

/*--------------------------------------------------------------
  ##  Video  1
  --------------------------------------------------------------*/
.inner-content-box ul {
  padding-top: 15px;
  padding-bottom: 20px;
}

.inner-content-box ul li {
  font-size: 15px;
  color: #808c9e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.inner-content-box ul li:after {
  position: absolute;
  left: 0;
  top: 8px;
  height: 7px;
  width: 7px;
  background: #677283;
  border-radius: 50px;
  content: '';
}

.video-area-2 {
  background: rgba(25, 123, 235, 0.79);
  overflow: hidden;
  height: 370px;
  width: 410px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0;
}

.video-1 {
  position: relative;
  background-position: right;
  background-size: 100%;
  position: relative;
  z-index: 1;
}

.video-1::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: url(../../media/images/bg/halfbg.jpg) no-repeat;
  background-position: left;
  background-size: cover;
  z-index: -1;
}

.video-area-2 .play-button {
  position: relative;
  width: 63px;
  text-align: center;
  float: none;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.video-area-2 .signal, .video-area-2 .signal2 {
  background-color: #fff;
}

.video-area-2 .play-button a {
  background: #fff;
}

.video-area-2 .play-button a i {
  color: #3165f4;
}

.video-area-2 .play-button::after {
  position: absolute;
  content: '';
  top: -12px;
  left: -11px;
  width: 84px;
  height: 84px;
  background: #fff;
  opacity: 0.1;
  z-index: -1;
  border-radius: 50px;
}

.video-1 .inner-content-box {
  padding-bottom: 60px;
  padding-right: 140px;
}

@media only screen and (max-width: 1199px) {
  .video-1 .inner-content-box {
    padding-right: 0px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .video-area-2 {
    height: 384px;
    width: 100%;
    position: inherit;
    left: inherit;
    bottom: inherit;
    margin-top: 40px;
  }
  .video-1::after {
    content: none;
  }
}

/*--------------------------------------------------------------
  ##  Success Nimber 1
  --------------------------------------------------------------*/
.single-number {
  text-align: center;
  box-shadow: 0px 1px 11.05px 1.95px rgba(100, 100, 100, 0.05);
  background: #fff;
  padding: 50px 20px;
}

.single-number .icon {
  width: 75px;
  height: 92px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.single-number h5 {
  color: #17b212;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 36px;
  position: relative;
  display: inline-block;
}

.single-number h5.sec-color {
  color: #197beb;
}

.single-number p {
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
  color: #67778e;
}

.single-number h5::after {
  content: '+';
  position: absolute;
  right: -22px;
  top: 0px;
}

.single-number-two {
  position: relative;
  padding: 50px;
  text-align: center;
}

.single-number-two h5 {
  color: #197beb;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 50px;
  position: relative;
  display: inline-block;
}

.single-number-two p {
  color: #2a384c;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.single-number-two:after {
  position: absolute;
  width: 1px;
  height: 80px;
  right: 0;
  top: 50%;
  content: '';
  background: url(../../media/images/icons/seperator.png);
  transform: translateY(-50%);
}

.single-number-two.border-remove:after {
  display: none;
}

.single-number h5::after {
  content: '+';
  position: absolute;
  right: -22px;
  top: 0px;
}

.border-top2 .container {
  border-top: 1px solid #eef4fd;
}

.number-2 {
  padding-bottom: 50px;
}

@media only screen and (max-width: 1199px) {
  .single-number-two {
    padding: 20px;
  }
  .single-number-two h5 {
    font-size: 44px;
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 575px) {
  .single-number-two:after {
    width: 80%;
    height: 1px;
    right: 50%;
    top: inherit;
    bottom: 0;
    transform: translateX(50%);
    border: 1px solid #197beb;
    opacity: .1;
  }
}

/*--------------------------------------------------------------
  ##  Pricing 1
  --------------------------------------------------------------*/
.pricing_1 {
  position: relative;
}

.single-pricing {
  overflow: hidden;
  border-radius: 6px;
  position: relative;
}

.single-pricing::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: #197beb;
  z-index: 99;
}

.single-pricing::after:hover:after {
  transition: all 0.3s ease-in-out;
}

.single-pricing .top-box {
  padding: 50px 40px;
  border-radius: 6px;
  background-color: white;
  position: relative;
}

.single-pricing .top-box h5 {
  text-transform: uppercase;
  font-weight: 700;
}

.single-pricing .top-box p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #4f5d72;
  font-size: 15px;
}

.single-pricing .top-box .inner-box {
  width: 46%;
  float: left;
  overflow: hidden;
}

.single-pricing .top-box .inner-box i {
  font-size: 33px;
  float: right;
}

.single-pricing .top-box .inner-box-left {
  width: 54%;
  float: left;
  overflow: hidden;
}

.single-pricing .top-box .top-heading {
  overflow: hidden;
  padding-bottom: 60px;
}

.single-pricing .top-box .price-toggle {
  overflow: hidden;
}

.single-pricing .top-box .price-toggle .montly-price {
  width: 50%;
  float: left;
  display: none;
}

.single-pricing .top-box .price-toggle .montly-price span {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #83868b;
  padding-left: 18px;
  text-decoration: line-through;
}

.single-pricing .top-box .price-toggle .montly-price span sup {
  top: 0;
}

.single-pricing .top-box .price-toggle .montly-price p {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #197beb;
}

.single-pricing .top-box .price-toggle .montly-price p sup {
  top: 0;
}

.single-pricing .top-box .price-toggle .yearly-price {
  width: 50%;
  float: left;
}

.single-pricing .top-box .price-toggle .yearly-price span {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #83868b;
  padding-left: 18px;
  text-decoration: line-through;
}

.single-pricing .top-box .price-toggle .yearly-price span sup {
  top: 0;
}

.single-pricing .top-box .price-toggle .yearly-price p {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #197beb;
}

.single-pricing .top-box .price-toggle .yearly-price p sup {
  top: 0;
}

.single-pricing .top-box .price-toggle .tog-button {
  overflow: hidden;
  text-align: right;
}

.single-pricing .top-box .price-toggle .tog-button p {
  margin: 0;
  color: #596069;
  font-size: 15px;
  text-transform: capitalize;
}

.single-pricing .top-box .price-toggle .tog-button .monthly {
  display: none;
}

.single-pricing .top-box .order-now {
  font-size: 16px;
  color: #fff;
  padding: 10px 27px;
  border-radius: 26px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
  z-index: 50;
  text-transform: uppercase;
  box-shadow: 0px 1px 11.05px 1.95px rgba(100, 100, 100, 0.18);
  border: 3px solid #fff;
}

.single-pricing .bottom-box {
  padding: 75px 40px 50px;
  background-color: #effbfd;
  position: relative;
}

.single-pricing .bottom-box .package-info-list ul li {
  color: #596069;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.single-pricing .bottom-box .package-info-list ul li i {
  font-size: 15px;
  margin-right: 10px;
}

.single-pricing:hover .top-box .order-now {
  background: #00c1ff;
}

.single-pricing:hover .toggle-btn {
  border: 1px solid #00c1ff;
}

.price-toggle.active .montly-price {
  display: block !important;
}

.price-toggle.active .yearly-price {
  display: none;
}

.price-toggle.active .tog-button .monthly {
  display: block !important;
}

.price-toggle.active .tog-button .yearly {
  display: none;
}

.toggle-btn {
  width: 50px;
  height: 21px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  border: 1px solid #197beb;
  cursor: pointer;
  -webkit-transition: background-color .40s ease-in-out;
  -o-transition: background-color .40s ease-in-out;
  transition: background-color .40s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-align: right;
}

.toggle-btn .round-btn {
  width: 15px;
  height: 15px;
  background-color: #197beb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 31px;
  top: 17px;
  margin-top: -15px;
  -webkit-transition: all .30s ease-in-out;
  -o-transition: all .30s ease-in-out;
  transition: all .30s ease-in-out;
}

.toggle-btn .round-btn.active {
  left: 2px;
}

.toggle-btn .cb-value {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .single-pricing .top-box {
    padding: 38px 30px;
    border-radius: 6px;
  }
  .single-pricing .top-box .inner-box-left {
    width: 71%;
  }
  .single-pricing .top-box .inner-box {
    width: 29%;
  }
  .single-pricing .top-box .inner-box i {
    font-size: 25px;
  }
  .single-pricing .top-box h5 {
    font-size: 17px;
  }
  .single-pricing .top-box p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .single-pricing .top-box .order-now {
    font-size: 14px;
    padding: 10px 20px;
  }
  .single-pricing .bottom-box {
    padding: 75px 30px 50px;
  }
  .single-pricing .bottom-box .package-info-list ul li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .single-pricing .top-box {
    padding: 38px 30px;
    border-radius: 6px;
  }
  .single-pricing .top-box .inner-box-left {
    width: 71%;
  }
  .single-pricing .top-box .inner-box {
    width: 29%;
  }
  .single-pricing .top-box .inner-box i {
    font-size: 25px;
  }
  .single-pricing .top-box h5 {
    font-size: 17px;
  }
  .single-pricing .top-box p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .single-pricing .top-box .order-now {
    font-size: 14px;
    padding: 10px 20px;
  }
  .single-pricing .bottom-box {
    padding: 75px 30px 50px;
  }
  .single-pricing .bottom-box .package-info-list ul li {
    font-size: 15px;
  }
}

/*--------------------------------------------------------------
  ##  Pricing  2
  --------------------------------------------------------------*/
.pricing_2 {
  background: url("../../media/images/bg/price_bg.png") no-repeat;
  background-position: center right;
}

.pricing_2 .toggle-btn-1 {
  float: left;
  margin-top: 20px;
}

.pricing_2 .inner-content-box {
  background: url("../../media/images/bg/snow_circle.png") no-repeat;
  background-position: bottom center;
}

.single-pricing-2 {
  box-shadow: 0px 5px 27px 0px rgba(151, 151, 151, 0.15);
  padding: 55px 0;
  text-align: center;
  border-radius: 5px;
  background: #fff;
}

.single-pricing-2 h6 {
  font-size: 20px;
  color: #2a384c;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.single-pricing-2 .price-wrap {
  background: #f7fcff;
  padding: 12px 15px;
}

.single-pricing-2 .price {
  font-size: 50px;
  font-weight: 800;
  transition: all .3s linear;
}

.single-pricing-2 .price sup {
  font-size: 30px;
  color: #2a384c;
}

.single-pricing-2 .price sub {
  color: #67778e;
  font-size: 15px;
  font-weight: 600;
  bottom: 0px;
  left: -9px;
}

.single-pricing-2 .package-info-list {
  padding: 0 55px;
  margin-top: 30px;
}

.single-pricing-2 .package-info-list li {
  color: #67778e;
  text-transform: capitalize;
  padding: 10px 0;
  text-align: center;
  position: relative;
}

.single-pricing-2 .package-info-list li span {
  font-weight: 600;
}

.single-pricing-2 .package-info-list li:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, transparent 0%, #dceaff 50%, transparent 100%);
}

.single-pricing-2 > p {
  color: #808c9e;
  padding: 0 25px;
  margin-bottom: 30px;
}

.single-pricing-2 .btn-3 {
  margin-top: 40px;
  padding: 7px 25px;
  font-size: 14px;
  width: auto;
  font-weight: 600;
}

.single-pricing-2 .monthly {
  display: block;
}

.single-pricing-2 .yearly {
  display: none;
}

.single-pricing-2:hover .btn-3 {
  background: #00c1ff;
  color: #fff;
}

.single-pricing-2.yearly .monthly {
  display: none;
}

.single-pricing-2.yearly .yearly {
  display: block;
}

@media (min-width: 992px) and (max-width: 1449px) {
  .pricing_2 .section_header h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1199px) {
  .single-pricing-2 {
    margin-top: 50px;
  }
}

/*--------------------------------------------------------------
  ##  Pricing  4
  --------------------------------------------------------------*/
.single-pricing-four {
  text-align: center;
  box-shadow: 0 0 13px 0 rgba(101, 114, 129, 0.07);
  border-radius: 8px;
  padding: 75px 0;
  background: #fff;
  overflow: hidden;
  position: relative;
}

.single-pricing-four h4 {
  font-size: 18px;
  color: #0b67f4;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  transition: .3s;
}

.single-pricing-four h3 {
  font-size: 26px;
  color: #0b67f4;
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
  transition: .3s;
}

.single-pricing-four ul {
  max-width: 70%;
  margin: 40px auto;
}

.single-pricing-four ul li {
  font-size: 15px;
  font-weight: 600;
  color: #67778e;
  text-align: left;
  padding: 13px 30px;
  border-bottom: 1px solid rgba(11, 103, 244, 0.071);
  text-align: center;
}

.single-pricing-four .pricing-image {
  text-align: center;
  width: 115px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.single-pricing-four .btn-3 {
  margin-top: 24px;
}

.single-pricing-four:before {
  content: '';
  background-color: rgba(11, 103, 244, 0.071);
  box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.82px 4.18px rgba(221, 234, 255, 0.39);
  width: 343px;
  height: 305px;
  position: absolute;
  top: -47px;
  left: -61%;
  transform: rotate(-44deg);
  border-radius: 30px;
  transition: .3s;
}

.single-pricing-four:after {
  content: '';
  background-color: rgba(11, 103, 244, 0.071);
  box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.82px 4.18px rgba(221, 234, 255, 0.39);
  width: 343px;
  height: 305px;
  position: absolute;
  bottom: 31px;
  right: -68%;
  transform: rotate(-50deg);
  border-radius: 30px;
  transition: .3s;
}

.single-pricing-four .badge {
  position: absolute;
  top: 24px;
  right: -59px;
  padding: 18px 87px;
  font-weight: 800;
  font-size: 12px;
  color: #ffffff;
  transform: rotate(38deg);
  text-transform: uppercase;
}

@media (min-width: 1200px) and (max-width: 1449px) {
  .single-pricing-four:before {
    left: -81%;
  }
  .single-pricing-four:after {
    right: -90%;
  }
  .single-pricing-four .badge {
    top: 17px;
    right: -70px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-pricing-four:before {
    left: -104%;
    top: -67px;
  }
  .single-pricing-four:after {
    right: -110%;
  }
  .single-pricing-four ul {
    max-width: 82%;
  }
  .single-pricing-four .badge {
    top: 19px;
    right: -69px;
    padding: 15px 87px;
  }
}

@media only screen and (max-width: 991px) {
  .single-pricing-four:before {
    left: -35%;
  }
  .single-pricing-four:after {
    right: -40%;
  }
}

@media only screen and (max-width: 757px) {
  .single-pricing-four:before {
    left: -48%;
  }
  .single-pricing-four:after {
    right: -57%;
  }
}

@media only screen and (max-width: 575px) {
  .single-pricing-four:before {
    left: -289px;
  }
  .single-pricing-four:after {
    right: -289px;
  }
}

/*--------------------------------------------------------------
  ##  Slider Feature
  --------------------------------------------------------------*/
.slider-image {
  width: 100%;
  max-width: 50%;
}

.single-feature-slider {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 490px;
}

.single-feature-slider .box {
  padding: 50px;
  width: 100%;
  border: 1px solid #f3f3f3;
  margin-bottom: 5px;
  border-radius: 26px;
  margin-right: 6px;
}

.single-feature-slider .box h4 {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #354358;
  text-transform: uppercase;
}

.single-feature-slider .box h4 span {
  color: #00c1ff !important;
}

.single-feature-slider .box ul li {
  font-size: 16px;
  padding: 5px 0;
  color: #5f7492;
  padding-left: 25px;
  position: relative;
}

.single-feature-slider .box ul li:before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  left: 0;
  color: #00c1ff;
  position: absolute;
}

.feature-slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-feature {
  width: 100%;
  max-width: 50%;
}

.fix-box {
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: 50% 50% !important;
  vertical-align: top;
  padding: 800px 30px 30px 30px;
}

.bg-1 {
  background: #f8f8f8;
}

.bg-2 {
  background: #fff;
}

.single-feature-slider-wrapper {
  width: 50%;
  float: left;
}

/*--------------------------------------------------------------
  ##  Data center
  --------------------------------------------------------------*/
.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #197beb;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

.dot-circle {
  width: 34px;
  height: 34px;
  background-image: url(../../media/images/icons/marrow.png);
  background-size: 100%;
  background-position: center;
  position: absolute;
  left: 12%;
  top: 25%;
  background-repeat: no-repeat;
  transform: rotate(360deg);
}

.dot-circle::after {
  content: '1';
  background: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 32px;
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  border-radius: 50px;
  top: -17px;
  transition: all .8s;
}

.map-area .tab-content > .tab-pane {
  display: block;
  opacity: 1;
}

.map-area {
  width: 60%;
  float: left;
  position: relative;
}

.map-location {
  width: 40%;
  float: left;
  box-shadow: -1.414px -1.414px 5px 0px rgba(102, 101, 101, 0.07);
  height: 406px;
  overflow-y: scroll;
}

.map-location .nav-tabs {
  border-bottom: none;
  background: #fff;
}

.map-location .nav-tabs .nav-item {
  margin-bottom: -1px;
  width: 100%;
  border-top: 1px solid #efefef;
  position: relative;
}

.map-location .nav-tabs .nav-item .nav-link {
  border: none;
  border-radius: 0;
  border-top: 5px solid #fff;
  padding: 19px 21px 10px;
}

.map-location .nav-tabs .nav-link.active {
  border: none;
  border-top: 5px solid #00c1ff;
}

.map-location .nav-tabs .nav-item a span {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.map-location .nav-tabs .nav-item a p {
  color: #777777;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.map-location .nav-tabs .nav-item a:hover p {
  color: #777777;
}

.map-location .nav-tabs .nav-link:hover span {
  color: #197beb;
}

.map-location .nav-tabs .nav-item a:hover span {
  color: #00c1ff;
}

.number {
  position: absolute;
  right: 25px;
  top: 37%;
  color: #1772ff;
}

.map-location .nav-tabs .nav-link.active span {
  color: #00c1ff;
}

#scroll-map::-webkit-scrollbar {
  width: 16px;
  background-color: red;
}

.dot-circle.num-2 {
  left: 50%;
}

.active .dot-circle {
  background-image: url(../../media/images/icons/marrow2.png);
  transform: rotate(0deg);
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}

.active .dot-circle .dot {
  background: #00c1ff;
}

.dot-circle.num-4 {
  top: 27%;
  left: 75%;
}

.dot-circle.num-1 {
  left: 28%;
  top: 70%;
}

.dot-circle.num-2 {
  left: 56%;
  top: 24%;
}

.dot-circle.num-5 {
  left: 32%;
  top: 9%;
}

.dot-circle.num-6 {
  left: 79%;
  top: 75%;
}

.dot-circle.num-1::after {
  content: '2';
}

.dot-circle.num-2::after {
  content: '3';
}

.dot-circle.num-4::after {
  content: '4';
}

.dot-circle.num-5::after {
  content: '5';
}

/* .active  .dot-circle::after {
	background: #17b212;
	color: #fff;
	@include transition(all .8s);
} */
@media (min-width: 1200px) and (max-width: 1429px) {
  .map-location .nav-tabs .nav-item a span {
    font-size: 16px;
  }
  .map-location .nav-tabs .nav-item a p {
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .map-location {
    height: 314px;
  }
  .map-location .nav-tabs .nav-item a span {
    font-size: 16px;
  }
  .map-location .nav-tabs .nav-item a p {
    font-size: 15px;
  }
  .map-location .nav-tabs .nav-link.active {
    border-top-width: 3px;
  }
}

@media only screen and (max-width: 991px) {
  .map-location {
    height: 297px;
    width: 100%;
  }
  .map-location .nav-tabs .nav-item a span {
    font-size: 16px;
  }
  .map-location .nav-tabs .nav-item a p {
    font-size: 15px;
  }
  .map-location .nav-tabs .nav-link.active {
    border-top-width: 3px;
  }
  .map-area {
    width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  Testimonial 1
  --------------------------------------------------------------*/
.testimonial_1 {
  position: relative;
}

.single-testimonial {
  text-align: center;
  padding: 0 40px;
}

.single-testimonial p {
  color: #6c798c;
  font-size: 15px;
  font-style: italic;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 17px;
  margin-top: 10px;
}

.single-testimonial .designation {
  margin-top: 0;
  line-height: 20px;
}

.single-testimonial span {
  font-size: 14px;
  color: #00c1ff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  position: relative;
  margin-left: 26px;
}

.single-testimonial span:before {
  position: absolute;
  content: '';
  top: 10px;
  left: -25px;
  width: 18px;
  height: 1px;
  background: #aac8d2;
}

@media only screen and (max-width: 1429px) {
  .single-testimonial {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 757px) {
  .single-testimonial {
    padding: 0 0px;
  }
}

/*--------------------------------------------------------------
  ##  Testimonial 2
  --------------------------------------------------------------*/
.center .single-testimonial-2 {
  text-align: center;
  padding: 50px 40px;
  background: #fff;
  box-shadow: 0px 1px 13px 0px rgba(209, 206, 206, 0.36);
  border-radius: 15px;
  position: relative;
  overflow-x: hidden;
}

.center .single-testimonial-2 i {
  text-align: center;
  color: #0b67f4;
  font-size: 28px;
}

.center .single-testimonial-2 p {
  color: #808c9e;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 25px;
}

.center .single-testimonial-2 .bottom-content {
  text-align: left;
  overflow: hidden;
  margin-top: 25px;
  margin-left: 22px;
}

.center .single-testimonial-2 .bottom-content .tes-image {
  float: left;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid #0b67f4;
  padding: 3px;
  margin-right: 20px;
}

.center .single-testimonial-2 .bottom-content .tes-image img {
  border-radius: 50px;
}

.center .single-testimonial-2 .bottom-content h6 {
  color: #2a384c;
  font-size: 16px;
  margin-top: 7px;
}

.center .single-testimonial-2 .bottom-content span {
  font-size: 14px;
  color: #808c9e;
}

.single-testimonial-2 {
  text-align: center;
  padding: 50px 40px;
  background: #fcfdff;
  box-shadow: 0px 1px 13px 0px rgba(209, 206, 206, 0.36);
  border-radius: 15px;
  position: relative;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.single-testimonial-2 i {
  text-align: center;
  color: #95aefc;
  font-size: 28px;
}

.single-testimonial-2 p {
  color: #808c9e;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 25px;
}

.single-testimonial-2 .bottom-content {
  text-align: left;
  overflow: hidden;
  margin-top: 25px;
  margin-left: 22px;
}

.single-testimonial-2 .bottom-content .tes-image {
  float: left;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid #0b67f4;
  padding: 3px;
  margin-right: 20px;
}

.single-testimonial-2 .bottom-content .tes-image img {
  border-radius: 50px;
}

.single-testimonial-2 .bottom-content h6 {
  color: #9199a5;
  font-size: 16px;
  margin-top: 7px;
}

.single-testimonial-2 .bottom-content span {
  font-size: 14px;
  color: #9aa1ac;
}

.single-testimonial-2::after {
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
  z-index: 99999;
  border-radius: 12px;
}

.testimonial-2 .center .single-testimonial-2::after {
  opacity: 0;
}

.testimonial-2 .item-wrap {
  padding: 5px 15px;
}

.testimonial-2.owl-theme .owl-nav [class*="owl-"] {
  color: #0b67f4;
  font-size: 28px;
  margin: 5px;
  padding: 0;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 37%;
  border: 1px solid #0b67f4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.testimonial-2.owl-theme .owl-nav .owl-next {
  right: -200px;
}

.testimonial-2.owl-theme .owl-nav .owl-prev {
  left: -200px;
}

.testimonial-2.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: #0b67f4;
}

.testimonial-2 .owl-item.center {
  transform: translateY(-70px);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial-2 .owl-item {
  padding-top: 80px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 991px) {
  .testimonial-2 .owl-item {
    padding-top: 0;
  }
}

/*--------------------------------------------------------------
  ##  Testimonial four
  --------------------------------------------------------------*/
.testimonial_4 {
  background: #f8faff;
}

.single-testimonial-4 {
  text-align: center;
  padding: 50px 40px;
  background: #fff;
  box-shadow: 0px 1px 13px 0px rgba(209, 206, 206, 0.36);
  border-radius: 15px;
  position: relative;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.single-testimonial-4 i {
  text-align: center;
  color: #3263f9;
  font-size: 28px;
}

.single-testimonial-4 p {
  color: #67778e;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 25px;
}

.single-testimonial-4 .bottom-content {
  overflow: hidden;
  margin-top: 25px;
  margin-left: 22px;
}

.single-testimonial-4 .bottom-content .tes-image {
  float: left;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid #0b67f4;
  padding: 3px;
  margin-right: 20px;
}

.single-testimonial-4 .bottom-content .tes-image img {
  border-radius: 50px;
}

.single-testimonial-4 .bottom-content h6 {
  color: #2a384c;
  font-size: 16px;
  margin-top: 7px;
  font-weight: 700;
}

.single-testimonial-4 .bottom-content span {
  font-size: 14px;
  color: #67778e;
}

.testimonial-4.owl-theme .owl-nav [class*="owl-"] {
  color: #0b67f4;
  font-size: 28px;
  margin: 5px;
  padding: 0;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  border: 1px solid #0b67f4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 42px;
  transform: translateY(-50%);
}

.testimonial-4.owl-theme .owl-nav .owl-next {
  right: -110px;
}

.testimonial-4.owl-theme .owl-nav .owl-prev {
  left: -110px;
}

.testimonial-4.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: #0b67f4;
}

.testimonial-4 .owl-item.center {
  transform: translateY(-70px);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial-4 .owl-item {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial-4.owl-theme .owl-nav .owl-next img {
  transform: rotate(180deg);
}

@media (min-width: 1200px) and (max-width: 1429px) {
  .center .single-testimonial-2 {
    padding: 50px 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .center .single-testimonial-2 {
    padding: 50px 25px;
  }
  .center .single-testimonial-2 .bottom-content,
  .single-testimonial-2 .bottom-content {
    margin-left: 0;
    text-align: center;
  }
  .center .single-testimonial-2 .bottom-content .tes-image,
  .single-testimonial-2 .bottom-content .tes-image {
    width: 66px;
    float: none;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1199px) {
  .testimonial-4 .owl-nav {
    opacity: 0;
    transition: .3s;
  }
  .testimonial-4:hover .owl-nav {
    opacity: 1;
  }
  .testimonial-4.owl-theme .owl-nav .owl-next {
    right: 15px;
  }
  .testimonial-4.owl-theme .owl-nav .owl-prev {
    left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .single-testimonial-2 {
    padding: 50px 25px;
  }
  .center .single-testimonial-2 .bottom-content,
  .single-testimonial-2 .bottom-content {
    margin-left: 0;
    text-align: center;
  }
  .center .single-testimonial-2 .bottom-content .tes-image,
  .single-testimonial-2 .bottom-content .tes-image {
    width: 66px;
    float: none;
    margin: 0 auto;
  }
  .single-testimonial-4 {
    padding: 40px 20px;
  }
}

/*--------------------------------------------------------------
  ##  Client 1
  --------------------------------------------------------------*/
.client_slider {
  background: #2a384c;
}

.client-slider .item {
  text-align: center;
}

.client-slider .item img {
  display: block;
  width: auto;
  margin: 0 auto;
  filter: brightness(0) invert(1);
}

/*--------------------------------------------------------------
  ##  About 1
  --------------------------------------------------------------*/
.about-1 {
  background-image: url(../../media/images/bg/line_bg1.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.about-1 .about-number {
  display: flex;
  align-items: flex-start;
}

.about-1 .about-number .number-wrap {
  font-weight: 700;
}

.about-1 .about-number .number-wrap .digit {
  font-size: 230px;
  line-height: 164px;
  background: radial-gradient(closest-side, transparent, #1b4db8 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-1 .about-number .number-wrap .prefix {
  font-size: 50px;
  color: #1b4db8;
  top: 0;
  left: -8px;
}

.about-1 .about-number img {
  float: left;
}

.about-1 .about-number .about-number-des {
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  margin-left: 21px;
  width: 250px;
}

.about-1 .about-number .about-number-des p {
  font-size: 18px;
  color: #4e5c6f;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.about-1 .about-one-img {
  position: relative;
}

.about-1 .about-one-img:after {
  content: '';
  position: absolute;
  left: -40px;
  top: 94px;
  width: 100%;
  height: 91%;
  border: 20px solid #f7fcff;
  opacity: .74;
  z-index: -1;
}

.about-1 .block-content {
  margin-bottom: 58px;
}

.block-content p {
  font-size: 15px;
  color: #808c9e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .about-1 .section_header.style-two h3 {
    font-size: 31px;
  }
  .about-1 .about-number .number-wrap .digit {
    font-size: 159px;
    line-height: 128px;
  }
  .about-1 .about-number .number-wrap .prefix {
    font-size: 44px;
  }
  .about-1 .about-number .about-number-des {
    width: auto;
    max-width: 250px;
  }
  .about-1 .about-number .about-number-des p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 4px;
  }
}

@media only screen and (max-width: 991px) {
  .about-1 .about-one-img {
    margin-top: 60px;
  }
  .about-1 .block-content {
    margin-bottom: 34px;
  }
  .about-1 .about-number .number-wrap .digit {
    font-size: 110px;
    line-height: 86px;
  }
  .about-1 .about-number .number-wrap .prefix {
    font-size: 36px;
  }
  .about-1 .about-number .about-number-des {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .about-1 .section_header.style-two h3 {
    font-size: 26px;
  }
  .about-1 .about-number {
    flex-wrap: wrap;
  }
}

/* About two css */
.about-image {
  position: relative;
}

.about-image::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #e5edfa;
  left: 20px;
  bottom: -20px;
  z-index: -1;
}

.about_2 .inner-content-box {
  padding-right: 115px;
}

.sin-about-2 {
  margin-top: 35px;
}

.sin-about-2 .icon {
  width: 14%;
  float: left;
}

.sin-about-2 .icon span {
  font-size: 50px;
  color: #edeef0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  padding-left: 13px;
}

.sin-about-2 .about-2-content {
  overflow: hidden;
  padding-right: 60px;
}

.sin-about-2 .about-2-content h6 {
  font-size: 18px;
  color: #354358;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition: all .3s;
}

.sin-about-2 .about-2-content p {
  color: #808c9e;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.sin-about-2:hover .about-2-content h6 {
  color: #00c1ff;
}

/*--------------------------------------------------------------
  ##  Service 1
  --------------------------------------------------------------*/
.service-1 .inner-content-box {
  padding-left: 25px;
}

.service-1 .btn-3 {
  margin-top: 25px;
}

.single-service-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -10px 0;
}

.single-service-wrap .single-service-col {
  width: 100%;
  max-width: 50%;
  padding: 0 10px;
  padding-top: 20px;
}

.single-service {
  text-align: center;
  background: #fff;
  padding: 30px 20px 25px;
  box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
}

.single-service h6 {
  font-size: 18px;
  color: #2a384c;
  position: relative;
  margin-bottom: 13px;
  margin-top: 20px;
  padding-bottom: 15px;
}

.single-service h6:hover {
  color: #0b67f4;
}

.single-service h6:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 40px;
  height: 2px;
  background: #a7bdff;
  transform: translateX(-50%);
}

.single-service p {
  font-size: 14px;
  color: #808c9e;
  line-height: 26px;
  font-weight: 500;
}

.btn-3 {
  font-size: 15px;
  color: #fff;
  padding: 7px 22px;
  border-radius: 26px;
  font-weight: 500;
  z-index: 50;
  background: #3263f9;
  text-transform: uppercase;
  border: 3px solid #f7fbfe;
  box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);
}

.service_4 .single-service {
  width: 100%;
}

@media only screen and (max-width: 1429px) {
  .service-1 .inner-content-box {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .service-1 .inner-content-box {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .single-service-wrap .single-service-col {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  Service 2
  --------------------------------------------------------------*/
.single-service-two {
  width: 71%;
  float: left;
  background: #fff;
  padding: 25px 40px;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
  box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
  margin-bottom: 40px;
}

.single-service-two h6 {
  font-size: 18px;
  color: #2a384c;
  position: relative;
  padding-bottom: 7px;
  display: inline;
  font-weight: 700;
  margin-left: 20px;
}

.single-service-two h6:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 1px;
  background: #0b67f4;
  margin-bottom: 0px;
}

.single-service-two h6:hover {
  color: #0b67f4;
}

.single-service-two p {
  font-size: 14px;
  color: #808c9e;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}

.single-service-two:nth-child(2) {
  margin-left: 80px;
}

.single-service-two img {
  float: left;
  width: 55px;
}

.service-2 .inner-content-box {
  padding-left: 25px;
  margin-top: 80px;
  padding-right: 50px;
}

.service-2 .btn-3 {
  margin-top: 25px;
}

.btn-3:hover {
  color: #000;
}

.service-2 {
  background-image: url(../../media/images/bg/square.png), url(../../media/images/bg/square2.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom right,center left;
}

.service-3 .inner-content-box {
  padding-left: 25px;
  margin-top: 80px;
  padding-right: 145px;
}

.service-3 .btn-3 {
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .service-2 .inner-content-box {
    margin-bottom: 60px;
    padding-left: 0px;
    margin-top: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .single-service-two {
    width: 83%;
  }
}

@media only screen and (max-width: 575px) {
  .single-service-two {
    width: 100%;
    padding: 20px;
  }
  .single-service-two:nth-child(2) {
    margin-left: 0px;
  }
}

/*--------------------------------------------------------------
  ##  Widget
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
##  Blog Widget
--------------------------------------------------------------*/
.sidebar .widget {
  background: #fff;
  padding: 20px 20px 40px 20px;
  margin-bottom: 30px;
}

.widget-title {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 20px;
}

.sidebar .widget p {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #4f5d72;
  text-align: center;
  line-height: 28px;
}

.about-widget {
  text-align: center;
}

.about-widget p {
  margin-top: 24px;
  padding: 0 10px;
}

.search-form input {
  width: 84%;
  border: none;
  background: transparent;
  padding: 13px;
  color: #808c9e;
  font-size: 14px;
}

.search-form button {
  border: none;
  background: transparent;
  color: #fff;
  background: #00c1fe;
  height: 100%;
  width: 14%;
  padding: 11px 0;
  float: right;
  cursor: pointer;
}

.search-form {
  width: 100%;
  background: #f0f2f8;
}

.widget-post {
  padding-bottom: 30px;
  border-bottom: 1px solid #f5f6fa;
  margin-bottom: 30px;
  display: flex;
}

.widget-post .post-image {
  width: 100%;
  max-width: 115px;
  margin-right: 15px;
}

.widget-post .post-content {
  margin-top: -4px;
  width: 100%;
}

.widget-post .post-content h5 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  text-align: left;
}

.widget-post .post-content h5 a {
  color: #2a384c;
}

.widget-post .post-content h5 a:hover {
  color: #00c1ff;
}

.widget-post .post-content span a {
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  color: #0b67f4;
}

.widget-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.category-widget ul li {
  border-bottom: 1px solid #f1f3f8;
  padding: 15px 0;
}

.category-widget ul li a {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #435062;
  width: 100%;
}

.category-widget ul li a span {
  float: right;
  background: #f1f3f8;
  width: 30px;
  height: 22px;
  text-align: center;
}

.category-widget ul li a:hover {
  color: #00c1ff;
}

.category-widget ul li:last-child {
  border-bottom: none;
}

.tag-widget ul li {
  display: inline-block;
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 19px;
  margin-right: 6px;
  transition: all .3s;
}

.tag-widget ul li:hover {
  border: 1px solid transparent;
}

.tag-widget ul li:hover a {
  border: solid 3px #f7fcff;
  box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.23);
  background: #00c1ff;
  color: #fff;
  transition: all .3s;
}

.tag-widget ul li a {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #435062;
  border-radius: 19px;
  text-align: center;
  padding: 5px 16px;
  border: solid 3px transparent;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .widget-post .post-image {
    max-width: 90px;
  }
  .widget-post .post-content h5 {
    line-height: 20px;
  }
  .widget-post .post-content span a {
    line-height: 18px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .widget-post .post-image {
    max-width: 90px;
  }
  .widget-post .post-content h5 {
    line-height: 20px;
    margin-bottom: 2px;
  }
  .widget-post .post-content span a {
    line-height: 18px;
    font-size: 13px;
  }
}

/*--------------------------------------------------------------
  ##  Feature 2
  --------------------------------------------------------------*/
.blue-box h6 {
  font-size: 18px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.blue-box p {
  font-size: 15px;
  color: #bccdff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.blue-box a {
  font-size: 12px;
  color: #fff;
  padding: 7px 18px;
  border-radius: 26px;
  font-weight: 500;
  z-index: 50;
  background: #3263f9;
  border: 2px solid #f7fbfe;
  margin-top: 27px;
}

.blue-box img {
  float: right;
}

.blue-box {
  background: #1445d9;
  padding: 40px 35px 30px;
  border-radius: 5px;
  overflow: hidden;
}

.box-half {
  width: 50%;
  float: left;
  padding: 0 10px;
  margin-top: 5px;
}

.feature-3 .btn-3 {
  margin-top: 22px;
}

.line-bg {
  padding-top: 290px !important;
  background-image: url(../../media/images/bg/linebg.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top left;
}

@media only screen and (max-width: 1429px) {
  .about_2 .inner-content-box {
    padding-right: 0px;
  }
  .feature-3 .section_header.style-two {
    margin-bottom: 12px;
  }
  .feature-3 .section_header.style-two h3 {
    font-size: 30px;
  }
  .feature-3 .btn-3 {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-3 .inner-content-box {
    text-align: center;
    margin-bottom: 35px;
  }
  .feature-3 .section_header.style-two {
    text-align: center;
  }
  .feature-3 .block-content p {
    max-width: 566px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 991px) {
  .feature-3 .blue-box {
    padding: 35px 25px 30px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-3 .box-half {
    width: 100%;
    float: none;
  }
}

@media only screen and (max-width: 575px) {
  .feature-3 .section_header.style-two h3 {
    font-size: 24px;
  }
  .sin-about-2 .icon {
    width: inherit;
    float: none;
    margin-bottom: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Feature tab Plan
  --------------------------------------------------------------*/
.hosting_plan_tab .nav-tabs {
  justify-content: center;
  border: 1xp solid #dceafe;
  margin-top: -15px;
}

.hosting_plan_tab .nav-tabs li {
  margin-bottom: 0;
  margin-top: 15px;
}

.hosting_plan_tab .nav-tabs li a {
  border-radius: 0;
  background: #fff;
  border: 0;
  box-shadow: 0px 3px 8.5px 1.5px rgba(13, 43, 134, 0.07);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  color: #2a384c;
  margin-right: 20px;
  padding: 10px 20px;
}

.hosting_plan_tab .nav-tabs li a:hover, .hosting_plan_tab .nav-tabs li a.active {
  color: #fff;
}

.hosting_plan_tab .nav-tabs li:first-child a {
  border-radius: 5px 0 0 0;
}

.hosting_plan_tab .nav-tabs li:last-child a {
  border-radius: 0 5px 0 0;
  margin-right: 0;
}

.hosting_plan_content_item {
  max-width: 765px;
  margin: 40px auto 0;
}

.hosting_plan_content_item .detail-item {
  display: flex;
  align-items: center;
  padding: 40px 0;
  border-bottom: 1px dashed rgba(25, 123, 235, 0.33);
  position: relative;
}

.hosting_plan_content_item .detail-item .item-img {
  width: 100%;
  max-width: 20%;
}

.hosting_plan_content_item .detail-item .item-img:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 55px;
  height: 50%;
  width: 1px;
  border-right: 1px dashed rgba(25, 123, 235, 0.5);
}

.hosting_plan_content_item .detail-item .item-img img {
  position: relative;
  z-index: 3;
}

.hosting_plan_content_item .detail-item .item-detail {
  width: 100%;
  max-width: 80%;
  padding-left: 30px;
}

.hosting_plan_content_item .detail-item .item-detail h4 {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 0;
}

.hosting_plan_content_item .detail-item .item-detail h4:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 20px;
  height: 1px;
}

.hosting_plan_content_item .detail-item .item-detail p {
  margin: 20px 0 0;
  font-size: 14px;
}

.hosting_plan_content_item .detail-item.img-right .item-img {
  order: 2;
}

.hosting_plan_content_item .detail-item.img-right .item-img:after {
  left: inherit;
  bottom: inherit;
  top: 0;
  right: 102px;
}

.hosting_plan_content_item .detail-item.img-right .item-detail {
  text-align: right;
  padding-left: 0;
  padding-right: 30px;
}

.hosting_plan_content_item .detail-item.img-right .item-detail h4:after {
  left: inherit;
  right: 0;
}

.hosting_plan_content_item .detail-item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 757px) {
  .hosting_plan_content_item .detail-item .item-img {
    max-width: 35%;
  }
}

@media only screen and (max-width: 575px) {
  .hosting_plan_content_item .detail-item {
    flex-wrap: wrap;
  }
  .hosting_plan_content_item .detail-item .item-img {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .hosting_plan_content_item .detail-item .item-img:after {
    content: none;
  }
  .hosting_plan_content_item .detail-item .item-detail {
    max-width: 100%;
    padding-left: 0;
  }
  .hosting_plan_content_item .detail-item.img-right .item-img {
    order: inherit;
    text-align: right;
  }
}

/*--------------------------------------------------------------
  ##  Blog 1
  --------------------------------------------------------------*/
.blog-starter {
  margin: 0 -15px;
}

.blog-starter .item-wrap {
  padding: 0 15px;
}

.play-button a {
  background: #0b67f4;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
  color: #fff;
}

.banner-ico {
  position: absolute;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
  animation: bounce 1s ease-in-out 0s infinite alternate;
}

.signal {
  width: 90px;
  pointer-events: none;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: #0b67f4;
  margin-left: -45px;
  margin-top: -45px;
  z-index: -5;
}

.signal {
  -webkit-animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
  animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: .78s;
  animation-delay: .78s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.signal2 {
  width: 90px;
  pointer-events: none;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: #0b67f4;
  margin-left: -45px;
  margin-top: -45px;
  z-index: -5;
}

.signal2 {
  -webkit-animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
  animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: .7s;
  animation-delay: .7s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.play-button {
  position: relative;
  width: 63px;
  float: left;
}

.video-area {
  margin-top: 40px;
  margin-left: 12px;
}

.video-title {
  margin-top: 19px;
  display: inline-block;
  margin-left: 16px;
  font-size: 14px;
  color: #0b67f4;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

@-webkit-keyframes animationSignal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  1% {
    opacity: .14;
  }
  20% {
    opacity: .14;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animationSignal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  1% {
    opacity: .14;
  }
  20% {
    opacity: .14;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  1% {
    opacity: .29;
  }
  20% {
    opacity: .29;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animationSignal2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  1% {
    opacity: .29;
  }
  20% {
    opacity: .29;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.single-blog {
  box-shadow: 0px 0px 16px 0px rgba(57, 68, 81, 0.07);
  padding-bottom: 20px;
}

.single-blog .sin-blog-content {
  padding: 25px;
}

.single-blog .sin-blog-content .cat-links {
  margin-bottom: 10px;
}

.single-blog .sin-blog-content .cat-links a {
  color: #0b67f4;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.single-blog .sin-blog-content .cat-links a:hover {
  color: #16a929;
}

.single-blog .sin-blog-content .entry-title {
  margin-bottom: 12px;
  font-size: 20px;
  color: #435062;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.single-blog .sin-blog-content .entry-title a {
  color: #435062;
}

.single-blog .sin-blog-content .entry-title a:hover {
  color: #16a929;
}

.single-blog .sin-blog-content .meta {
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.single-blog .sin-blog-content .meta a {
  color: #5b6470;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-right: 10px;
}

.single-blog .sin-blog-content .meta a i {
  margin-right: 5px;
}

.single-blog .sin-blog-content .meta a:last-child {
  margin-right: 0;
}

.single-blog .sin-blog-content .meta a:hover {
  color: #16a929;
}

.single-blog .sin-blog-content .meta a:first-child {
  padding-left: 0;
}

.single-blog .sin-blog-content > a {
  font-size: 14px;
  color: #0b67f4;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.single-blog .sin-blog-content > a:hover {
  color: #16a929;
}

@media only screen and (max-width: 575px) {
  .single-blog .sin-blog-content .meta a {
    font-size: 13px;
  }
  .single-blog .sin-blog-content {
    padding: 14px 20px;
  }
}

/*--------------------------------------------------------------
  ##  Blog 2
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Blog  2
  --------------------------------------------------------------*/
.blog_2 {
  background: #fdfdff;
}

.single-blog-2 .sin-blog-content {
  padding: 25px 0 0;
}

.single-blog-2 .sin-blog-content .entry-content p {
  color: #808c9e;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.single-blog-2 .sin-blog-content .entry-content a {
  color: #808c9e;
}

.single-blog-2 .sin-blog-content .entry-content a:hover {
  color: #16a929;
}

.single-blog-2 .sin-blog-content .cat-links {
  margin-bottom: 10px;
}

.single-blog-2 .sin-blog-content .cat-links a {
  color: #4287ff;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.single-blog-2 .sin-blog-content .cat-links a:hover {
  color: #16a929;
}

.single-blog-2 .sin-blog-content .entry-title {
  margin-bottom: 12px;
  font-size: 20px;
  color: #52575f;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-weight: 700;
}

.single-blog-2 .sin-blog-content .entry-title a {
  color: #435062;
}

.single-blog-2 .sin-blog-content .entry-title a:hover {
  color: #16a929;
}

.single-blog-2 .sin-blog-content .meta a {
  color: #2a384c;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.single-blog-2 .sin-blog-content .meta a i {
  margin-right: 5px;
}

.single-blog-2 .sin-blog-content .meta a:hover {
  color: #16a929;
}

.single-blog-2 .sin-blog-content > a {
  font-size: 14px;
  color: #0b67f4;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.single-blog-2 .sin-blog-content > a:hover {
  color: #16a929;
}

.single-blog-2 > a {
  box-shadow: 0px 1px 3.8px 0.2px rgba(105, 105, 105, 0.09);
  border: 4px solid #fff;
}

@media only screen and (max-width: 757px) {
  .single-blog-2 .sin-blog-content {
    padding: 20px 0 0;
  }
}

/*--------------------------------------------------------------
  ##  Blog List
  --------------------------------------------------------------*/
.list-view .single-blog {
  box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.06);
  padding: 20px;
  overflow: hidden;
  margin-bottom: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.list-view .single-blog > a {
  max-width: 27%;
  width: 100%;
}

.list-view .single-blog > a img {
  width: 100%;
}

.list-view .single-blog .sin-blog-content {
  width: 100%;
  max-width: 73%;
  padding: 0px 10px 0 30px;
}

.list-view .single-blog .sin-blog-content .cat-links {
  margin-bottom: 10px;
}

.list-view .single-blog .sin-blog-content .cat-links a {
  color: #0b67f4;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.list-view .single-blog .sin-blog-content .cat-links a:hover {
  color: #00c1ff;
}

.list-view .single-blog .sin-blog-content .entry-title {
  margin-bottom: 12px;
  font-size: 23px;
  color: #435062;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 32px;
}

.list-view .single-blog .sin-blog-content .entry-title a {
  color: #435062;
}

.list-view .single-blog .sin-blog-content .entry-title a:hover {
  color: #00c1ff;
}

.list-view .single-blog .sin-blog-content .meta {
  margin-bottom: 20px;
}

.list-view .single-blog .sin-blog-content .meta a {
  color: #5b6470;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.list-view .single-blog .sin-blog-content .meta a i {
  margin-right: 5px;
}

.list-view .single-blog .sin-blog-content .meta a:hover {
  color: #00c1ff;
}

.list-view .single-blog .sin-blog-content > a {
  font-size: 15px;
  color: #fff;
  padding: 7px 22px;
  border-radius: 26px;
  font-weight: 500;
  z-index: 50;
  background: #3263f9;
  margin-top: 10px;
}

.list-view .single-blog .sin-blog-content > a:hover {
  color: #00c1ff;
}

.blog-wrapper {
  background: #f5f6fa;
  padding: 120px 0;
}

.blog-pagination {
  background: #fff;
  padding: 20px 30px;
  border-radius: 51px;
  text-align: center;
  box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.06);
}

.blog-pagination ul li {
  display: inline-block;
  padding: 0 5px;
}

.blog-pagination ul li a {
  font-size: 14px;
  color: #2F5774;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.blog-pagination ul li a:hover {
  color: #00c1ff;
}

.blog-pagination ul li:first-child {
  float: left;
}

.blog-pagination ul li:first-child a {
  color: #0b67f4;
}

.blog-pagination ul li:first-child a:hover {
  color: #00c1ff;
}

.blog-pagination ul li:last-child {
  float: right;
}

.blog-pagination ul li:last-child a {
  color: #0b67f4;
}

.blog-pagination ul li:last-child a:hover {
  color: #00c1ff;
}

@media (min-width: 1200px) and (max-width: 1649px) {
  .list-view .single-blog > a {
    max-width: 35%;
  }
  .list-view .single-blog .sin-blog-content {
    max-width: 65%;
  }
  .list-view .single-blog .sin-blog-content .entry-title {
    font-size: 21px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .list-view .single-blog > a {
    max-width: 44%;
  }
  .list-view .single-blog .sin-blog-content {
    max-width: 56%;
    padding: 0 0 0 20px;
  }
  .list-view .single-blog .sin-blog-content .entry-title {
    font-size: 17px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 991px) {
  .list-view .single-blog > a {
    max-width: 36%;
  }
  .list-view .single-blog .sin-blog-content {
    max-width: 64%;
    padding: 0 0 0 20px;
  }
  .list-view .single-blog .sin-blog-content .entry-title {
    font-size: 19px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .list-view .single-blog > a {
    max-width: 100%;
  }
  .list-view .single-blog .sin-blog-content {
    max-width: 100%;
    padding: 20px 0 0 0;
  }
  .list-view .single-blog .sin-blog-content .entry-title {
    font-size: 19px;
    line-height: 27px;
  }
}

/*--------------------------------------------------------------
  ##  Blog Detail
  --------------------------------------------------------------*/
.blog-detail-page .single-blog {
  background: #fff;
  box-shadow: none;
}

.single-blog .sin-blog-content .category-title {
  color: #67778e;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.single-blog .sin-blog-content .entry-content p {
  color: #67778e;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.single-blog .sin-blog-content h6 {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin: 25px 0 22px;
}

.single-blog .sin-blog-content img {
  margin: 20px 0 35px;
}

.single-blog .blog-bottom-tag {
  display: inline-block;
  float: left;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #67778e;
  margin-top: 7px;
}

.single-blog .blog-bottom-tag a {
  color: #0b67f4;
}

.social-blog {
  float: right;
}

.social-blog ul li {
  display: inline-block;
  margin-left: 8px;
}

.social-blog ul li:first-child {
  margin-left: 0;
}

.social-blog ul li a {
  color: #67778e;
  font-size: 12px;
  width: 30px;
  height: 30px;
  border: 1px solid #67778e;
  text-align: center;
  line-height: 28px;
  border-radius: 50px;
  transition: all .3s;
}

.social-blog ul li a:hover {
  color: #00c1ff;
  border: 1px solid #00c1ff;
  transition: all .3s;
}

.author-social ul li {
  display: inline-block;
  margin-right: 8px;
}

.author-social ul li a {
  color: #67778e;
  font-size: 12px;
  width: 30px;
  height: 30px;
  border: 1px solid #67778e;
  text-align: center;
  line-height: 28px;
  border-radius: 50px;
  transition: all .3s;
}

.author-social ul li a:hover {
  color: #00c1ff;
  border: 1px solid #00c1ff;
  transition: all .3s;
}

.blog-bottom-wrapper {
  overflow: hidden;
  padding-top: 35px;
}

.blog-author-info {
  background: #ffffff;
  padding: 35px;
  display: flex;
  margin-top: 20px;
}

.blog-author-info .author-image {
  overflow: hidden;
  width: 100%;
  max-width: 140px;
  height: 100%;
  border: 5px solid #ffffff;
  border-radius: 50%;
  margin-right: 30px;
  box-shadow: 0px 2px 15.2px 0.8px rgba(105, 105, 105, 0.2);
}

.blog-author-info .author-info h6 {
  font-size: 18px;
  font-weight: 700;
  color: #2a384c;
}

.related-article-wrap {
  margin-top: 40px;
}

.related-article-wrap .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.related-article-wrap .section-header a {
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #707f95;
  border: 1px solid #707f95;
  border-radius: 50%;
  transition: .3s;
}

.related-article-wrap .section-header a:hover {
  color: #00c1ff;
  border-color: #00c1ff;
}

.related-article-wrap .section-header h4 {
  color: #2a384c;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.related-article-items {
  display: flex;
}

.related-article-items .article-item {
  background: #fff;
  padding: 20px 20px 30px;
  text-align: center;
  width: 100%;
  max-width: calc(50% - 15px);
  margin-top: 25px;
}

.related-article-items .article-item h2 {
  font-size: 18px;
  line-height: 27px;
  color: #2a384c;
  font-weight: 700;
}

.related-article-items .article-item .public-date {
  color: #0b67f4;
  font-weight: 600;
  font-size: 14px;
}

.related-article-items .article-item .title {
  margin-top: 15px;
}

.related-article-items .article-item a:hover h4 {
  color: #0b67f4;
}

.related-article-items .article-item:first-child {
  margin-right: 30px;
}

.comments-wrap {
  margin-top: 30px;
  padding: 40px 30px;
  background: #fff;
}

.comments-wrap .section-header {
  text-align: center;
  margin-bottom: 40px;
}

.comments-wrap .section-header h4 {
  color: #2a384c;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.comment-list .comment-item {
  padding: 30px 0;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #eeeeee;
}

.comment-list .comment-item .commenter-img {
  width: 100%;
  max-width: 90px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 2px 22.8px 1.2px rgba(105, 105, 105, 0.2);
  border: 3px solid #ffffff;
}

.comment-list .comment-item .comment-content {
  padding-left: 30px;
}

.comment-list .comment-item .comment-content .name-wrap a {
  font-size: 18px;
  font-weight: 700;
  color: #2a384c;
  margin-right: 20px;
}

.comment-list .comment-item .comment-content .name-wrap .date {
  padding-left: 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.comment-list .comment-item .comment-content .name-wrap .date:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 15px;
  width: 1px;
  background: #707f95;
}

.comment-list .comment-item .comment-content .speech {
  font-size: 15px;
  line-height: 28px;
  margin-top: 10px;
}

.comment-list .comment-item .comment-content .reply {
  font-size: 14px;
  font-weight: 600;
}

.comment-list .comment-item .comment-content .reply i {
  margin-right: 2px;
}

.comment-list .comment-child {
  margin-left: 80px;
  padding-left: 0;
  list-style: none;
}

.comment-list .comment-child li:last-child {
  border-bottom: 0;
}

.comment-form-wrap {
  margin-top: 30px;
  padding: 40px 30px;
  background: #fff;
}

.comment-form-wrap .section-header {
  text-align: center;
  margin-bottom: 40px;
}

.comment-form-wrap .section-header h4 {
  color: #2a384c;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.comment-form input,
.comment-form textarea {
  padding: 15px;
  width: 100%;
  border: 1px solid #ebebeb;
  font-size: 14px;
  color: #67778e;
  font-weight: 600;
  margin-top: 20px;
  border-radius: 3px;
}

.comment-form .input-wrap {
  display: flex;
}

.comment-form .input-wrap .form-input {
  width: 100%;
  max-width: calc(33.3333% - 14px);
  margin-right: 22px;
}

.comment-form .input-wrap .form-input:last-child {
  margin-right: 0;
}

.comment-form .input-wrap input {
  padding: 15px;
}

.comment-form textarea {
  height: 130px;
  resize: none;
}

.comment-form button {
  box-shadow: 0px 1px 7.6px 0.4px rgba(16, 13, 122, 0.14);
  border: 3px solid #ffffff;
  background-color: #00c1ff;
  color: #fff;
  border-radius: 19px;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 24px;
  margin-top: 16px;
  text-transform: uppercase;
}

.comment-form button:focus {
  outline: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .comment-list .comment-child {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .blog-author-info .author-image {
    max-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-detail-page .blog-bottom-wrapper .blog-bottom-tag {
    float: none;
  }
  .blog-detail-page .blog-bottom-wrapper .social-blog {
    float: none;
    margin-top: 20px;
  }
  .related-article-items {
    flex-wrap: wrap;
  }
  .related-article-items .article-item {
    max-width: 100%;
  }
  .related-article-items .article-item:first-child {
    margin-right: 0;
  }
  .comment-list .comment-child {
    margin-left: 50px;
  }
  .comment-list .comment-item .comment-content .name-wrap a {
    margin-right: 10px;
  }
  .comment-list .comment-item .comment-content .name-wrap .date {
    padding-left: 13px;
  }
  .comment-form .input-wrap {
    flex-wrap: wrap;
  }
  .comment-form .input-wrap .form-input {
    max-width: 100%;
    margin: 00px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-author-info {
    flex-wrap: wrap;
    text-align: center;
  }
  .blog-author-info .author-image {
    max-width: 120px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .comment-list .comment-child {
    margin-left: 30px;
  }
  .comment-list .comment-item {
    flex-wrap: wrap;
  }
  .comment-list .comment-item .comment-content {
    padding-left: 0px;
    margin-top: 20px;
  }
  .comment-list .comment-item .comment-content .name-wrap a {
    font-size: 15px;
  }
  .comment-list .comment-item .comment-content .name-wrap .date {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  ##  Plan 1
  --------------------------------------------------------------*/
.plan-1 {
  background-image: url(../../media/images/bg/line_bg2.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.plan-1 .block-content {
  max-width: 620px;
}

/* --------------------------------------------------
            ==  Progressbar Part  ==
--------------------------------------------------- */
.six-sec-ease-in-out {
  -webkit-transition: width 6s ease-in-out;
  -moz-transition: width 6s ease-in-out;
  -ms-transition: width 6s ease-in-out;
  -o-transition: width 6s ease-in-out;
  transition: width 6s ease-in-out;
}

.progress {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c5effd+0,ffffff+100 */
  background: #c5effd;
  /* Old browsers */
  background: -moz-linear-gradient(top, #c5effd 0%, white 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #c5effd 0%, white 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #c5effd 0%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c5effd', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 5px;
  box-shadow: 0px 1px 11.05px 1.95px rgba(13, 37, 110, 0.06);
}

.progress.vertical {
  width: 16px;
  height: 360px;
  float: right;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #304efd;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

.sin-prog-bar {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 64px;
  margin-right: 16px;
}

.sin-prog-bar span {
  position: absolute;
  left: -55px;
  bottom: 78px;
  transform: rotate(-90deg);
  font-size: 15px;
  color: #6e8394;
  font-weight: 400;
  width: 133px;
  display: inline;
  text-transform: uppercase;
}

.bar-wrap {
  margin-top: 60px;
  display: none;
}

.bar-wrap h6 {
  font-size: 15px;
  color: #3263f9;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.bar-wrap.active {
  display: block;
}

.toggle-btn-1 {
  width: 120px;
  height: 35px;
  border-radius: 50px;
  position: relative;
  border: 4px solid #fff;
  cursor: pointer;
  -webkit-transition: background-color .40s ease-in-out;
  -o-transition: background-color .40s ease-in-out;
  transition: background-color .40s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-align: right;
  background: #0b69ec;
  text-align: center;
  box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);
}

.toggle-btn-1 .round-btn {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 78%;
  top: 17px;
  margin-top: -15px;
  -webkit-transition: all .30s ease-in-out;
  -o-transition: all .30s ease-in-out;
  transition: all .30s ease-in-out;
}

.toggle-btn-1 .round-btn.active {
  left: 2px;
}

.toggle-btn-1 .cb-value {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.toggle-btn-1 p {
  font-size: 11px;
  color: #fff;
  display: inline;
  line-height: 0;
  text-transform: uppercase;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 14px;
  width: 100%;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.toggle-btn-1 p.active {
  visibility: visible;
  opacity: 1;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.planning-table > span {
  color: #4c83d9;
  float: left;
  margin-top: 7px;
}

.planning-table-inner .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  border-color: transparent;
  overflow: hidden;
  width: 100%;
  background-image: url(../../media/images/bg/plan-btn-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  justify-content: space-between;
}

.planning-table-inner .nav-tabs .nav-item.show .nav-link,
.planning-table-inner .nav-tabs .nav-link.active {
  border-color: transparent;
  background: transparent;
}

.planning-table-inner .nav-tabs .nav-link:focus,
.planning-table-inner .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.planning-table-inner {
  margin-top: 30px;
}

.planning-table .nav-item span {
  margin-top: 32px;
  color: #c6cace;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  float: left;
}

.planning-table {
  box-shadow: 0px 0px 24px 0px rgba(78, 91, 107, 0.1);
  border-radius: 5px;
  background: #fff;
  padding: 50px;
}

.planning-table .nav-item .active span {
  color: #5c9cfd;
}

.planning-table .nav-item:last-child {
  margin-right: 0;
}

.planning-table .nav-link {
  display: block;
  padding: .5rem 0;
  text-align: left;
  position: relative;
  height: 100%;
}

.planning-table-inner .nav-tabs .nav-link.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-image: url(../../media/images/icons/button.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateX(-50%);
}

.plannig-table-content {
  text-align: center;
  margin-top: 30px;
}

.plannig-table-content ul {
  margin-bottom: 36px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.plannig-table-content ul li {
  color: #8198aa;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: left;
}

.plannig-table-content ul li span {
  float: right;
}

.plannig-table-content ul li i {
  float: left;
  margin-right: 10px;
}

.plannig-table-content ul li:hover {
  color: #085ddf;
}

.plannig-table-content > span {
  color: #7f8793;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: center;
  display: block;
}

.plannig-table-content .btn-3 {
  margin-top: 25px;
}

.total {
  text-align: center;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #3263f9;
  display: none;
}

.total span {
  font-size: 14px;
  color: #8198aa;
}

.total.active {
  display: block;
}

@media (min-width: 1200px) and (max-width: 1429px) {
  .sin-prog-bar {
    width: 59px;
    margin-right: 13px;
  }
  .sin-prog-bar span {
    left: -58px;
  }
}

@media only screen and (max-width: 767px) {
  .sin-prog-bar {
    width: 42px;
    margin-right: 15px;
  }
  .progress.vertical {
    width: 11px;
    height: 260px;
    margin-right: 3px;
  }
}

@media only screen and (max-width: 575px) {
  .planning-table {
    padding: 30px 20px;
  }
  .sin-prog-bar {
    width: 25px;
    margin-right: 6px;
  }
  .sin-prog-bar span {
    font-size: 13px;
  }
  .progress.vertical {
    width: 3px;
    height: 260px;
    margin-right: 0px;
  }
}

/*--------------------------------------------------------------
  ##  Sticky area css
  --------------------------------------------------------------*/
.sticky-area-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.sidebar_sticky {
  position: relative;
  width: 100%;
  min-height: 1px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 39%;
  flex: 0 0 39%;
  max-width: 39%;
}

.sticky-contain {
  position: relative;
  width: 100%;
  min-height: 1px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 61%;
  flex: 0 0 61%;
  max-width: 61%;
  border-left: 1px solid #eff5ff;
}

.provide-1 {
  display: block;
  overflow: hidden;
  background: url(../../media/images/bg/angle1.png);
  background-repeat: repeat;
  background-position: center;
  background-size: auto;
  content: '';
  background-repeat: no-repeat;
  background-position: right 80px;
}

.why-choose-2 {
  background-image: url(../../media/images/bg/angle2.png);
  background-repeat: repeat;
  background-position: center;
  background-size: auto;
  content: '';
  background-repeat: no-repeat;
  background-position: right 80px;
  padding-left: 15px;
  padding-right: 15px;
}

.why-choose-2 .block-content p {
  margin-bottom: 0;
}

.sticky-heading {
  text-align: center;
}

.sticky-heading h2 {
  font-size: 30px;
  font-weight: 800;
  color: #1c2c43;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 20px;
}

.sticky-heading h2 span {
  color: #197beb;
}

.single-provide {
  width: 50%;
  float: left;
  text-align: left;
  margin-top: 40px;
  padding: 0 30px;
}

.single-provide .section_header.style-two {
  margin-bottom: 9px;
}

.single-provide h6 {
  font-size: 18px;
  color: #2a384c;
  position: relative;
  margin-bottom: 20px;
  margin-top: 27px;
  font-weight: 600;
  transition: all .3s;
}

.single-provide h6:hover {
  color: #0b67f4;
  transition: all .3s;
}

.single-provide p {
  font-size: 14px;
  color: #808c9e;
  margin-bottom: 0;
}

.img-shadow img {
  box-shadow: 0.618px 1.902px 6px 0px rgba(15, 59, 128, 0.23);
}

.why-choose-2 .block-content {
  padding-right: 40px;
  padding-top: 18px;
}

.sticky-heading-inner {
  padding-top: 410px;
  padding-bottom: 410px;
  position: relative;
}

.sticky-heading-inner:after {
  position: absolute;
  left: -139px;
  top: 11px;
  width: 100%;
  height: 100%;
  background-image: url(../../media/images/bg/net.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  content: '';
}

/*--------------------------------------------------------------
  ##  Testimonial 3
  --------------------------------------------------------------*/
.single-testimonial-3 {
  background: #f7fcff;
  padding: 30px 20px;
  border: 5px solid #fff;
  box-shadow: 0px 1px 2.85px 0.15px rgba(105, 105, 105, 0.22);
  position: relative;
}

.single-testimonial-3:after {
  position: absolute;
  right: 21px;
  top: 29px;
  width: 45px;
  height: 25px;
  background: url(../../media/images/icons/quatation.png);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto auto;
  background-position: center;
  background-size: auto;
  content: '';
  background-repeat: no-repeat;
}

.single-testimonial-3 h4 {
  font-size: 18px;
  color: #2a384c;
  font-weight: 600;
}

.single-testimonial-3 p {
  font-size: 14px;
  color: #808c9e;
  font-weight: 500;
}

.single-testimonial-3 h6 {
  color: #1c2c43;
  font-size: 16px;
}

.single-testimonial-3 span {
  font-size: 14px;
  color: #808c9e;
}

.single-testimonial-3.mt-40 {
  margin-top: 40px;
}

.testimonial-three {
  margin-top: 100px;
}

.sticky-contain .container {
  padding-left: 60px;
  padding-right: 160px;
}

.sticky-inner {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  overflow: visible;
  top: 50px;
}

.sticky-heading {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  overflow: visible;
  top: 0;
}

@media (min-width: 992px) and (max-width: 1649px) {
  .sticky-contain .section_header h3 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 1649px) {
  .sticky-contain .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sticky-contain .single-testimonial-3 {
    margin-top: 23px;
  }
  .why-choose-2 .img-shadow {
    margin-top: 30px;
  }
  .testimonial-three {
    margin-top: 70px;
  }
  .testimonial-three .row {
    margin: 0;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar_sticky {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sticky-contain {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sticky-heading-inner {
    padding: 60px 0;
  }
}

@media (min-width: 1200px) and (max-width: 1649px) {
  .sticky-heading-inner {
    padding-top: 360px;
    padding-bottom: 360px;
  }
}

/*--------------------------------------------------------------
  ##  Process  one
  --------------------------------------------------------------*/
.single-process-1 {
  margin-top: 35px;
  display: flex;
}

.single-process-1 .icon {
  padding-right: 25px;
}

.single-process-1 .icon span {
  font-size: 50px;
  color: #edeef0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  display: block;
  text-align: right;
}

.single-process-1 .process-1-content {
  width: 100%;
  max-width: 100%;
}

.single-process-1 .process-1-content h6 {
  font-size: 18px;
  color: #197beb;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition: all .3s;
}

.single-process-1 .process-1-content p {
  color: #808c9e;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  max-width: 272px;
}

.single-process-1:hover .process-1-content h6 {
  color: #00c1ff;
  transition: all .3s;
}

.process-1 {
  margin-top: -50px;
  z-index: 55;
  position: relative;
}

.process-1 .inner-box {
  box-shadow: 0px -1px 14.56px 1.44px rgba(167, 175, 250, 0.17);
  padding: 50px;
  background: #fff;
  border-radius: 10px;
}

@media only screen and (max-width: 575px) {
  .process-1 .section-head-3 {
    font-size: 20px;
  }
  .process-1 .inner-box {
    padding: 35px;
  }
  .single-process-1 {
    flex-wrap: wrap;
  }
  .single-process-1 .icon {
    padding-right: 0px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
  }
  .single-process-1 .icon span {
    display: inline-block;
    line-height: 50px;
    margin-left: auto;
  }
}

/*--------------------------------------------------------------
  ##  Link area
  --------------------------------------------------------------*/
.link-content {
  display: none;
  padding-bottom: 110px;
}

.single-quick-link {
  margin-top: 50px;
}

.single-quick-link span {
  font-size: 14px;
  color: #495971;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  position: relative;
  display: block;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}

.single-quick-link span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 45px;
  height: 1px;
  background: #0069ff;
}

.single-quick-link ul {
  margin-top: 37px;
}

.single-quick-link ul li {
  padding-bottom: 6px;
}

.single-quick-link ul li a {
  font-size: 14px;
  color: #808c9e;
  font-weight: 400;
}

.quick-link-header {
  margin-bottom: 40px;
  border-top: 1px solid #ebebeb;
  padding-top: 33px;
  cursor: pointer;
}

.quick-link-header p {
  position: relative;
  font-size: 16px;
  color: #1c2c43;
  padding-left: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.quick-link-header p span {
  font-weight: 500;
  color: #808c9e;
}

.pm_icon {
  box-sizing: border-box;
  transition: transform 0.3s;
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
  position: relative;
  float: left;
  max-right: 10px;
  top: 3px;
}

.pm_icon::before {
  content: "";
  display: block;
  width: 15px;
  height: 0px;
  border-bottom: solid 2px #0069ff;
  position: absolute;
  bottom: 11px;
  transform: rotate(90deg);
  transition: width 0.3s;
  left: 5px;
}

.pm_icon::after {
  content: "";
  display: block;
  width: 15px;
  height: 0px;
  border-bottom: solid 2px #0069ff;
  position: absolute;
  bottom: 11px;
  left: 5px;
}

.pm_icon.active {
  transform: rotate(0deg);
}

.pm_icon.active::before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border-bottom: solid 2px #0069ff;
  position: absolute;
  bottom: 11px;
  transform: rotate(90deg);
}

/* @-webkit-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@-moz-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@-ms-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@-o-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(180deg); }
}

.pm_icon  {
	-webkit-animation: color_change .3s  ;
	-moz-animation: color_change .3s  ;
	-ms-animation: color_change .3s  ;
	-o-animation: color_change .3s  ;
	animation: color_change .3s ;
} */
/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
.footer-widget-area {
  padding: 100px 0 0;
}

.footer-widget-area .footer-bottom p {
  color: #6c798c;
  text-align: left;
}

.footer-widget-area .footer-bottom p span {
  color: #00c1ff;
}

.footer-widget-area .footer-bottom p a {
  color: #00c1ff;
}

.footer-widget-area.style-two .footer-bottom p {
  color: #3f3f3f;
  text-align: left;
}

.footer-widget h3 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
  color: #2a384c;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.footer-widget .logo {
  padding: 5px 0 33px;
}

.footer-widget p {
  font-size: 15px;
  color: #67778e;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.social ul li {
  display: inline-block;
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border: 2px solid #e0e0e0;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social ul li:hover {
  border: 2px solid #00c1ff;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social ul a {
  color: #929caa;
  font-size: 16px;
  display: block;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social ul a:hover {
  color: #00c1ff;
}

.footer-menu ul li {
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.footer-menu ul li a {
  font-size: 15px;
  color: #67778e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  letter-spacing: .3px;
  font-weight: 600;
}

.footer-widget {
  padding-bottom: 50px;
}

.footer-widget img {
  margin-right: 20px;
}

.footer_long_text img {
  float: left;
  margin-right: 40px;
}

.footer_long_text p {
  font-size: 14px;
  line-height: 30px;
  color: #67778e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-weight: 600;
  letter-spacing: .5px;
  margin-bottom: 0;
}

.footer-subscribe-wrapper input {
  width: 100%;
  border: 1px solid #ddd;
  height: 45px;
  margin-bottom: 15px;
  padding-left: 21px;
}

.footer-subscribe-wrapper button {
  width: 100%;
  border: 1px solid #ddd;
  height: 45px;
  background: #ece7e7;
  font-size: 20px;
  cursor: pointer;
}

.footer-menu ul li a:hover {
  color: #00c1ff;
}

.footer-address {
  padding-bottom: 40px;
  padding-top: 20px;
}

.footer-address a {
  font-size: 16px;
  color: #6c798c;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding: 7px 0;
}

.footer-address a i {
  margin-right: 15px;
}

.social p {
  font-size: 16px;
  color: #00c1ff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: relative;
  margin-bottom: 40px;
}

.social p:after {
  position: absolute;
  background: #56595f;
  height: 1px;
  width: 50px;
  bottom: -7px;
  left: 0;
  content: '';
}

#subscribe-form {
  margin-top: 35px;
  overflow: hidden;
  margin-bottom: 50px;
  position: relative;
  border: 1px solid rgba(103, 119, 142, 0.23);
  border-radius: 3px;
  padding-right: 60px;
}

#subscribe-form input {
  color: #67778e;
  font-size: 14px;
  font-weight: 400;
  height: 55px;
  padding-left: 17px;
  width: 100%;
  border-radius: 3px;
  border: 0;
  font-weight: 600;
}

#subscribe-form button {
  background: #197beb;
  border: medium none;
  font-size: 15px;
  line-height: 46px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 64px;
  height: 100%;
  cursor: pointer;
  border-radius: 0 3px 3px 0;
}

#subscribe-form button i {
  color: #fff;
}

.payment-link li {
  display: inline-block;
  margin-left: 10px;
}

.footer-bottom {
  background: #e9f1f3;
  padding: 28px 0 20px;
}

.footer_long_text {
  padding-top: 50px;
  border-top: 1px solid #eaeded;
  padding-bottom: 100px;
}

/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/
.backtotop {
  position: fixed;
  bottom: 25px;
  right: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
  -webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  -moz-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
}

.backtotop i {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 20px;
  background: #fff;
  color: #197beb;
  text-align: center;
  border-radius: 50%;
}

.payment-link {
  float: right;
}

/*--------------------------------------------------------------
  ##  Footer Two
  --------------------------------------------------------------*/
.service-2 {
  background-image: url(../../media/images/bg/square.png), url(../../media/images/bg/square2.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom right, center left;
}

.footer-two {
  padding: 100px 0 0;
  background: #080957;
  background-image: url(../../media/images/bg/booter_bg_2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footer-two .footer-widget-wrap {
  padding-top: 140px;
}

.footer-two .footer-widget .logo {
  padding: 5px 0 54px;
}

.footer-two .footer-widget p {
  font-size: 14px;
  color: #abb1bb;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.footer-two .footer-bottom p {
  color: #6c798c;
  text-align: left;
}

.footer-two .footer-bottom p span {
  color: #00c1ff;
}

.footer-two .footer-bottom p a {
  color: #00c1ff;
}

.footer-two.style-two .footer-bottom p {
  color: #3f3f3f;
  text-align: left;
}

.social-two ul li {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
  position: relative;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social-two ul li:hover {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social-two ul li i {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  left: 0;
  top: 36%;
  right: 0;
  font-size: 13px;
}

.social-two ul a {
  color: #929caa;
  font-size: 16px;
  display: block;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social-two ul a .svg {
  height: 48px;
  width: 43px;
}

.social-two ul a .svg .st0 {
  fill: #0c1b31;
  stroke: #fff;
  stroke-miterlimit: 10;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social-two ul a:hover .svg .st0 {
  fill: #00c1ff;
  stroke: #00c1ff;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-two .footer-menu ul li {
  padding-bottom: 0;
  margin-bottom: 20px;
}

.footer-two .footer-menu ul li a {
  font-size: 14px;
  color: #abb1bb;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.footer-two .footer-menu ul li a:hover {
  color: #ffffff;
}

.footer-two .footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer-widget {
  padding-bottom: 50px;
  overflow: hidden;
}

.footer-widget img {
  margin-right: 20px;
}

.footer-two .widget h3 {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: relative;
  margin-top: 26px;
  margin-bottom: 65px;
}

.footer-two .widget h3:after {
  position: absolute;
  content: '';
  top: 35px;
  left: 0;
  background: #a2a2be;
  height: 1px;
  width: 100px;
}

.footer-two .widget h3:before {
  position: absolute;
  content: '';
  top: 35px;
  left: 0;
  background: #1772ff;
  height: 1px;
  width: 40px;
  z-index: 99;
}

.footer-two .widget .single-footer-post {
  border-bottom: 1px solid #353672;
  padding: 26px 0;
}

.footer-two .widget .single-footer-post:first-child {
  padding-top: 0;
}

.footer-two .widget .single-footer-post div {
  font-size: 12px;
  color: #808c9e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.footer-two .widget .single-footer-post div span {
  color: #20b51b;
}

.footer-two .widget .single-footer-post div a {
  color: #808c9e;
}

.footer-two .widget .single-footer-post > a {
  font-size: 14px;
  color: #abb1bb;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  line-height: 26px;
}

.footer-two .widget .single-footer-post > a:hover {
  color: #ffffff;
}

.footer-two .footer_long_text img {
  float: left;
}

.footer-two .footer_long_text p {
  font-size: 14px;
  color: #6c798c;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
  overflow: hidden;
  padding: 0 160px 0 40px;
}

.footer-two .footer-address {
  padding-bottom: 20px;
  padding-top: 10px;
}

.footer-two .footer-address li {
  color: #e6e8ea;
  font-size: 14px;
  font-weight: 600;
}

.footer-two .footer-address a {
  font-size: 14px;
  color: #abb1bb;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  padding: 7px 0;
  margin-left: 15px;
}

.footer-two .footer-address a i {
  margin-right: 15px;
}

.footer-two .footer-address a:hover {
  color: #ffffff;
}

.social p {
  font-size: 16px;
  color: #00c1ff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  position: relative;
  margin-bottom: 40px;
}

.social p:after {
  position: absolute;
  background: #56595f;
  height: 1px;
  width: 50px;
  bottom: -7px;
  left: 0;
  content: '';
}

#subscribe-form-two input {
  background: transparent none repeat scroll 0 0;
  border-color: transparent;
  border-bottom: 1px solid #fff;
  color: #fff;
  float: left;
  font-size: 12px;
  font-weight: 400;
  height: 45px;
  width: 100%;
}

#subscribe-form-two button {
  background: transparent;
  border: medium none;
  text-align: right;
  font-size: 15px;
  line-height: 46px;
  position: absolute;
  right: 0;
  transition: all 0.3s ease 0s;
  width: 70px;
  cursor: pointer;
}

#subscribe-form-two button i {
  color: #17b212;
}

#subscribe-form-two {
  position: relative;
  overflow: hidden;
  float: right;
  width: 50%;
}

.payment-link li {
  display: inline-block;
  margin-left: 10px;
}

.footer-two .footer-bottom {
  background: transparent;
  padding: 28px 0 20px;
  background: rgba(13, 14, 81, 0.6);
  margin-top: 75px;
}

.footer-subscribe {
  overflow: hidden;
  border-bottom: 1px solid #262568;
  padding-bottom: 100px;
}

.footer-subscribe h3 {
  font-size: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #fff;
  display: inline;
  float: left;
}

.footer-subscribe h3 span {
  font-weight: 400;
}

/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/
.backtotop {
  position: fixed;
  bottom: 25px;
  right: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
  -webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  -moz-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
}

.backtotop i {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 20px;
  background: #fff;
  color: #197beb;
  text-align: center;
  border-radius: 50%;
}

.payment-link {
  float: right;
}

.footer-contact-form input {
  width: 49%;
  float: left;
  background: #ddd;
  border-color: transparent;
  height: 40px;
  margin-bottom: 18px;
  padding-left: 10px;
  background: rgba(255, 255, 255, 0.09);
  color: #abb1bb;
  text-transform: capitalize;
  font-size: 14px;
}

.footer-contact-form input:first-child {
  margin-right: 2%;
}

.footer-contact-form textarea {
  height: 115px;
  width: 100%;
  border-color: transparent;
  padding: 10px;
  background: rgba(255, 255, 255, 0.09);
  color: #abb1bb;
  text-transform: capitalize;
  font-size: 14px;
}

.footer-contact-form button {
  font-size: 15px;
  color: #fff;
  padding: 7px 33px;
  border-radius: 26px;
  font-weight: 500;
  z-index: 50;
  background: #3263f9;
  text-transform: uppercase;
  border: 3px solid #f7fbfe;
  -webkit-box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);
  box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);
  margin-top: 16px;
  cursor: pointer;
  transition: all .3s;
}

.footer-contact-form button:hover {
  background: #00c1ff;
}

@media (min-width: 1200px) and (max-width: 1429px) {
  .social-two ul a .svg {
    height: 41px;
  }
  .social-two ul li {
    margin-right: 6px;
  }
  .social-two ul li i {
    font-size: 12px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-two .footer-address {
    padding-bottom: 21px;
    padding-top: 20px;
  }
  .footer-subscribe h3 {
    font-size: 29px;
  }
  #subscribe-form-two {
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-two .footer-widget-wrap {
    padding-top: 110px;
  }
  .footer-subscribe h3 {
    display: block;
    float: none;
    text-align: center;
  }
  #subscribe-form-two {
    float: none;
    width: 80%;
    margin: 0 auto;
  }
  .footer-two .footer-bottom p {
    text-align: center;
  }
  .footer-two .payment-link {
    float: none;
    text-align: center;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-subscribe h3 {
    font-size: 26px;
  }
  #subscribe-form-two {
    width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  Cloud feature area
  --------------------------------------------------------------*/
.multi_bg_wave {
  background: url(../../media/images/bg/linebg.png), url(../../media/images/bg/fold_wave.png);
  background-repeat: no-repeat;
  background-position: bottom left, top right;
  padding-bottom: 290px !important;
}

.single-cloud-feature {
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.8px 4.2px rgba(221, 234, 255, 0.39);
  padding: 80px 0 70px;
  margin: 15px 15px;
  background: #fff;
}

.single-cloud-feature p {
  font-size: 14px;
  color: #00c1ff;
  font-weight: 400;
  margin-bottom: 5px;
}

.single-cloud-feature h6 {
  color: #00c1ff;
  font-weight: 700;
}

.single-cloud-feature .image {
  width: 20%;
  margin: 0 auto;
  margin-bottom: 32px;
  text-align: center;
  min-height: 50px;
}

.single-cloud-feature .image img {
  display: inline;
}

/*--------------------------------------------------------------
  ##  Cloud Exclusive feature area
  --------------------------------------------------------------*/
.cloud-excusive-feature.section-padding {
  padding-bottom: 65px;
}

.cloud-excusive-feature {
  background-image: url(../../media/images/background/dark1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.cloud-excusive-feature .section_header {
  padding: 0 15px;
}

.single-ex-feature {
  margin-bottom: 50px;
}

.single-ex-feature .top-box {
  overflow: hidden;
}

.single-ex-feature .top-box .ex-image {
  width: 67px;
  float: left;
}

.single-ex-feature .top-box h4 {
  font-size: 18px;
  color: #fff;
  position: relative;
  overflow: hidden;
  padding-bottom: 19px;
  margin-top: 2px;
}

.single-ex-feature .top-box h4:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 6px;
  background: #fff;
  width: 20px;
  height: 1px;
}

.single-ex-feature p {
  color: #aabbd5;
  margin-left: 6px;
  margin-top: 9px;
  max-width: 375px;
}

@media only screen and (max-width: 1429px) {
  .cloud-feature .section_header.style-two {
    margin-bottom: 12px;
  }
  .cloud-feature .section_header.style-two h3 {
    font-size: 30px;
  }
  .cloud-feature .btn-3 {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .cloud-feature .inner-content-box {
    text-align: center;
    margin-bottom: 35px;
  }
  .cloud-feature .section_header.style-two {
    text-align: center;
  }
  .cloud-feature .block-content p {
    max-width: 566px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 575px) {
  .cloud-feature .section_header.style-two h3 {
    font-size: 24px;
  }
}

/*--------------------------------------------------------------
  ##  Customer review
  --------------------------------------------------------------*/
.customer-review-carousel {
  margin-top: -8px;
}

.single-customer-review {
  height: 335px;
  margin: 9px 20px;
}

.single-customer-review .cr-image-box {
  width: 231px;
  height: 231px;
  border-radius: 3px;
  box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.07);
  background-color: #fcfcfc;
  border: 10px solid #fff;
  text-align: center;
  padding: 25px 0;
}

.single-customer-review .cr-image-box p {
  color: #808c9e;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.single-customer-review .cr-image-box span {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  color: #2a384c;
}

.single-customer-review .cr-image-box .image {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px solid #fff;
  overflow: hidden;
  box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.07);
  margin: 0 auto;
  margin-bottom: 15px;
}

.single-customer-review .review-box {
  position: absolute;
  top: 74px;
  left: 115px;
  padding: 30px;
  padding-left: 169px;
  box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.07);
  z-index: -1;
  padding-bottom: 50px;
  margin-right: 6px;
}

.single-customer-review .review-box p {
  font-size: 14px;
  color: #67778e;
  font-weight: 600;
  font-style: italic;
  font-family: "OpenSans", sans-serif;
  line-height: 2;
}

.single-customer-review .review-box i {
  color: #3263f9;
  -webkit-box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.08);
  box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.08);
  font-size: 25px;
  margin-bottom: 20px;
  margin-left: 6px;
}

.customer-review-carousel.owl-theme .owl-dots .owl-dot span {
  width: 22px;
  height: 4px;
  margin: 5px 7px;
  background: #9db4fc;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 0;
}

.customer-review-carousel.owl-theme .owl-dots .owl-dot.active span, .customer-review-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background: #3263f9;
}

.customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: -127px;
  z-index: 999999;
  position: relative;
  left: 8px;
}

.cloud-feature .btn-3 {
  margin-top: 22px;
}

@media (min-width: 1200px) and (max-width: 1429px) {
  .customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -74px;
  }
}

@media only screen and (max-width: 991px) {
  .customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-customer-review .cr-image-box {
    width: 213px;
    height: 231px;
  }
  .single-customer-review .review-box {
    left: 83px;
  }
  .customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 575px) {
  .single-customer-review {
    height: inherit;
  }
  .single-customer-review .review-box {
    position: inherit;
    padding: 15px;
  }
  .customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
    display: none;
  }
  .single-customer-review .cr-image-box {
    margin: 0 auto 20px;
  }
}

/*--------------------------------------------------------------
  ## Hosting plan section
  --------------------------------------------------------------*/
.pricing-three-configation-title {
  padding-top: 289px;
}

.pricing-three-configation-title ul li {
  font-size: 16px;
  color: #45566f;
  text-align: left;
  padding: 13px 30px;
  border-bottom: 1px solid #d6e7f4;
}

.single-pricing-three {
  text-align: center;
  box-shadow: 0 0 13px 0 rgba(101, 114, 129, 0.07);
  border-radius: 8px;
  padding: 60px 0;
  background: #fff;
}

.single-pricing-three ul li {
  font-size: 16px;
  color: #67778e;
  text-align: left;
  padding: 13px 30px;
  border-bottom: 1px solid #eef6fc;
  text-align: center;
}

.single-pricing-three .pricing-image {
  text-align: center;
  width: 115px;
  height: 115px;
  border-radius: 100%;
  margin: 0 auto;
  line-height: 105px;
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.single-pricing-three .pricing-image:before {
  content: '';
  position: absolute;
  left: -20px;
  top: -20px;
  width: 155px;
  height: 155px;
  background: #fafcfe;
  z-index: -2;
  border-radius: 100%;
}

.single-pricing-three .pricing-image:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 115px;
  height: 115px;
  background: #f5f9fd;
  z-index: -1;
  border-radius: 100%;
}

.single-pricing-three h4 {
  font-size: 16px;
  color: #0b67f4;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 35px;
}

.single-pricing-three h3 {
  font-size: 36px;
  color: #0b67f4;
  text-align: center;
  font-weight: 800;
  margin-top: 36px;
}

.single-pricing-three .btn-3 {
  margin-top: 24px;
}

.single-pricing-three.high-pack {
  box-shadow: 0 0 32px 0 rgba(101, 114, 129, 0.18);
  z-index: 1111;
  position: relative;
}

.single-pricing-three.high-pack h4 {
  color: #00c1ff;
}

.single-pricing-three.high-pack h3 {
  color: #00c1ff;
}

.single-pricing-three.high-pack .btn-3 {
  background: #00c1ff;
}

.half-bg {
  position: relative;
}

.half-bg:before {
  content: '';
  position: absolute;
  left: 15px;
  top: 440px;
  background: #f6fbfe;
  width: 106%;
  height: 475px;
  z-index: -1;
  box-shadow: 0px 1px 7.7px 1.4px rgba(50, 99, 249, 0.08);
  border-radius: 0 22px 22px 0;
}

@media only screen and (max-width: 1429px) {
  .half-bg:before {
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing_3 .pricing-three-configation-title {
    display: none;
  }
  .pricing_3 .half-bg:before {
    content: none;
  }
}

@media only screen and (max-width: 991px) {
  .single-pricing-three {
    margin-top: 40px;
  }
}

/*--------------------------------------------------------------
  ##  Contact page
  --------------------------------------------------------------*/
.contact_address {
  background: #f9fbfe;
}

.single-address {
  width: 33.33%;
  float: left;
  text-align: center;
  padding: 0 15px;
}

.single-address .image {
  height: 110px;
  width: 110px;
  border-radius: 100%;
  line-height: 108px;
  box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
  margin: 0 auto;
  margin-bottom: 30px;
}

.single-address h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #2a384c;
  display: block;
  position: relative;
  margin-bottom: 40px;
  transition: all .3s;
}

.single-address h5:after {
  content: '';
  position: absolute;
  width: 40px;
  height: 1px;
  background: #a7bdff;
  left: 50%;
  margin-left: -20px;
  bottom: -20px;
}

.single-address p {
  color: #67778e;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin: 0;
  line-height: 28px;
}

.single-address:hover h5 {
  color: #00c1ff;
  transition: all .3s;
}

.contact_area .form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-bottom: 1px solid #dcefff;
  margin-bottom: 60px;
}

.form-control:focus {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #00c1ff;
}

.contact_area textarea.form-control {
  border: 1px solid #dcefff;
  margin-top: 35px;
  height: 120px;
  margin-bottom: 40px;
}

.contact_area textarea.form-control:focus {
  border: 1px solid #00c1ff;
}

.contact-btn-wrapper {
  text-align: center;
}

.btn-3 {
  cursor: pointer;
}

.contact_area label {
  color: #67778e;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .single-address {
    width: 100%;
    margin-bottom: 50px;
  }
  .single-address:last-child {
    margin-bottom: 0;
  }
}

.how-it-work {
  padding: 120px 0;
}

.working-items {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.working-items:before {
  position: absolute;
  content: '';
  top: 58px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  width: 92%;
  border: 1px dashed #d7d7d7;
}

.working-items li {
  text-align: center;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 25%;
}

.working-items li .icon {
  width: 120px;
  height: 120px;
  line-height: 115px;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 11px 15.3px 2.7px rgba(13, 43, 134, 0.15);
  border-radius: 50%;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

.working-items li h4 {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #2a384c;
  margin-top: 50px;
  margin-bottom: 0;
}

.working-items li:hover .icon {
  border-color: #00c1ff;
}

@media only screen and (max-width: 991px) {
  .working-items {
    margin-top: -70px;
  }
  .working-items:before {
    content: none;
  }
  .working-items li {
    max-width: 50%;
    margin-top: 50px;
  }
  .working-items li .icon {
    width: 100px;
    height: 100px;
    line-height: 92px;
  }
  .working-items li .icon img {
    width: 35px;
  }
  .working-items li h4 {
    margin-top: 27px;
  }
}

@media only screen and (max-width: 575px) {
  .working-items li {
    max-width: 100%;
  }
}

.faq_1_tab_wrap {
  display: flex;
  flex-wrap: wrap;
}

.faq_1_tab_wrap .nav {
  flex-direction: column;
  width: 100%;
  max-width: 25%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.82px 4.18px rgba(221, 234, 255, 0.39);
  padding: 31px 35px;
}

.faq_1_tab_wrap .nav li {
  border-bottom: 1px solid #f4f2f2;
}

.faq_1_tab_wrap .nav li:last-child {
  border-bottom: 0;
}

.faq_1_tab_wrap .nav a {
  font-size: 15px;
  font-weight: 600;
  color: #67778e;
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.faq_1_tab_wrap .nav a img {
  width: 26px;
  margin-right: 15px;
}

.faq_1_tab_wrap .tab-content {
  width: 100%;
  max-width: 75%;
  padding-left: 30px;
}

.faq_1_tab_wrap .tab-content .content_wrap {
  display: flex;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-img {
  width: 100%;
  max-width: 40%;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-img img {
  width: 100%;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-detail {
  padding-left: 30px;
  width: 100%;
  max-width: 60%;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-detail h4 {
  margin-top: -5px;
  font-size: 26px;
  color: #2a384c;
  font-weight: 800;
  margin-bottom: 34px;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-detail p {
  line-height: 30px;
  font-size: 15px;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-detail ul {
  margin: 32px 0;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-detail ul li {
  position: relative;
  padding-left: 20px;
  font-size: 15px;
  color: #67778e;
  font-weight: 600;
  margin: 15px 0;
}

.faq_1_tab_wrap .tab-content .content_wrap .item-detail ul li:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #565a5f;
}

@media only screen and (max-width: 1449px) {
  .faq_1_tab_wrap .nav {
    max-width: 100%;
    flex-direction: row;
    margin-bottom: 50px;
  }
  .faq_1_tab_wrap .nav li {
    border: 0;
    padding: 0 15px;
    position: relative;
  }
  .faq_1_tab_wrap .nav li::after {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    height: 25px;
    width: 1px;
    background: #f4f2f2;
  }
  .faq_1_tab_wrap .nav li:last-child::after {
    content: none;
  }
  .faq_1_tab_wrap .tab-content {
    max-width: 100%;
    padding-left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faq_1_tab_wrap .tab-content .content_wrap .item-img {
    max-width: 100%;
  }
  .faq_1_tab_wrap .tab-content .content_wrap .item-detail h4 {
    margin-bottom: 20px;
  }
  .faq_1_tab_wrap .tab-content .content_wrap .item-detail ul {
    margin: 28px 0;
  }
}

@media only screen and (max-width: 757px) {
  .faq_1_tab_wrap .nav {
    padding: 30px 20px;
  }
  .faq_1_tab_wrap .tab-content .content_wrap {
    flex-direction: column;
  }
  .faq_1_tab_wrap .tab-content .content_wrap .item-img {
    max-width: 100%;
  }
  .faq_1_tab_wrap .tab-content .content_wrap .item-detail {
    padding-left: 0;
    margin-top: 30px;
    max-width: 100%;
  }
  .faq_1_tab_wrap .tab-content .content_wrap .item-detail h4 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .faq_1_tab_wrap .nav {
    padding: 30px 15px;
  }
  .faq_1_tab_wrap .tab-content .content_wrap .item-detail h4 {
    font-size: 23px;
  }
}
