.blue-box{
	h6{
		font-size: 18px;
		color: #fff;
		@include font-one-7;
	}
	p{
		font-size: 15px;
		color: #bccdff;
		@include font-three-6;
		margin-bottom: 0;

	}
	a{
		font-size: 12px;
		color: #fff;
		padding: 7px 18px;
		border-radius: 26px;
		font-weight: 500;
		z-index: 50;
		background: #3263f9;
		border: 2px solid #f7fbfe;
		margin-top: 27px;
	}
	img{
		float: right;
	}

}

.blue-box {
	background: #1445d9;
	padding: 40px 35px 30px;
	border-radius: 5px;
	overflow: hidden;
}

.box-half {
	width: 50%;
	float: left;
	padding: 0 10px;
	margin-top: 5px;
}

.feature-3 .btn-3 {
	margin-top: 22px;
}

.line-bg{
	padding-top:290px!important;
	background-image:url(../../media/images/bg/linebg.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position:top left;
}

@include mq-max(1429px) {
	.about_2 .inner-content-box {
		padding-right: 0px;
	}

	.feature-3{
		.section_header.style-two {
			margin-bottom: 12px;		
			h3 {
				font-size: 30px;
			}
		}
		.btn-3 {
			margin-top: 15px;
		}
	}
}
@include mq-max(1199px) {

	.feature-3{
		.inner-content-box{
			text-align: center;
			margin-bottom: 35px;
		}
		.section_header.style-two {
			text-align: center;
		}
		.block-content p {
			max-width: 566px;
			margin: 0 auto;
		}
	}
}
@include mq-max(991px) {
	.feature-3{
		.blue-box {
			padding: 35px 25px 30px;
		}
	}
}
@include mq-max(991px) {
	.feature-3{
		.box-half {
			width: 100%;
			float: none;
		}
	}
}

@include mq-max(575px) {
	.feature-3 .section_header.style-two h3 {
		font-size: 24px;
	}
	.sin-about-2 .icon {
	 	width: inherit;
		 float: none;
		 margin-bottom: 10px;
	}
}