.single-feature-two {
	width: 100%;
	max-width: 50%;
	background: #fff;
	position: relative;
	.feature-img {
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		height: 100%;
		img{
			height: 100%;
			width: 100%;
		}
	}
	.hide-content {
		width: 100%;
		max-width: 50%;
		transition: all 0.15s ease-in-out;
		padding: 60px 45px;
		background: #faf9f9;
		> span{
			color: $main_two;
			font-size: 14px;
			@include font-one-5;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
		.feature-list{
			margin-top: 15px;
			ul{
				border-bottom: 1px dashed #d7d7d7;
				li{
					font-size: 14px;
					@include font-three-4;
					color: $para_color;
					padding: 15px 0;
					border-top: 1px dashed #d7d7d7;
					span{
						float: right;
					}
				}
			}
		}
	
	}
	.feature-two-contnt {
		overflow: hidden;
		display: flex;
	}
	.btn-2 {
		font-size: 15px;
		color: #fff;
		padding: 7px 22px;
		border-radius: 26px;
		font-weight: 500;
		z-index: 50;
		background: $main_two;
		text-transform: uppercase;
		border: 3px solid #ebebeb;
		margin-top: 30px;
	}
	
	.btn-2:hover {
		background: #197beb;
		color: #fff;
	}
	.show-content {
		width: 100%;
		max-width: 50%;
		background: #fff;
		z-index: 5;
		padding: 60px 45px;
		border-left: 2px solid #fff;
		transition: all 0.7s ease-in-out;
		&:after{
			content: '';
			position: absolute;
			left: -12px;
			top: 50%;
			width: 24px;
			height: 24px;
			background: #fff;
			transform: rotate(45deg);
			margin-top: -12px;
			-webkit-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
		}
		h5{
			color: #354358;
			text-transform: uppercase;
			@include font-one-7;
			position: relative;
			margin-bottom: 50px;
			&:after{
				content:'';
				bottom:-15px;
				left:0;
				width:80px;
				height:1px;
				background: #83b4ff;
				position:absolute;
			}
		}
		p{
			@include font-three-3;
			
		}
		.icon {
			margin-bottom: 30px;
			height: 82px;
		}
	}
	/* ---------------------- */
	.btn-2 {
		font-size: 15px;
		color: #fff;
		padding: 7px 22px;
		border-radius: 26px;
		font-weight: 500;
		z-index: 50;
		background: $main_two;
		text-transform: uppercase;
		border: 3px solid #ebebeb;
		margin-top: 30px;
	}

	.btn-2:hover {
		background: #197beb;
		color: #fff;
	}

	&:nth-child(-n+2) {
		.show-content {
			transform: translateX(100%);
		}
	}
	&:nth-child(n+3):nth-child(-n+4) {
		.feature-img {
			left: inherit;
			right: 0;
		}
		.show-content {
			order: 2;
			transform: translateX(-100%);
			&:after{
				right: -12px;
				left: inherit;
			}
		}

	}
	&:hover{
		&:nth-child(-n+2) {
			.show-content {
				transform:translateX(0);
			}
		}
		&:nth-child(n+3):nth-child(-n+4) {
			.show-content {
				transform: translateX(0);
			}
		}
		.show-content {
			&:after{
				background-color: transparent;
			}
		}
	}
}


@include mq(1200px, 1429px) {
	.single-feature-two {
		.show-content {
			padding: 30px 20px;
			h5 {
				font-size: 16px;
				margin-bottom: 37px;
			}
			p{
				font-size: 15px;
				line-height: 25px;
				margin-bottom: 0px;
			}
		}
		.hide-content {
			padding: 30px 20px;
			.feature-list ul li {
				padding: 12px 0;
			}
		}
		.btn-2 {
			margin-top: 23px;
		}
	}
}

@include mq(992px, 1199px) {
	.single-feature-two {
		max-width: 100%;		
	}
}

@include mq-max(991px) {
	.single-feature-two {
		max-width: 100%;		
	}
}

@include mq-max(767px) {
	.single-feature-two {
		max-width: 100%;
		.show-content {
			padding: 30px 20px;
			h5 {
				font-size: 16px;
				margin-bottom: 37px;
			}
			p{
				font-size: 15px;
				line-height: 25px;
				margin-bottom: 0px;
			}
		}
		.hide-content {
			padding: 30px 20px;
			.feature-list ul li {
				padding: 12px 0;
			}
		}
		.btn-2 {
			margin-top: 23px;
		}		
	}
}

@include mq-max(575px) {
	.single-feature-two{
		.feature-img {
			width: 100%;
			height: 50%;
			display: none;
		}
		.feature-two-contnt{
			flex-direction: column;
		}
		.show-content {
			min-width: 100%;
		}
		.hide-content{
			max-width: 100%;
		}
		&:nth-child(-n+2) {
			.show-content {
				transform: translateX(0);
			}
		}
		&:nth-child(n+3):nth-child(-n+4) {
			.show-content {
				transform: translateX(0%);
			}
		}
	}
}