$font_primary: 'OpenSans', sans-serif;
$font_heading: 'Montserrat', sans-serif;

$main_color: #197beb;
$main_two: #00c1ff;
$main_three: #0b67f4;
$main_four: #85e0fd;

$header_color: #1c2c43;

$header_up: #2F5774;

$sub_head:  #354358;

$sub_head_two:  #596069;

$para_color: #4f5d72;
$para_color_2: #808c9e;
$bg_one: #f7fcff;
$bg_two: #f7fcff;
$bg_three: #e5edfa;

$heading_2:#1c2c43;

$blog_title: #435062;







