.list-view .single-blog{
    box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.06);
    padding:20px;
    overflow: hidden;
    margin-bottom: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > a{
        max-width: 27%;
        width: 100%;
        img{
            width: 100%;
        }
    }
    .sin-blog-content{
        width: 100%;
        max-width: 73%;
        padding: 0px 10px 0 30px;
        .cat-links{
            margin-bottom:10px;
            a{
                color: #0b67f4;
                font-size: 14px;
                @include font-one-6;
                &:hover{
                    color: $main_two;
                }
            }
        }
        .entry-title{
            margin-bottom:12px;
            font-size: 23px;
            color: $blog_title;
            @include font-one-7;
            line-height: 32px;
            a{
                color: $blog_title;
                &:hover{
                    color: $main_two;
                }
            }

        }
        .meta{
            margin-bottom:20px;
            a{
                color: #5b6470;
                font-size: 14px;
                @include font-three-6;

                i{
                    margin-right: 5px;

                }
                &:hover{
                    color: $main_two;
                }
            }
        }
        > a{
            font-size: 15px;
            color: #fff;
            padding: 7px 22px;
            border-radius: 26px;
            font-weight: 500;
            z-index: 50;
            background: #3263f9;
            margin-top: 10px;
            &:hover{
                color: $main_two;
            }
        }
    }

}

.blog-wrapper {
    background: #f5f6fa;
    padding: 120px 0;
}


.blog-pagination{
    background: #fff;
    padding: 20px 30px;
    border-radius: 51px;
    text-align: center;
    box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.06);
    ul{
        li{
            display: inline-block;
            padding: 0 5px;
            a{
                font-size: 14px;
                color: $header_up;
                @include font-three-7;
                &:hover{
                    color: $main_two;
                }
            }
            &:first-child{
                float: left;
                a{
                    color: $main_three;
                    &:hover{
                        color: $main_two;
                    }
                }
            }
            &:last-child{
                float: right;
                a{
                    color: $main_three;
                    &:hover{
                        color: $main_two;
                    }
                }
            }
        }
    }
}

@include mq(1200px, 1649px) {
    .list-view {
        .single-blog {
            > a {
                max-width: 35%;
            }
            .sin-blog-content{
                max-width: 65%;
                .entry-title {
                    font-size: 21px;
                }
            }
        }
    }
}

@include mq(992px, 1199px) {
    .list-view {
        .single-blog {
            > a {
                max-width: 44%;
            }
            .sin-blog-content{
                max-width: 56%;
                padding: 0 0 0 20px;
                .entry-title {
                    font-size: 17px;
                    line-height: 27px;
                }
            }
        }
    }
}

@include mq-max(991px) {
    .list-view {
        .single-blog {
            > a {
                max-width: 36%;
            }
            .sin-blog-content{
                max-width: 64%;
                padding: 0 0 0 20px;
                .entry-title {
                    font-size: 19px;
                    line-height: 27px;
                }
            }
        }
    }
}
@include mq-max(767px) {
    .list-view {
        .single-blog {
            > a {
                max-width: 100%;
            }
            .sin-blog-content{
                max-width: 100%;
                padding: 20px 0 0 0;
                .entry-title {
                    font-size: 19px;
                    line-height: 27px;
                }
            }
        }
    }
}