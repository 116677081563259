.header-two {
    position: relative;
    z-index: 999;
    margin: 0 auto;
    border-radius: 11px;
    top: 30px;

    &.navbar-transparent {
        .container {
            background: transparent;
            box-shadow: none;
        }
    }

    &.navbar-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999999;
        transition-property: transform;
        transition-duration: 0.3s;
        transition: all .3s ease-in-out;
    }

    .container {
        background: #fff;
        box-shadow: 0px 0px 32px 0px rgba(101, 114, 129, 0.11);
    }

    .right-element {
        display: flex;
        align-items: center;
    }

    .menu-2 {
        >ul {
            >li {
                display: inline-block;

                >a {
                    @include font-three-6;
                    color: #838fa0;
                    font-size: 16px;
                    text-transform: capitalize;
                    padding: 35px 30px;

                    &:hover {
                        color: $main_two;
                    }

                }

                &.dropdown {
                    position: relative;

                    &:hover {
                        .submenu {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .submenu {
            position: absolute;
            top: 100%;
            left: 0;
            width: 280px;
            background: #fff;
            padding: 20px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            border-radius: 0 0 3px 3px;
            box-shadow: 0px 0px 32px 0px rgba(101, 114, 129, 0.11);

            li {
                a {
                    font-size: 14px;
                    padding: 5px 0;
                    font-weight: 600;
                    text-transform: capitalize;
                    color: #838fa0;

                    &:hover {
                        @extend .main-color;
                    }
                }
            }
        }
    }

    .top-search {
        position: relative;

        a {
            color: $main_two;
        }

        input {
            display: block;
            border: 1px solid $main_two;
            border-radius: 2px;
            padding: 5px;
            width: 300px;
            margin-left: 10px;
            margin-top: 12px;
        }

        .search-input-wrapper {
            position: absolute;
            right: 5px;
            top: 60px;
            background: $main_two;

            height: 0;
            width: 320px;
            overflow: hidden;
            @include transition(all .3s);
            opacity: .1;

        }

        .search-input-wrapper.active {

            height: 60px;
            @include transition(all .3s);
            opacity: 1;
        }
    }

    /* Mobile Menu */
    .mobile-menu-toggler {
        display: none;
        border: 1px solid rgba(0, 0, 0, .1);
        background: transparent;
        font-size: 25px;
        padding: 0px 15px;
        color: rgb(28, 46, 66);
    }
}

.header-two-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.megamenu {
    position: absolute;
    background: #fff;
    left: 0;
    width: 100%;
    z-index: -7;
    top: 89px;
    padding: 50px;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;

    .column {
        width: 33%;
        float: left;
    }
}

.position-initial {
    position: initial;
}

.single-mega-menu {
    margin-top: 35px;

    .icon {
        width: 77px;
        float: left;
    }

    .mega-menu-content {
        overflow: hidden;
        padding-right: 25px;

        h6 {
            font-size: 18px;
            color: #505b67;
            @include font-one-6;
        }

        p {
            color: #808c9e;
            font-size: 14px;
            @include font-one-5;

        }
    }

    &:hover {
        .mega-menu-content {
            h6 {
                @extend .main-two;
            }
        }

    }
}

.single-megamenu-feature {
    text-align: center;
    box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.8px 4.2px rgba(221, 234, 255, 0.39);
    padding: 50px 0 50px;
    margin: 15px 15px;

    p {
        font-size: 12px;
        color: #7f8793;
        font-weight: 400;
        margin-bottom: 5px;
    }

    h6 {
        color: $main_three;
        font-weight: 800;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 50px;

        &:after {
            position: absolute;
            content: '';
            width: 150px;
            height: 1px;
            background: url(../../media/images/icon/line.png);
            left: 50%;
            margin-left: -75px;
            bottom: -20px;
        }

    }

    h3 {
        color: $main_three;
        font-size: 26px;
        @include font-three-7;

        span {
            color: #8198aa;
            font-size: 16px;
            @include font-three-6;
            text-transform: lowercase;

        }
    }

    .image {
        width: 20%;
        margin: 0 auto;
        margin-bottom: 25px;
        text-align: center;
        min-height: 50px;

        img {
            display: inline;
        }

    }
}

.header-two .menu-2 ul li:hover .megamenu {
    opacity: 1;
    visibility: visible;
    transition: all .3s;
}

@include mq(1200px, 1429px) {
    .header-two {
        .menu-2>ul>li>a {
            padding: 35px 20px;
        }

        .top-search {
            float: right;
            padding-left: 8px;
        }
    }
}

@include mq-max(991px) {
    .header-two {
        .header-two-wrap {
            padding: 15px 0;
        }

        .mobile-menu-toggler {
            display: block;
        }

        .menu-2 {
            display: none;
        }

        .top-search {
            margin-left: 14px;
            display: none;
        }
    }
}


@import 'sidebar-mobile-menu-2';