.single-service-two {
	width: 71%;
    float: left;
    background: #fff;
    padding: 25px 40px;
    -webkit-box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
    box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
    margin-bottom: 40px;

	h6{
		font-size: 18px;
		color: #2a384c;
		position: relative;
		padding-bottom: 7px;
		display: inline;
		font-weight: 700;
		margin-left: 20px;
		&:after{
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 20px;
			height: 1px;
			background: $main_three;
			margin-bottom: 0px;

		}
		&:hover{
			color: #0b67f4;
		}
	}
	p{
		font-size: 14px;
		color: #808c9e;
		display: block;
		overflow: hidden;
		width: 100%;
		text-align: left;
		margin-bottom: 0;
	}
	&:nth-child(2) {
		margin-left: 80px;
	}
}
.single-service-two img {
	float: left;
	width: 55px;
}

.service-2{

	.inner-content-box{
		padding-left: 25px;
		margin-top: 80px;
		padding-right: 50px;
	}
	.btn-3{
		margin-top: 25px;
	}
}

.btn-3:hover {
	color: #000;
}

.service-2{
	background-image:url(../../media/images/bg/square.png),url(../../media/images/bg/square2.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: bottom right,center left;
}

.service-3{

	.inner-content-box{
		padding-left: 25px;
		margin-top: 80px;
		padding-right: 145px;
	}
	.btn-3{
		margin-top: 20px;
	}
}

@include mq-max(1199px) {
	.service-2 .inner-content-box{
		margin-bottom: 60px;
		padding-left: 0px;
		margin-top: 0px;
		padding-right: 0px;
	}
}
@include mq-max(575px) {
	.single-service-two {
		width: 83%;
	}
}
@include mq-max(575px) {
	.single-service-two {
		width: 100%;
		padding: 20px;
		&:nth-child(2) {
			margin-left: 0px;
		}
	}
}