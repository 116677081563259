.sticky-area-wrapper{
	display: flex;
	flex-wrap: wrap;

}

.sidebar_sticky {
	position: relative;
	width: 100%;
	min-height: 1px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 39%;
	flex: 0 0 39%;
	max-width: 39%;
}

.sticky-contain {
	position: relative;
	width: 100%;
	min-height: 1px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 61%;
	flex: 0 0 61%;
	max-width: 61%;
	border-left: 1px solid #eff5ff;


}

.provide-1{
	display: block;
	overflow: hidden;
	background: url(../../media/images/bg/angle1.png);
	background-repeat: repeat;
	background-position: center;
	background-size: auto;
	content: '';
	background-repeat: no-repeat;
	background-position: right 80px;


}

.why-choose-2{
	background-image: url(../../media/images/bg/angle2.png);
	background-repeat: repeat;
	background-position: center;
	background-size: auto;
	content: '';
	background-repeat: no-repeat;
	background-position: right 80px;
	padding-left: 15px;
	padding-right: 15px;
	.block-content{
		p {
			margin-bottom: 0;
		}
	}
}

.sticky-heading{
	text-align: center;
	h2{
		font-size: 30px;
		font-weight: 800;
		color: $heading_2;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-top: 20px;
		span{
			color: $main_color;
		}
	}
}



.single-provide {
	width: 50%;
	float: left;
	text-align: left;
	margin-top: 40px;
	padding: 0 30px;
	.section_header.style-two {
		margin-bottom: 9px;
	}
	h6{
		font-size: 18px;
		color: #2a384c;
		position: relative;
		margin-bottom: 20px;
		margin-top: 27px;
		font-weight: 600;
		transition: all .3s;
		&:hover{
			color: #0b67f4;
			transition: all .3s;
		}
	}
	p{
		font-size:14px;
		color: $para_color_2;
		margin-bottom: 0;
	}

}

.img-shadow img{
	box-shadow: 0.618px 1.902px 6px 0px rgba(15, 59, 128, 0.23);
}

.why-choose-2{
	.block-content{
		padding-right: 40px;
		padding-top: 18px;

	}
}


.sticky-heading-inner{
	padding-top: 410px;
	padding-bottom: 410px;
	position: relative;

	&:after{
		position: absolute;
		left: -139px;
		top: 11px;
		width: 100%;
		height: 100%;
		background-image: url(../../media/images/bg/net.png);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		content: '';

	}
}




/*--------------------------------------------------------------
  ##  Testimonial 3
  --------------------------------------------------------------*/

  .single-testimonial-3{
  	background: #f7fcff;
  	padding: 30px 20px;
  	border: 5px solid #fff;
  	box-shadow: 0px 1px 2.85px 0.15px rgba(105, 105, 105, 0.22);
  	position:relative;
  	&:after{
  		position: absolute;
  		right: 21px;
  		top: 29px;
  		width: 45px;
  		height: 25px;
  		background: url(../../media/images/icons/quatation.png);
  		background-position-x: 0%;
  		background-position-y: 0%;
  		background-repeat: repeat;
  		background-size: auto auto;
  		background-position: center;
  		background-size: auto;
  		content: '';
  		background-repeat: no-repeat;
  	}
  	h4{
  		font-size: 18px;
  		color: #2a384c;
  		font-weight: 600;

  	}
  	p{
  		font-size: 14px;
  		color: $para_color_2;
  		font-weight: 500;

  	}
  	h6{
  		color: #1c2c43;
  		font-size: 16px;

  	}
  	span{
  		font-size: 14px;
  		color: $para_color_2;


  	}
  }

  .single-testimonial-3.mt-40{
  	margin-top: 40px;
  }

  .testimonial-three {
  	margin-top: 100px;
  }

  .sticky-contain .container {
  	padding-left: 60px;
  	padding-right: 160px;
  }


.sticky-inner {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	overflow: visible;
	top: 50px;
}
.sticky-heading {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	overflow: visible;
	top: 0;
}
@include mq(992px, 1649px) {
	.sticky-contain {
		.section_header h3{
			font-size: 34px;
		}
	}
}

@include mq-max(1649px) {
	.sticky-contain {
		.container {
			padding-left: 15px;
			padding-right: 15px;
		}
		.single-testimonial-3 {
			margin-top: 23px;
		}
	}

	.why-choose-2{
		.img-shadow{
			margin-top: 30px;
		}
	}
	.testimonial-three {
		margin-top: 70px;
		.row{
			margin: 0;
		}
	}
}
@include mq-max(991px) {
	.sidebar_sticky {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.sticky-contain{
		flex: 0 0 100%;
		max-width: 100%;
	}
	.sticky-heading-inner {
		padding: 60px 0;
	}
}
@include mq(1200px, 1649px) {
	.sticky-heading-inner {
		padding-top: 360px;
		padding-bottom: 360px;
	}
}