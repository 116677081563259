
/*--------------------------------------------------------------
  ##  Contact page
  --------------------------------------------------------------*/


  .contact_address{
	background: #f9fbfe;
}


  .single-address{
  	width: 33.33%;
  	float: left;
  	text-align: center;
  	padding: 0 15px;
  	.image {
  		height: 110px;
  		width: 110px;
  		border-radius: 100%;
  		line-height: 108px;
  		box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
  		margin: 0 auto;
  		margin-bottom: 30px;
  	}
  	h5{
  		@include font-one-7;
  		color: #2a384c;
  		display: block;
  		position: relative;
  		margin-bottom: 40px;
  		transition: all .3s;
  		&:after{
  			content: '';
  			position: absolute;
  			width: 40px;
  			height: 1px;
  			background: #a7bdff;
  			left: 50%;
  			margin-left: -20px;
  			bottom: -20px;
  		}
  	}
  	p{
  		color: #67778e;
  		font-size: 14px;
  		@include font-three-6;
  		margin: 0;
  		line-height: 28px;


  	}
  	&:hover{
  		h5{
  			color: $main_two;
  			transition: all .3s;
  		}
  	}
  }

  .contact_area .form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: none;
	border-radius: 0;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	border-bottom: 1px solid #dcefff;
	margin-bottom: 60px;
}

.form-control:focus {
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #00c1ff;
}

.contact_area textarea.form-control{
	border: 1px solid #dcefff;
	margin-top: 35px;
	height: 120px;
	margin-bottom: 40px;
}
.contact_area textarea.form-control:focus{
	border: 1px solid #00c1ff;
}
.contact-btn-wrapper{
	text-align: center;
}

.btn-3{
	cursor: pointer;
}

.contact_area label {
	color: #67778e;
	@include font-three-6;
}
@include mq-max(767px) {
    .single-address{
        width: 100%;
        margin-bottom: 50px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}