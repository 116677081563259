.banner_5{
    background-repeat: no-repeat!important;
}
.banner_5_content{
    text-align: center;
    padding: 290px 0;
    h1{
        font-size: 44px;
        line-height: 65px;
        letter-spacing: .55px;
        @extend .font-heading;
        font-weight: 800;
        text-transform: uppercase;
        max-width: 675px;
        margin-left: auto;
        margin-right: auto;
        color: #ffffff;
    }
    form{
        margin-top: 40px;
        max-width: 595px;
        margin-left: auto;
        margin-right: auto;
        border: 7px solid #ffffff;
        background: #ffffff;
        display: flex;
        border-radius: 48px;
        overflow: hidden;
        input{
            width: 100%;
            border: 0;
            font-size: 14px;
            font-weight: 600;
            @extend .font-primary;
            padding: 0 15px 0 26px;
            color: #808c9e;
        }
        button{
            height: 47px;
            padding: 0 39px;
            @extend .main-two-bg;
            border: 0;
            @extend .font-heading;
            font-size: 17px;
            color: #ffffff;
            font-weight: 700;
            cursor: pointer;
            border-radius: 40px;
            &:focus{
                outline: none;
            }
        }
    }
    ul{
        margin-bottom: 0;
        margin-top: 30px;
        li{
            display: inline-block;
            text-align: center;
            opacity: .7;
            cursor: pointer;
            padding-right: 30px;
            margin-right: 30px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                height: 50px;
                width: 1px;
                transform: translate(50%, -50%);
                background-image: linear-gradient(transparent 0%, #ffffff 58%,transparent 100%);
            }
            h5{
                font-size: 18px;
                @extend .font-primary;
                color: #ffffff;
            }
            .detail{
                @extend .font-primary;
                font-size: 14px;
                color: #ffffff;
            }
            &:hover{
                opacity: 1;
            }
            &:last-child{
                margin-right: 0;
                padding-right: 0;
                &:after{
                    content: none;
                }
            }
        }
    }
}