
/*--------------------------------------------------------------
##  Blog Widget
--------------------------------------------------------------*/

.sidebar .widget {
    background: #fff;
    padding: 20px 20px 40px 20px;
    margin-bottom: 30px;
}

.widget-title {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 40px;
    text-align: center;
    margin-top: 20px;
}
.sidebar .widget p{
    font-size: 15px;
    @include font-three-6;
    color: $para_color;
    text-align: center;
    line-height: 28px;
}
.about-widget {
    text-align: center;
    p {
        margin-top: 24px;
        padding: 0 10px;
    }
}

.search-form input {
    width: 84%;
    border: none;
    background: transparent;
    padding: 13px;
    color: #808c9e;
    font-size: 14px;
}
.search-form button {
    border: none;
    background: transparent;
    color: #fff;
    background: #00c1fe;
    height: 100%;
    width: 14%;
    padding: 11px 0;
    float: right;
    cursor: pointer;
}

.search-form {
    width: 100%;
    background: #f0f2f8;
}
.widget-post{
    padding-bottom: 30px;
    border-bottom: 1px solid #f5f6fa;
    margin-bottom: 30px;
    display: flex;
    .post-image{
        width: 100%;
        max-width: 115px;
        margin-right: 15px;
    }
    .post-content{
        margin-top: -4px;
        width: 100%;
        h5{
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            text-align: left;
            @extend .font-primary;
            a{
                color: #2a384c;
              &:hover{
                  color: $main_two;
              }
            }
        }
        span{
            a{
                @extend .font-heading;
                line-height: 18px;
                font-size: 14px;
                font-weight: 600;
                color: #0b67f4;
            }


        }
    }
    &:last-child{
        margin-bottom: 0;
        padding-bottom:0;
        border-bottom: none;
    }
}


.category-widget{
    ul{
        li{
            border-bottom: 1px solid #f1f3f8;
            padding: 15px 0;
            a{
                font-size: 14px;
                @include font-three-6;
                color: $blog_title;
                width: 100%;
                span{
                    float: right;
                    background: #f1f3f8;
                    width: 30px;
                    height: 22px;
                    text-align: center;
                }
                &:hover{
                    color: $main_two;
                }

            }
            &:last-child{
                border-bottom: none;
            }
        }
    }
}


.tag-widget{
    ul{
        li{
            display: inline-block;
            margin-bottom: 10px;
            border: 1px solid #e1e1e1;
          border-radius: 19px;
          margin-right: 6px;
          transition: all .3s;
          &:hover{
              border: 1px solid transparent;
              a{
                  border: solid 3px #f7fcff;
                    box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.23);
                    background: $main_two;
                    color: #fff;
                    transition: all .3s;

              }
          }
            a{
                font-size: 14px;
                @include font-three-6;
                color: $blog_title;
                border-radius: 19px;
              text-align: center;
              padding: 5px 16px;
                border: solid 3px transparent;


            }
        }
    }
}

@include mq(992px, 1199px) {
    .widget-post {
        .post-image {
            max-width: 90px;
        }
        .post-content {
            h5{
                line-height: 20px;
            }
            span a {
                line-height: 18px;
                font-size: 13px;
            }
        }
    }
}

@include mq-max(991px) {
    .sidebar{
        margin-top: 50px;
    }
}

@include mq-max(575px) {
    .widget-post {
        .post-image {
            max-width: 90px;
        }
        .post-content {
            h5{
                line-height: 20px;
                margin-bottom: 2px;
            }
            span a {
                line-height: 18px;
                font-size: 13px;
            }
        }
    }
}