.inner-content-box{
	ul{
		padding-top: 15px;
		padding-bottom: 20px;
		li{
			font-size: 15px;
			color: #808c9e;
			@include font-three-6;
			margin-bottom: 10px;
			padding-left: 20px;
			position: relative;
			&:after{
				position: absolute;
				left: 0;
				top: 8px;
				height: 7px;
				width: 7px;
				background: #677283;
				border-radius: 50px;
				content: '';
			}
		}
	}
}

.video-area-2 {
	background: rgba(25, 123, 235, 0.79);
	overflow: hidden;
	height: 370px;
	width: 410px;
	text-align: center;
	position: absolute;
	left: 50%;
	bottom: 0;
}

.video-1{
	position: relative;
	background-position: right;
	background-size: 100%;
	position:relative;
	z-index: 1;
}

.video-1::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	height: 100%;
	width: 50%;
	background: url(../../media/images/bg/halfbg.jpg) no-repeat;
	background-position: left;
	background-size: cover;
	z-index: -1;
}
.video-area-2 .play-button {
	position: relative;
	width: 63px;
	text-align: center;
	float: none;
	margin: 0 auto;
	top: 50%;
	transform: translateY(-50%);
}

.video-area-2 .signal, .video-area-2 .signal2 {

	background-color: #fff;

}
.video-area-2 .play-button a {
	background: #fff;
}
.video-area-2 .play-button a i {
	color: #3165f4;
}
.video-area-2 .play-button::after {
	position: absolute;
	content: '';
	top: -12px;
	left: -11px;
	width: 84px;
	height: 84px;
	background: #fff;
	opacity: 0.1;
	z-index: -1;
	border-radius: 50px;
}

.video-1 .inner-content-box {
	padding-bottom: 60px;
	padding-right: 140px;
}

@include mq-max(1199px) {
	.video-1 .inner-content-box {
		padding-right: 0px;
		padding-bottom: 0;
	}
}
@include mq-max(991px) {
	.video-area-2 {
		height: 384px;
		width: 100%;
		position: inherit;
		left: inherit;
		bottom: inherit;
		margin-top: 40px;
	}
	.video-1::after{
		content: none;
	}
}