.service-2 {
    background-image: url(../../media/images/bg/square.png), url(../../media/images/bg/square2.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom right, center left;
}

.footer-two {
	padding: 100px 0 0;
    background: #080957;
    background-image: url(../../media/images/bg/booter_bg_2.png);
    background-repeat: no-repeat;
    background-size: cover;
	background-position: center;
	.footer-widget-wrap{
		padding-top: 140px;
	}	
	.footer-widget {
		.logo {
			padding: 5px 0 54px;
		}
		p {
			font-size: 14px;
			color: #abb1bb;
			line-height: 28px;
			@include font-three-6;
		}
	}
	.footer-bottom p {
		color: #6c798c;
		text-align: left;
	
		span {
			color: $main_two;
		}
	
		a {
			color: $main_two;
		}
	}
	&.style-two .footer-bottom p {
		color: #3f3f3f;
		text-align: left;
	}
}

.social-two {
    ul {
        li {
            display: inline-block;
            margin-right: 10px;
            border-radius: 50px;
            text-align: center;
            line-height: 40px;
            position: relative;
            @include transition(all .3s);

            &:hover {
                @include transition(all .3s);
            }

            i {
                position: absolute;
                top: 0;
                left: 0;
                color: #fff;
                left: 0;
                top: 36%;
                right: 0;
                font-size: 13px;
            }
        }

        a {
            color: #929caa;
            font-size: 16px;
            display: block;
            @include transition(all .3s);
            .svg {
                height: 48px;
                width: 43px;
                .st0 {
                    fill: #0c1b31;
                    stroke: #fff;
                    stroke-miterlimit:10;
                    @include transition(all .3s);
                }
            }
            &:hover {
                .svg {
                    .st0 {
                        fill: $main_two;
                        stroke: $main_two;
                        @include transition(all .3s);
                    }
                }
            }
        }
    }

}


.footer-two .footer-menu {
    ul {
        li {
            padding-bottom: 0;
            margin-bottom: 20px;
            a {
                font-size: 14px;
                color: #abb1bb;
                @include font-three-6;
                &:hover{
                    color: #ffffff;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.footer-widget {
    padding-bottom: 50px;
    overflow: hidden;

    img {
        margin-right: 20px;
    }
}

.footer-two .widget {
    h3 {
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        @include font-one-6;
        position: relative;
        margin-top: 26px;
        margin-bottom: 65px;
        &:after {
            position: absolute;
            content: '';
            top: 35px;
            left: 0;
            background: #a2a2be;
            height: 1px;
            width: 100px;

        }

        &:before {
            position: absolute;
            content: '';
            top: 35px;
            left: 0;
            background: #1772ff;
            height: 1px;
            width: 40px;
            z-index: 99;

        }
    }

    .single-footer-post {
        border-bottom: 1px solid #353672;
        padding: 26px 0;
		&:first-child{
			padding-top: 0;
		}
        div {
            font-size: 12px;
            color: #808c9e;
            @include font-three-6;

            span {
                color: #20b51b;
            }

            a {
                color: #808c9e;
            }
        }
		> a{
            font-size: 14px;
            color: #abb1bb;
            @include font-three-6;
            margin-top: 10px;
            line-height: 26px;
            &:hover{
                color: #ffffff;
            }
        }
    }
}

.footer-two .footer_long_text {
    img {
        float: left;
    }

    p {
        font-size: 14px;
        color: #6c798c;
        @include font-three-3;
        font-style: italic;
        overflow: hidden;
        padding: 0 160px 0 40px;

    }
}


.footer-two .footer-address {
    padding-bottom: 20px;
    padding-top: 10px;
    li {
        color: #e6e8ea;
        @extend .font-primary;
        font-size: 14px;
        font-weight: 600;
    }

    a {
        font-size: 14px;
        color: #abb1bb;
        @include font-three-6;
        padding: 7px 0;
        margin-left: 15px;
        i {
            margin-right: 15px;

        }
        &:hover{
            color: #ffffff;
        }
    }

}

.social {
    p {
        font-size: 16px;
        color: $main_two;
        @include font-one-6;
        position: relative;
        margin-bottom: 40px;

        &:after {
            position: absolute;
            background: #56595f;
            height: 1px;
            width: 50px;
            bottom: -7px;
            left: 0;
            content: '';
        }
    }

}

#subscribe-form-two input {
    background: transparent none repeat scroll 0 0;
    border-color: transparent;
    border-bottom: 1px solid #fff;
    color: #fff;
    float: left;
    font-size: 12px;
    font-weight: 400;
    height: 45px;
    width: 100%;
}

#subscribe-form-two button {
    background: transparent;
    border: medium none;
    text-align: right;
    font-size: 15px;
    line-height: 46px;
    position: absolute;
    right: 0;
    transition: all 0.3s ease 0s;
    width: 70px;
    cursor: pointer;

    i {
        color: #17b212;
    }
}

#subscribe-form-two {
    position: relative;
    overflow: hidden;
    float: right;
    width: 50%;
}

.payment-link li {
    display: inline-block;
    margin-left: 10px;
}

.footer-two .footer-bottom {
    background: transparent;
    padding: 28px 0 20px;
    background: rgba(13, 14, 81, 0.6);
    margin-top: 75px;
}
.footer-subscribe {
    overflow: hidden;
    border-bottom: 1px solid #262568;
    padding-bottom: 100px;

    h3 {
        font-size: 36px;
        @include font-one-7;
        color: #fff;
        display: inline;
        float: left;
        span {
            font-weight: 400;
        }

    }
}

/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/


.backtotop {
    position: fixed;
    bottom: 25px;
    right: 25px;
    border: 2px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    z-index: 9999;
    -webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
    -moz-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
    box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
}

.backtotop i {
    height: 42px;
    width: 42px;
    line-height: 42px;
    font-size: 20px;
    background: #fff;
    color: $main_color;
    text-align: center;
    border-radius: 50%;
}

.payment-link {
    float: right;
}

// =========================== Footer 2 Subscribe area
.footer-contact-form {
    input {
        width: 49%;
        float: left;
        background: #ddd;
        border-color: transparent;
        height: 40px;
        margin-bottom: 18px;
        padding-left: 10px;
        background: rgba(255, 255, 255, 0.09);
        color: #abb1bb;
        text-transform: capitalize;
        font-size: 14px;
        &:first-child {
            margin-right: 2%;

        }

    }

    textarea {
        height: 115px;
		width: 100%;
        border-color: transparent;
        padding: 10px;
        background: rgba(255, 255, 255, 0.09);
        color: #abb1bb;
        text-transform: capitalize;
        font-size: 14px;
    }

    button {
        font-size: 15px;
        color: #fff;
        padding: 7px 33px;
        border-radius: 26px;
        font-weight: 500;
        z-index: 50;
        background: #3263f9;
        text-transform: uppercase;
        border: 3px solid #f7fbfe;
        -webkit-box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);
        box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);
        margin-top: 16px;
        cursor: pointer;
        transition: all .3s;

        &:hover {
            background: $main_two;
        }
    }
}
@include mq(1200px, 1429px) {
	.social-two ul a .svg {
		height: 41px;
	}
	.social-two ul li {
		margin-right: 6px;
		i {
			font-size: 12px;
		}
	}
}


@include mq-max(991px) {
	.footer-two {
		.footer-address {
			padding-bottom: 21px;
			padding-top: 20px;
		}
	}
	// Subcribe 
    .footer-subscribe {
        h3 {
            font-size: 29px;
        }
    }

    #subscribe-form-two {
        width: 40%;
    }
}

@include mq-max(767px) {
	.footer-two {
		.footer-widget-wrap {
			padding-top: 110px;
		}
	}
	// Subcribe 
    .footer-subscribe {
        h3 {
            display: block;
            float: none;
            text-align: center;
        }
    }

    #subscribe-form-two {
        float: none;
        width: 80%;
        margin: 0 auto;
    }

    // Footer Bottom
    .footer-two {
        .footer-bottom p {
            text-align: center;
        }
        .payment-link{
            float: none;
            text-align: center;
            margin-top: 5px;
        }
    }

}

@include mq-max(575px) {

    .footer-subscribe {
        h3 {
            font-size: 26px
        }
    }

    #subscribe-form-two {
        width: 100%;
    }
}