/*--------------------------------------------------------------
  ##  Header style One or Navsidebar or default
--------------------------------------------------------------*/
@import "navsidebar";

/*--------------------------------------------------------------
  ##  Header style two
  --------------------------------------------------------------*/
@import "header-style-2";


/*--------------------------------------------------------------
   						Header Responsive
--------------------------------------------------------------*/
@media (min-width: 1200px) {
	.navsidebar {
		display: block!important;
	}
}
@media (max-width: 1199px) {
	.is-navsidebar{
		.site{
			padding-left: 0;
		}
	}
	.navsidebar {
		left: -100%;
		.navbar-wrap{
			overflow-y: scroll;
		}
		&.show{
			left: 0;
		}
	}
	
	.moibe_nav_toggler_1{
		display: inherit;
	}
}