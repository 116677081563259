.about-1{
	background-image: url(../../media/images/bg/line_bg1.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	.about-number{
		display: flex;
		align-items: flex-start;
		.number-wrap{
			font-weight: 700;
			.digit{
				font-size: 230px;
				line-height: 164px;
				background: radial-gradient(closest-side, transparent, #1b4db8 50%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.prefix{
				font-size: 50px;
				color: #1b4db8;
				top: 0;
				left: -8px;
			}
			  
		}
		img{
			float: left
		}
		.about-number-des{
			overflow: hidden;
			border-bottom: 1px solid #ddd;
			margin-left: 21px;
			width: 250px;

			p{
				font-size: 18px;
				color: #4e5c6f;
				@include font-three-6;
				text-transform: uppercase;
				letter-spacing: 5px;

			}
		}

	}
	.about-one-img{
		position: relative;
		&:after{
			content: '';
			position: absolute;
			left: -40px;
			top: 94px;
			width: 100%;
			height: 91%;
			border: 20px solid #f7fcff;
			opacity: .74;
			z-index: -1;
		}
	}
	.block-content{
		margin-bottom: 58px;
	}
}

.block-content{

	p{
		font-size: 15px;
		color: #808c9e;
		@include font-three-6;

	}

}

@include mq-max(1199px) {
	.about-1 {
		.section_header.style-two h3 {
			font-size: 31px;
		}
		.about-number{
			.number-wrap {
				.digit {
					font-size: 159px;
					line-height: 128px;
				}
				.prefix {
					font-size: 44px;
				}
			}
			.about-number-des {
				width: auto;
				max-width: 250px;
				p{
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 4px;
				}
			}
		}

	}
}
@include mq-max(991px) {
	.about-1 {
		.about-one-img{
			margin-top: 60px;
		}
		.block-content {
			margin-bottom: 34px;
		}
		.about-number{
			.number-wrap {
				.digit {
					font-size: 110px;
					line-height: 86px;
				}
				.prefix {
					font-size: 36px;
				}
			}
			.about-number-des {
				margin-top: 10px;
				margin-left: 0;
			}
		}
	}
}

@include mq-max(575px) {
	.about-1 {
		.section_header.style-two h3 {
			font-size: 26px;
		}
		.about-number {
			flex-wrap: wrap;
		}
	}
}
/* About two css */

.about-image{
	position: relative;
}
.about-image::after {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	background: #e5edfa;
	left: 20px;
	bottom: -20px;
	z-index: -1;
}
.about_2 .inner-content-box {
	padding-right: 115px;
}



.sin-about-2{
	margin-top: 35px;
	.icon{
		width: 14%;
		float: left;
		img{

		}
		span{
			font-size: 50px;
			color: #edeef0;
			@include font-three-7;
			padding-left: 13px;
		}
	}
	.about-2-content{
		overflow: hidden;
		padding-right: 60px;
		h6{
			font-size: 18px;
			color : $sub_head;
			@include font-one-6;
			transition: all .3s;

		}
		p{
			color: #808c9e;
			font-size:14px;
			@include font-one-5;

		}
	}
	&:hover{
		.about-2-content{
			h6{
				color : $main_two;


			}

		}

	}
}