/*--------------------------------------------------------------
  ##  Cloud feature area
  --------------------------------------------------------------*/
.multi_bg_wave{
	background: url(../../media/images/bg/linebg.png), url(../../media/images/bg/fold_wave.png);
	background-repeat:  no-repeat;
	background-position:  bottom left, top right;
	padding-bottom: 290px !important;
}

.single-cloud-feature{
	text-transform: uppercase;
	text-align: center;
	box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1), inset 0px 8px 33.8px 4.2px rgba(221, 234, 255, 0.39);
	padding: 80px 0 70px;
	margin: 15px 15px;
    background: #fff;
	p{
		font-size: 14px;
		color:#00c1ff;
		font-weight: 400;
		margin-bottom: 5px;
	}
	h6{
		color:#00c1ff;
		font-weight: 700;

	}
	.image {
		width: 20%;
		margin: 0 auto;
		margin-bottom: 32px;
		text-align: center;
		min-height: 50px;
		img{
			display: inline;
		}

	}
}


/*--------------------------------------------------------------
  ##  Cloud Exclusive feature area
  --------------------------------------------------------------*/

  .cloud-excusive-feature.section-padding {
  	padding-bottom: 65px;
  }
  .cloud-excusive-feature{
  	background-image: url(../../media/images/background/dark1.jpg);
  	background-repeat: no-repeat;
	  background-size: cover;
	  .section_header{
		  padding: 0 15px;
	  }
  }

  .single-ex-feature{
  	margin-bottom: 50px;
  	.top-box{
  		overflow: hidden;
  		.ex-image{
  			width: 67px;
  			float: left;
  		}
  		h4{
  			font-size: 18px;
  			color: #fff;
  			position: relative;
  			overflow: hidden;
  			padding-bottom: 19px;
  			margin-top: 2px;
  			&:after{
  				content:'';
  				position: absolute;
  				left: 0;
  				bottom: 6px;
  				background: #fff;
  				width: 20px;
  				height: 1px;
  			}
  		}
  	}
  	p{
  		color: #aabbd5;
  		margin-left: 6px;
		margin-top: 9px;
		max-width: 375px;
  	}
  }

  @include mq-max(1429px) {
	.cloud-feature{
		.section_header.style-two {
			margin-bottom: 12px;		
			h3 {
				font-size: 30px;
			}
		}
		.btn-3 {
			margin-top: 15px;
		}
	}
}

@include mq-max(1199px) {
	.cloud-feature{
		.inner-content-box{
			text-align: center;
			margin-bottom: 35px;
		}
		.section_header.style-two {
			text-align: center;
		}
		.block-content p {
			max-width: 566px;
			margin: 0 auto;
		}
	}
}


@include mq-max(575px) {
	.cloud-feature .section_header.style-two h3 {
		font-size: 24px;
	}
}


/*--------------------------------------------------------------
  ##  Customer review
  --------------------------------------------------------------*/


  .customer-review-carousel {
  	margin-top: -8px;
  }


  .single-customer-review{
  	height: 335px;
  	margin: 9px 20px;
  	.cr-image-box{
  		width: 231px;
  		height: 231px;
  		border-radius: 3px;
  		box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.07);
  		background-color: #fcfcfc;
  		border: 10px solid #fff;
  		text-align: center;
  		padding: 25px 0;
  		p{
  			color: #808c9e;
  			font-size: 15px;
  			@include font-three-5;


  		}
  		span{

  			font-size: 16px;
  			font-weight: 600;
  			font-style: normal;
  			letter-spacing: normal;
  			color: #2a384c;
  		}
  		.image {
  			width: 100px;
  			height: 100px;
  			border-radius: 100%;
  			border: 5px solid #fff;
  			overflow: hidden;
  			box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.07);
  			margin: 0 auto;
  			margin-bottom: 15px;
  		}
  	}
  	.review-box {
  		position: absolute;
  		top: 74px;
  		left: 115px;
  		padding: 30px;
  		padding-left: 169px;
  		box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.07);
  		z-index: -1;
  		padding-bottom: 50px;
  		margin-right: 6px;
  		p{
  			font-size: 14px;
  			color: #67778e;
  			font-weight: 600;
  			font-style: italic;
  			font-family: $font_primary;
  			line-height: 2;

  		}
  		i{
  			color: #3263f9;
			-webkit-box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.08);
			box-shadow: 0px 4px 11.1px 2px rgba(30, 64, 165, 0.08);
			font-size: 25px;
			margin-bottom: 20px;
			margin-left: 6px;
  		}
  	}
  }

  .customer-review-carousel.owl-theme .owl-dots .owl-dot span {
	width: 22px;
	height: 4px;
	margin: 5px 7px;
	background: #9db4fc;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 0;
}

.customer-review-carousel.owl-theme .owl-dots .owl-dot.active span, .customer-review-carousel.owl-theme .owl-dots .owl-dot:hover span {
	background: #3263f9;
}

.customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
	margin-top: -127px;
	z-index: 999999;
	position: relative;
	left: 8px;
}

.cloud-feature .btn-3{
	margin-top: 22px;
}

@include mq(1200px, 1429px) {
	.customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
		margin-top: -74px;
	}
}

@include mq-max(991px) {
	.customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
		margin-top: -100px
	}
}
@include mq-max(767px) {
	.single-customer-review .cr-image-box {
		width: 213px;
		height: 231px;
	}
	.single-customer-review .review-box {

		left: 83px;
	}
	.customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
		margin-top: -50px;
	}
}
@include mq-max(575px) {
	.single-customer-review{
		height: inherit;
	}
	.single-customer-review .review-box{
		position: inherit;
		padding: 15px;
	}
	.customer-review-carousel.owl-theme .owl-nav.disabled + .owl-dots {
		display: none;
	}
	.single-customer-review .cr-image-box {

		margin: 0 auto 20px;
	}
}
/*--------------------------------------------------------------
  ## Hosting plan section
  --------------------------------------------------------------*/


.pricing-three-configation-title{
	padding-top: 289px;
	ul{
		li{
			font-size: 16px;
			color: #45566f;
			text-align: left;
			padding: 13px 30px;
			border-bottom: 1px solid #d6e7f4;
		}
	}
}

.single-pricing-three{
	text-align: center;
	box-shadow: 0 0 13px 0 rgba(101, 114, 129, 0.07);
	border-radius: 8px;
	padding: 60px 0;
	background: #fff;
	ul{

		li{
			font-size: 16px;
			color: #67778e;
			text-align: left;
			padding: 13px 30px;
			border-bottom: 1px solid #eef6fc;
			text-align: center;
		}
	}
	.pricing-image{
		text-align: center;
		width: 115px;
		height: 115px;
		border-radius: 100%;
		margin: 0 auto;
		line-height: 105px;
		position: relative;
		z-index:1;
		margin-bottom: 60px;
		&:before{
			content: '';
			position: absolute;
			left: -20px;
			top: -20px;
			width: 155px;
			height: 155px;
			background: #fafcfe;
			z-index: -2;
			border-radius: 100%;

		}
		&:after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 115px;
			height: 115px;
			background: #f5f9fd;
			z-index: -1;
			border-radius: 100%;

		}
	}
	h4{
		font-size: 16px;
		color: $main_three;
		font-weight: 800;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 35px;
	}
	h3{
		font-size: 36px;
		color: $main_three;
		text-align: center;
		font-weight: 800;
		margin-top: 36px;
	}
	.btn-3{
		margin-top: 24px;
	}
}

.single-pricing-three.high-pack{
	box-shadow: 0 0 32px 0 rgba(101, 114, 129, 0.18);
	z-index: 1111;
	position: relative;
	h4{
		color: $main_two;

	}
	h3{
		color: $main_two;

	}
	.btn-3{
		background: $main_two;

	}
}

.half-bg{
	position: relative;
	&:before{
		content: '';
		position: absolute;
		left: 15px;
		top: 440px;
		background: #f6fbfe;
		width: 106%;
		height: 475px;
		z-index: -1;
		box-shadow: 0px 1px 7.7px 1.4px rgba(50, 99, 249, 0.08);
		border-radius: 0 22px 22px 0;

	}
}



@include mq-max(1429px) {
	.half-bg:before {
		width: 100%;
	}
}
@include mq-max(1199px) {
	.pricing_3 {
		.pricing-three-configation-title {
			display: none;
		}
		.half-bg:before {
			content: none;
		}
	}
}
@include mq-max(991px) {
	.single-pricing-three{
		margin-top: 40px;
	}
}