/* ==================================================== */
/* ==================== sidebar Menu =================== */
/* ==================================================== */
.mobile-navbar-wrap{
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    max-width: 315px;
    background: #fff;
    z-index: 99999;
    height: 100%;
    min-height: 100vh;
    overflow: visible;
    overflow-y: scroll;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-delay: 0.3s;
    transition: all .6s ease-in-out;
    z-index: 999999;

    // MObile Wrap Box
    .mobile-box {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 17px 18px;
        display: flex;
        flex-direction: column;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 21%;
            height: 82px;
            width: 36px;
            border: 5px solid;
            border-left: 0;
            border-color: rgba(0,0,0,.5);
            opacity: .09;
            border-radius: 0 150px 150px 0;
        }
        &:before{
            content: '';
            position: absolute;
            right: 0;
            top: 45%;
            height: 62px;
            width: 32px;
            border: 3px solid;
            border-right: 0;
            border-color: rgba(0,0,0,.5);
            opacity: .09;
            border-radius: 150px 0 0 150px;
        }
    }

    // Canvas Main Menu
    .mobile-menu{
        position: relative;
        z-index: 90;
        text-align: center;
        margin-bottom: 30px;
        > li {
            transition: all .4s;
            border-radius: 2px;
            margin-bottom: 13px;
            > a {
                font-size: 16px;
                color: #63799b;
                font-weight: 500;
                letter-spacing: .1px;
                opacity: .8;
                display: inline-block;
                &:hover{
                    @extend .main-two;
                    &:after{
                        @extend .main-two;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .submenu {
            background: rgba(#fff, .3);
            display: none;
            border-radius: 0 0 5px 5px;
            li {
                a {
                    @extend .main-color;
                    padding: 5px 15px;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    opacity: .8;
                }
            }
        }
        .caret-dropdown{
            > a{
                position: relative;
                padding-right: 17px;
                &:after{
                    position: absolute;
                    content: "\f107";
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-family: 'Font Awesome\ 5 Free';
                    color: #63799b;
                    font-weight: 700;
                    transition: all .3s linear;
                    font-size: 15px;
                }
            }
            &.open{
                > a{
                    @extend .main-two;
                    &:after{
                        @extend .main-two;
                    }
                }
            }
        }
    }
    
    .mobile-nav-header{
        margin-bottom: 84px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        padding-bottom: 22px;
    }
    .mobile-menu-close {
        @extend .main-color;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border-radius: 2px;
        i {
            font-size: 18px;
        }
    }
    .mobile-footer{
        margin-top: auto;
        form{
            display: flex;
            background: #f5f8fb;
            border: 1px solid #e6e9ff;
            padding: 0 15px;
            margin-bottom: 23px;
            justify-content: space-between;
            border-radius: 2px;
            input{
                border: 0;
                font-size: 12px;
                color: #000;
                line-height: 24px;
                padding: 11px 0; 
                background: transparent;
                width: 100%;
            }
            button{
                background: transparent;
                border: 0;
                @extend .main-color;
                font-size: 12px;
                transition: all .3s linear;
                &:hover{
                    @extend .main-two;
                }
            }
        }
        .social_link{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding-top: 25px;
            margin-bottom: 6px;
            ._title{
                font-size: 12px;
                font-weight: 500;
                color: #63799b;
                letter-spacing: .5px;
            }
            ul{
                margin-bottom: 0;
                li{
                    display: inline-block;
                    a{
                        
                        margin-right: 0px;
                        width: 32px;
                        height: 32px;
                        border: 1px solid #63799b;
                        border-radius: 50px;
                        text-align: center;
                        line-height: 30px;
                        color: #63799b;
                        font-size: 12px;
                        &:hover{
                            @extend .main-two;
                        }
                    }
                    &:first-child{
                        a{
                            margin-left: 0
                        }
                    }
                }
            }
        }
    }
}

body.active {
    .mobile-navbar-wrap {
        left: 0!important;
    }
    .sidebar-manu-close {
        left: 100%;
    }
}

.mask-overlay {
    position: fixed;
    background: rgba(#000, .7);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}

@include mq-max(575px) {
    .mobile-navbar-wrap .mobile-nav-header {
        margin-bottom: 45px;
    }
}