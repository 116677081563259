.section_header {
    text-align: center;
    width: 100%;
    margin-bottom: 100px;

    >span {
        color: $header_up;
        font-size: 16px;
        @include font-one-6;
        text-transform: uppercase;
        display: block;
        line-height: 16px;
        letter-spacing: 2px;

    }

    h3 {
        color: $header_color;
        @include font-one-8;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        margin-top: 20px;
        position: relative;
        padding-bottom: 35px;
        &:before {
            content: '';
            position: absolute;
            width: 300px;
            height: 1px;
            background-image: linear-gradient(to right, transparent 0%, #01c0f6 50%, transparent 100%);
            bottom: 3px;
            left: 50%;
            background-repeat: no-repeat;
            background-size: auto;
            transform: translateX(-50%);
        }
        &:after {
            content: '';
            position: absolute;
            width: 200px;
            height: 1px;
            background-image: linear-gradient(to right, transparent 0%, #01c0f6 50%, transparent 100%);
            bottom: 0;
            left: 50%;
            background-repeat: no-repeat;
            background-size: auto;
            transform: translateX(-50%);
        }
        span {
            color: $main_color;
        }
    }

    &.light-style {
        >span {
            color: #fff;
        }

        h3 {
            color: #fff;

            &:after {
                display: none;
            }
        }
    }
}

.section_header.style-two {
    text-align: left;
    margin-bottom: 20px;

    h3 {
        margin-top: 15px;
        color: #2a384c;
        padding-bottom: 0;
        span {
            color: $main_three;
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }   
    }


    >span {
        font-size: 15px;
        color: #2F5774;
    }
}

.section_header_3 {
    text-align: left;
    width: 100%;

    > span {
        color: $header_up;
        font-size: 16px;
        @include font-one-4;
        text-transform: uppercase;
        display: block;
        line-height: 16px;
        letter-spacing: 2px;
        margin-bottom: 15px;
    }

    h3 {
        color: $header_color;
        @include font-one-7;
        text-transform: capitalize;
        position: relative;
        display: inline-block;
        margin-bottom: 0;

        span {

            color: $main_color;
        }

        .font-light {
            font-weight: 300;
        }
    }
}

.section-head-3 {
    font-size: 26px;
    color: #1c2c43;
    @include font-one-8;
    text-transform: uppercase;

    span {
        color: $main_color;

    }
}

.section_header.light-style {
    margin-bottom: 90px;
}

.section_header.no-line {
    h3{
        padding-bottom: 0;
        &:before,
        &::after {
            content: none;
        }
    }
}

// Parallax
.paralax {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

@include mq-max(575px) {
    .section_header{
        h3 {
            font-size: 26px;
        }
    }
    .section_header.style-2{
        h3 {
            margin-top: 11px;
        }
    }

}