/*--------------------------------------------------------------
	##  Fonts
	--------------------------------------------------------------*/

	@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Open+Sans:300i,300,400,600,700,800|Poppins:300,400,500,600,700,800,900";


	html{
		font-size: 16px;
	}

	body {
		font-family: 'Montserrat', sans-serif;
		color: #3f3f3f;
		font-weight: 400;

		font-size: 100%;
		font-variant-ligatures: none;
		-webkit-font-variant-ligatures: none;
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-smoothing: antialiased;
		-webkit-font-smoothing: antialiased;
	}

	input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
		border-radius: 0px;
		padding: 0px;
	}
	input:focus, textarea:focus{
		outline: none;
	}

	img{
		max-width: 100%;
	}

	svg {
		display: block;
	}

	a {
		transition: all 0.15s ease-in-out;
		display:inline-block;
		outline: 0;
	}

	a,
	a:hover {
		text-decoration: none;
	}

	ul {
		margin: 0;
		list-style: none;
		padding: 0;
	}

	.container-small{
		max-width: 880px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;

	}

	h1{
		font-size: 60px;
		@extend .font-heading;
	}
	h2{
		font-size: 40px;
		@extend .font-heading;
	}
	h3{
		font-size: 36px;
		@extend .font-heading;
	}
	h4{
		font-size: 20px;
		@extend .font-heading;
	}
	h5{
		font-size: 20px;
		@extend .font-heading;
	}
	h6{
		font-size: 16px;
		@extend .font-heading;
	}


	p{
		font-size: 15px;
		margin-bottom: 10px;
		line-height: 28px;
		color: #67778e;
		font-weight: 600;
		@extend .font-primary;
	}
	.section-padding{
		padding: 120px 0;
	}
	.padding-50 {
		padding: 50px 0;
	}
	.padding-120{
		padding:120px 0!important;
	}
	.container-two {
		max-width: 1430px;
	}
	.padding-150{
		padding:150px 0;
	}
	.bg-one{
		background: $bg_one
	}

	.pb-60{
		padding-bottom: 60px!important;
	}
	.pb-50{
		padding-bottom:50px;
	}
	.pad-45{
		padding:45px 0!important;
	}
	.pb-30{
		padding-bottom:30px;
	}
	.no-padding{
		padding:0px;
	}
	.bg-two {
		background-color: $bg_two;
	}
	.bg-three{
		background: #e7f3f5;
	}
	.padding-top-120{
		padding-top: 120px;
	}
	.padding-top-90{
		padding-top: 90px;
	}
	.bb-content-wrapper {
		padding-left: 242px;
	}

	.header,
	.content,
	.footer
	{
		text-align: center;
	}
	.header,
	.footer
	{
		background: #777;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
		line-height: 40px;


		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		height: 40px;
		padding: 0 50px;
	}

	.footer.fixed
	{
		position: fixed;
		bottom: 0;
		left: 0;
	}