$single_quick_link_margin_top: 50px;
.link-content{
	display: none;
	padding-bottom: 110px;
}

.single-quick-link{
	margin-top: $single_quick_link_margin_top;
	span{
		font-size: 14px;
		color: #495971;
		font-family: 'Montserrat', sans-serif;
		font-weight: 500;
		position: relative;
		display: block;
		border-bottom: 1px solid #ececec;
		padding-bottom: 10px;
		&:after{
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 45px;
			height: 1px;
			background: #0069ff;

		}
	}
	ul{
		margin-top: 37px;
		li{
			padding-bottom:6px;
			a{
				font-size: 14px;
				color: #808c9e;
				font-weight: 400;
			}
		}
	}
}
.quick-link-header {
	margin-bottom: 40px;
	border-top: 1px solid #ebebeb;
	padding-top: 33px;
	cursor: pointer;
	p{
		position: relative;
		font-size: 16px;
		color: #1c2c43;
		padding-left: 36px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		@include font-one-7;

		span{
			font-weight: 500;
			color: #808c9e;

		}

	}
}


.pm_icon {
	box-sizing: border-box;
	transition: transform 0.3s;
	width: 24px;
	height: 24px;
	transform: rotate(180deg);
	position: relative;
	float: left;
	max-right: 10px;
	top: 3px;
	&::before {
		content: "";
		display: block;
		width: 15px;
		height: 0px;
		border-bottom: solid 2px #0069ff;
		position: absolute;
		bottom: 11px;
		transform: rotate(90deg);
		transition: width 0.3s;
		left: 5px;
	}
	&::after {
		content: "";
		display: block;
		width: 15px;
		height: 0px;
		border-bottom: solid 2px #0069ff;
		position: absolute;
		bottom: 11px;
		left:5px
	}
	&.active {
		transform: rotate(0deg);
		&::before {
			content: "";
			display: block;
			width: 0px;
			height: 0px;
			border-bottom: solid 2px #0069ff;
			position: absolute;
			bottom: 11px;
			transform: rotate(90deg);
		}
	}
}

/* @-webkit-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@-moz-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@-ms-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@-o-keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(90deg); }
}
@keyframes color_change {
	from { transform: rotate(0deg); }
	to { transform: rotate(180deg); }
}

.pm_icon  {
	-webkit-animation: color_change .3s  ;
	-moz-animation: color_change .3s  ;
	-ms-animation: color_change .3s  ;
	-o-animation: color_change .3s  ;
	animation: color_change .3s ;
} */




