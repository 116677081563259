.blog-detail-page {
    .single-blog{
        background: #fff;
        box-shadow: none;
    }
}

.single-blog {
    .sin-blog-content {
        .category-title {
            color: #67778e;
            font-size: 14px;
            @include font-three-6;
            margin-bottom: 10px;
        }

        .entry-content {
            p {
                color: #67778e;
                font-size: 15px;
                @include font-three-6;
            }
        }

        h6 {
            font-size: 18px;
            @include font-one-7;
            font-size: 18px;
            margin: 25px 0 22px;
        }

        img {
            margin: 20px 0 35px;
        }
    }

    .blog-bottom-tag {
        display: inline-block;
        float: left;
        font-size: 14px;
        @include font-three-6;
        color: #67778e;
        margin-top: 7px;

        a {
            color: $main_three;
        }

    }
}


.social-blog {
    float: right;

    ul {
        li {
            display: inline-block;
            margin-left: 8px;
            &:first-child{
                margin-left: 0;
            }
            a {
                color: #67778e;
                font-size: 12px;
                width: 30px;
                height: 30px;
                border: 1px solid #67778e;
                text-align: center;
                line-height: 28px;
                border-radius: 50px;
                transition: all .3s;

                &:hover {
                    color: $main_two;
                    border: 1px solid $main_two;
                    transition: all .3s;
                }
            }
        }
    }
}


.author-social {
    ul {
        li {
            display: inline-block;
            margin-right: 8px;

            a {
                color: #67778e;
                font-size: 12px;
                width: 30px;
                height: 30px;
                border: 1px solid #67778e;
                text-align: center;
                line-height: 28px;
                border-radius: 50px;
                transition: all .3s;

                &:hover {
                    color: $main_two;
                    border: 1px solid $main_two;
                    transition: all .3s;
                }
            }
        }
    }
}


.blog-bottom-wrapper {
    overflow: hidden;
    padding-top: 35px;
}

.blog-author-info {
    background: #ffffff;
    padding: 35px;
    display: flex;
    margin-top: 20px;

    .author-image {
        overflow: hidden;
        width: 100%;
        max-width: 140px;
        height: 100%;
        border: 5px solid #ffffff;
        border-radius: 50%;
        margin-right: 30px;
        box-shadow: 0px 2px 15.2px 0.8px rgba(105, 105, 105, 0.2);
    }

    .author-info {
        h6 {
            font-size: 18px;
            font-weight: 700;
            color: #2a384c;
        }
    }
}

// Related Article
.related-article-wrap {
    margin-top: 40px;

    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            color: #707f95;
            border: 1px solid #707f95;
            border-radius: 50%;
            transition: .3s;

            &:hover {
                color: #00c1ff;
                border-color: #00c1ff;
            }
        }

        h4 {
            color: #2a384c;
            font-weight: 900;
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
}

.related-article-items {
    display: flex;

    .article-item {
        background: #fff;
        padding: 20px 20px 30px;
        text-align: center;
        width: 100%;
        max-width: calc(50% - 15px);
        margin-top: 25px;
        h2 {
            font-size: 18px;
            line-height: 27px;
            color: #2a384c;
            font-weight: 700;
        }

        .public-date {
            @extend .font-primary;
            color: #0b67f4;
            font-weight: 600;
            font-size: 14px;
        }

        .title {
            margin-top: 15px;
        }

        a {
            &:hover {
                h4 {
                    color: #0b67f4;
                }
            }
        }

        &:first-child {
            margin-right: 30px;
        }
    }
}

// Blog Comment 
.comments-wrap {
    margin-top: 30px;
    padding: 40px 30px;
    background: #fff;

    .section-header {
        text-align: center;
        margin-bottom: 40px;

        h4 {
            color: #2a384c;
            font-weight: 900;
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
}

.comment-list {
    .comment-item {
        padding: 30px 0;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #eeeeee;

        .commenter-img {
            width: 100%;
            max-width: 90px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0px 2px 22.8px 1.2px rgba(105, 105, 105, 0.2);
            border: 3px solid #ffffff;
        }

        .comment-content {
            padding-left: 30px;

            .name-wrap {
                a {
                    @extend .font-primary;
                    font-size: 18px;
                    font-weight: 700;
                    color: #2a384c;
                    margin-right: 20px;
                }

                .date {
                    padding-left: 20px;
                    display: inline-block;
                    @extend .main-color;
                    font-size: 14px;
                    font-weight: 600;
                    position: relative;

                    &:after {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        height: 15px;
                        width: 1px;
                        background: #707f95;
                    }
                }
            }

            .speech {
                font-size: 15px;
                line-height: 28px;
                margin-top: 10px;
            }

            .reply {
                font-size: 14px;
                @extend .main-color;
                font-weight: 600;

                i {
                    margin-right: 2px;
                }
            }
        }
    }

    .comment-child {
        margin-left: 80px;
        padding-left: 0;
        list-style: none;
        li {
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

// Blog Comment Form 
.comment-form-wrap {
    margin-top: 30px;
    padding: 40px 30px;
    background: #fff;

    .section-header {
        text-align: center;
        margin-bottom: 40px;

        h4 {
            color: #2a384c;
            font-weight: 900;
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
}

.comment-form {

    input,
    textarea {
        padding: 15px;
        width: 100%;
        border: 1px solid #ebebeb;
        font-size: 14px;
        color: #67778e;
        font-weight: 600;
        margin-top: 20px;
        border-radius: 3px;
    }

    .input-wrap {
        display: flex;

        .form-input {
            width: 100%;
            max-width: calc(33.3333% - 14px);
            margin-right: 22px;

            &:last-child {
                margin-right: 0;
            }
        }

        input {
            padding: 15px;
        }
    }

    textarea {
        height: 130px;
        resize: none;
    }

    button {
        box-shadow: 0px 1px 7.6px 0.4px rgba(16, 13, 122, 0.14);
        border: 3px solid #ffffff;
        background-color: rgb(0, 193, 255);
        color: #fff;
        border-radius: 19px;
        font-size: 14px;
        font-weight: 600;
        padding: 7px 24px;
        margin-top: 16px;
        text-transform: uppercase;

        &:focus {
            outline: none;
        }
    }
}
@include mq(992px, 1199px) {
    .comment-list .comment-child {
        margin-left: 50px;
    }
}

@include mq-max(1199px) {
    .blog-author-info .author-image{
        max-width: 120px;
    }
}

@include mq-max(767px) {
    .blog-detail-page{
        .blog-bottom-wrapper{

            .blog-bottom-tag{
                float: none;
            }
            .social-blog{
                float: none;
                margin-top: 20px;
            }
        }
    }
    .related-article-items {
        flex-wrap: wrap;
        .article-item{
            max-width: 100%;
            &:first-child{
                margin-right: 0;
            }
        }
    }
    .comment-list {
        .comment-child {
            margin-left: 50px;
        }
        .comment-item {
            .comment-content {
                .name-wrap{
                    a {
                        margin-right: 10px;
                    }
                    .date {
                        padding-left: 13px;
                    }
                }
            }
        }
    }
    .comment-form {
        .input-wrap {
            flex-wrap: wrap;
            .form-input {
                max-width: 100%;
                margin: 00px;
            }
        }
    }
}
@include mq-max(575px) {
    .blog-author-info {
        flex-wrap: wrap;
        text-align: center;
        .author-image{
            max-width: 120px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
    .comment-list {
        .comment-child{
            margin-left: 30px;
        }
        .comment-item {
            flex-wrap: wrap;
            .comment-content {
                padding-left: 0px;
                margin-top: 20px;
                .name-wrap{
                    a {
                        font-size: 15px
                    }
                    .date {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}