
.footer-widget-area{
	padding:100px 0 0;
}

.footer-widget-area .footer-bottom p {
	color: #6c798c;
	text-align: left;
	span{
		color: $main_two;
	}
	a{
		color: $main_two;
	}
}
.footer-widget-area.style-two .footer-bottom p {
	color: #3f3f3f;
	text-align: left;
}
.footer-widget{
	h3{
		font-size: 18px;
		font-weight: 700;
		letter-spacing: .5px;
		color: #2a384c;
		margin-bottom: 40px;
		text-transform:uppercase;
		@include font-one-6;
	}
	.logo{
		padding:5px 0 33px;
	}
	p {
		font-size: 15px;
		color: #67778e;
		line-height: 28px;
		@include font-three-4;
	}
}

.social{
	ul {
		li {
			display: inline-block;
			margin-right: 12px;
			width: 40px;
			height: 40px;
			border: 2px solid #e0e0e0;
			border-radius: 50px;
			text-align: center;
			line-height: 40px;
			@include transition(all .3s);
			&:hover{
				border: 2px solid $main_two;
				@include transition(all .3s);
			}
		}
		a {
			color: #929caa;
			font-size: 16px;
			display: block;
			@include transition(all .3s);
			&:hover{
				color: $main_two;
			}

		}
	}
}
.footer-menu{
	ul {
		li {
			margin-bottom: 10px;
			padding-bottom: 5px;

			a {
				font-size: 15px;
				color:#67778e;
				@include font-three-4;
				letter-spacing: .3px;
				font-weight: 600;
			}
		}
	}
}
.footer-widget {
	padding-bottom: 50px;
	img{
		margin-right: 20px;
	}
}

.footer_long_text{
	img{
		float: left;
		margin-right: 40px;
	}
	p{
		font-size: 14px;
		line-height: 30px;
		color: #67778e;
		@include font-three-3;
		font-style: italic;
		font-weight: 600;
		letter-spacing: .5px;
		margin-bottom: 0;

	}
}


.footer-subscribe-wrapper{
	input{
		width: 100%;
		border: 1px solid #ddd;
		height: 45px;
		margin-bottom: 15px;
		padding-left: 21px;
	}
	button{
		width: 100%;
		border: 1px solid #ddd;
		height: 45px;
		background: #ece7e7;
		font-size: 20px;
		cursor: pointer;
	}

}

.footer-menu ul li a:hover {
	color: $main_two;
}


.footer-address{

	padding-bottom: 40px;
	padding-top: 20px;
	li{

	}
	a{
		font-size: 16px;
		color: #6c798c;
		@include font-three-4;
		padding: 7px 0;
		i{
			margin-right: 15px;

		}
	}

}

.social{
		p{
			font-size: 16px;
			color: $main_two;
			@include font-one-6;
			position: relative;
			margin-bottom: 40px;
			&:after{
				position: absolute;
				background: #56595f;
				height: 1px;
				width: 50px;
				bottom: -7px;
				left: 0;
				content: '';
			}
		}

	}

#subscribe-form {
	margin-top: 35px;
	overflow: hidden;
	margin-bottom: 50px;
	position: relative;
	border: 1px solid rgba(103, 119, 142, .23);
	border-radius: 3px;
	padding-right: 60px;
	input {
		color: #67778e;
		font-size: 14px;
		font-weight: 400;
		height: 55px;
		padding-left: 17px;
		width: 100%;
		border-radius: 3px;
		border: 0;
		font-weight: 600;
	}
	  
	button {
		background: $main_color;
		border: medium none;
		font-size: 15px;
		line-height: 46px;
		position: absolute;
		right: 0;
		top: 0;
		transition: all 0.3s ease 0s;
		width: 64px;
		height: 100%;
		cursor: pointer;
		
		border-radius: 0 3px 3px 0;
		i{
			color: #fff;
		}
	}
}

.payment-link li {
	display: inline-block;
	margin-left: 10px;
}
.footer-bottom {
	background: #e9f1f3;
	padding: 28px 0 20px;
}

.footer_long_text {
	padding-top: 50px;
	border-top: 1px solid #eaeded;
	padding-bottom: 100px;
}
/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/


  .backtotop {
  	position: fixed;
  	bottom: 25px;
  	right: 25px;
  	border: 2px solid #fff;
  	cursor: pointer;
  	border-radius: 50%;
  	z-index: 9999;
  	-webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  	-moz-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  	box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
  }

  .backtotop i {
  	height: 42px;
  	width: 42px;
  	line-height: 42px;
  	font-size: 20px;
  	background: #fff;
  	color: $main_color;
  	text-align: center;
  	border-radius: 50%;
  }
  .payment-link {
	float: right;
}
