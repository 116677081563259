
.single-pricing-four{
	text-align: center;
	box-shadow: 0 0 13px 0 rgba(101, 114, 129, 0.07);
	border-radius: 8px;
	padding: 75px 0;
    background: #fff;
    overflow: hidden;
    position: relative;
    h4{
		font-size: 18px;
		color: $main_three;
		font-weight: 800;
		text-transform: uppercase;
		text-align: center;
        transition: .3s;
        @extend .font-heading;
	}
	h3{
		font-size: 26px;
		color: $main_three;
		text-align: center;
		font-weight: 700;
		margin-top: 10px;
        transition: .3s;
        @extend .font-primary;
    }
	ul{
        max-width: 70%;
        margin: 40px auto;
		li{
            font-size: 15px;
            font-weight: 600;
			color: #67778e;
			text-align: left;
			padding: 13px 30px;
			border-bottom: 1px solid rgba(11, 103, 244, 0.071);
            text-align: center;
            @extend .font-primary;

		}
	}
	.pricing-image{
		text-align: center;
		width: 115px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
	}
	.btn-3{
		margin-top: 24px;
    }
    &:before{
        content: '';
        background-color: rgba(11, 103, 244, 0.071);
        box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1),inset 0px 8px 33.82px 4.18px rgba(221, 234, 255, 0.39);
        width: 343px;
        height: 305px;
        position: absolute;
        top: -47px;
        left: -61%;
        transform: rotate(-44deg);
        border-radius: 30px;
        transition: .3s;
    }
    &:after{
        content: '';
        background-color: rgba(11, 103, 244, 0.071);
        box-shadow: 0px 4px 6.8px 1.2px rgba(13, 43, 134, 0.1),inset 0px 8px 33.82px 4.18px rgba(221, 234, 255, 0.39);
        width: 343px;
        height: 305px;
        position: absolute;
        bottom: 31px;
        right: -68%;
        transform: rotate(-50deg);
        border-radius: 30px;
        transition: .3s;
    }
    .badge{
        @extend .main-two-bg;
        @extend .font-heading;
        position: absolute;
        top: 24px;
        right: -59px;
        padding: 18px 87px;
        font-weight: 800;
        font-size: 12px;
        color: #ffffff;
        transform: rotate(38deg);
        text-transform: uppercase;
    }
    &.active,
    &:hover{
        &:before{
            @extend .main-two-bg;
        }
        &:after{
            @extend .main-two-bg;
        }
        h3,
        h4{
            @extend .main-two;
        }
        .btn-3{
            @extend .main-two-bg;
        }
    }
}

@include mq(1200px, 1449px) {
    .single-pricing-four {
        &:before {
            left: -81%;
        }
        &:after {
            right: -90%;
        }
        .badge {
            top: 17px;
            right: -70px;
        }
    }
}

@include mq(992px, 1199px) {
    .single-pricing-four {
        &:before {
            left: -104%;
            top: -67px;
        }
        &:after {
            right: -110%;
        }
        ul {
            max-width: 82%;
        }
        .badge {
            top: 19px;
            right: -69px;
            padding: 15px 87px;
        }
    }
}

@include mq-max(991px) {
    .single-pricing-four{
        &:before {
            left: -35%;
        }
        &:after {
            right: -40%;
        }
    }
}

@include mq-max(757px) {
    .single-pricing-four{
        &:before {
            left: -48%;
        }
        &:after {
            right: -57%;
        }
    }
}

@include mq-max(575px) {
    .single-pricing-four{
        &:before {
            left: -289px;
        }
        &:after {
            right: -289px;
        }
    }
}