

.single-feature {
	text-align: center;
	padding: 0 40px;
	h5{
		text-transform: uppercase;
		font-weight: 600;
		color: $sub_head;
		margin-bottom: 15px;
		margin-top: 30px;

	}
	p{
		@include font-three-4
		color: $para_color;
		margin-bottom: 17px;
	}
	a{
		font-size: 13px;
		color: $main_color;
		text-transform: uppercase;
		img{
			margin-left: 5px;
		}
	}
	&:hover
		h5{
			color: $main_two;
		}

}
.single-feature:hover a {
	color: #00c1ff;
}

.feature_1{
	position: relative;
}

@include mq(1200px, 1439px) {
	.single-feature {
		padding: 0; 
	}
}

@include mq(992px, 1199px) {
	.single-feature {
		padding: 0; 
	}
}

@include mq-max(991px) {
	.single-feature {
		padding: 0; 
	}
}