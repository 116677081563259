
/*--------------------------------------------------------------
  ##  Blog  2
  --------------------------------------------------------------*/

.blog_2{
    background: #fdfdff;
}
.single-blog-2{
    .sin-blog-content{
        padding: 25px 0 0;
        .entry-content{
            p{
                color: #808c9e;
                font-size:14px;
                @include font-three-6;
            }
            a{
                color: #808c9e;
                &:hover{
                    color:#16a929;
                }

            }
        }
        .cat-links{
            margin-bottom:10px;
            a{
                color: #4287ff;
                font-size: 14px;
                @include font-one-6;
                &:hover{
                    color:#16a929;
                }
            }
        }
        .entry-title{
            margin-bottom: 12px;
            font-size: 20px;
            color: #52575f;
            @include font-one-4;
            font-weight: 700;
            a{
                color: $blog_title;
                &:hover{
                    color:#16a929;
                }
            }

        }
        .meta{
            a{
                color: #2a384c;
                font-size: 13px;
                @include font-three-6;

                i{
                    margin-right: 5px;

                }
                &:hover{
                    color:#16a929;
                }
            }
        }
        > a{
            font-size: 14px;
            color:  #0b67f4;
            @include font-one-6;
            text-transform: capitalize;

            &:hover{
                color:#16a929;
            }
        }
    }
    > a{
        box-shadow: 0px 1px 3.8px 0.2px rgba(105, 105, 105, 0.09);
        border: 4px solid #fff;
    }

}

@include mq-max(757px) {
    .single-blog-2 .sin-blog-content {
        padding: 20px 0 0;
    }
}
