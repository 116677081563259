.main-color{
    color: $main_color;
}

.main-color-bg{
    background-color: $main_color;
}
.main-color-border{
    border-color: $main_color;
}

// Main Two
.main-two{
    color: $main_two;
}

.main-two-bg{
    background-color: $main_two;
}
.main-two-border{
    border-color: $main_two;
}

// Main Three
.main-three{
    color: $main_three;
}

.main-three-bg{
    background-color: $main_three;
}
.main-three-border{
    border-color: $main_three;
}

// Main four
.main-four{
    color: $main_four;
}

.main-four-bg{
    background-color: $main_four;
}
.main-four-border{
    border-color: $main_four;
}