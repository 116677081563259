.how-it-work{
    padding: 120px 0;
}
.working-items{
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    &:before{
        position: absolute;
        content: '';
        top: 58px;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 92%;
        border: 1px dashed #d7d7d7;
    }
    li{
        text-align: center;
        position: relative;
        z-index: 3;
        width: 100%;
        max-width: 25%;
        .icon{
            width: 120px;
            height: 120px;
            line-height: 115px;
            border: 1px solid rgb(215, 215, 215);
            box-shadow: 0px 11px 15.3px 2.7px rgba(13, 43, 134, 0.15);
            border-radius: 50%;
            background: #fff;
            margin-left: auto;
            margin-right: auto;
        }
        h4{
            @extend .font-heading;
            font-weight: 700;
            font-size: 18px;
            text-transform: uppercase;
            color: #2a384c;
            margin-top: 50px;
            margin-bottom: 0;
        }
        &:hover{
            .icon{
                border-color: $main-two;
            }
            h4{
                @extend .main-two;
            }
        }
    }
}

@include mq-max(991px) {
    .working-items{
        margin-top: -70px;
        &:before{
            content: none;
        }
        li{
            max-width: 50%;
            margin-top: 50px;
            .icon{
                width: 100px;
                height: 100px;
                line-height: 92px;
                img{
                    width: 35px;
                }
            }
            h4{
                margin-top: 27px;
            }
        }
    }
}

@include mq-max(575px) {
    .working-items{
        li{
            max-width: 100%;
        }
    }
}
