.client_slider{
	background: #2a384c;
}
.client-slider{
	.item {
		text-align: center;
		img{
			display: block;
			width: auto;
			margin: 0 auto;
			filter:  brightness(0) invert(1)
		}
	}
}
