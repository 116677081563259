.single-feature-style-4{
	position: relative;
	z-index:1;
	padding-left: 15px;
	padding-right: 7px;
	.inner-wrapper{
		box-shadow: 0px 1px 13.6px 2.4px rgba(100, 100, 100, 0.1);
		background: #fff;
		border-radius: 10px;
		padding: 25px 35px;
	}

	i{
		font-size: 44px;
		color: $main_color;
		margin-bottom: 14px;
		display: inline-block;
	}
	h4{
		font-size: 18px;
		color: #404a59;

	}
	p{
		font-size: 14px;
		color: #808c9e;
		@include font-three-6;

	}
	&:before{
		content: '';
		position: absolute;
		left: -5px;
		top: -20px;
		width: 90%;
		height: 100%;
		background: #ecf2fa;
		z-index: -6;
		border-radius: 10px;
		-webkit-box-shadow: 0px 1px 13.6px 2.4px rgba(100, 100, 100, 0.1);
		box-shadow: 0px 1px 13.6px 2.4px rgba(100, 100, 100, 0.1);
		transition: all .3s;
	}
	&:hover{
		i{
			color: $main_two;
		}
		h4{
			color: $main_two;

		}
		p{
			color: #808c9e;

		}
		&:before{

			@extend .main-four-bg;

		}

	}
}


.Rectangle_2 {
	border-radius: 4px;
	background-color: rgb(252, 254, 255);

	position: absolute;
	left: 394px;
	top: 830px;
	width: 352px;
	height: 220px;
	z-index: 227;
}
