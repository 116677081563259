.single-number{
	text-align: center;
	box-shadow: 0px 1px 11.05px 1.95px rgba(100, 100, 100, 0.05);
	background: #fff;
	padding: 50px 20px;
	.icon{

		width: 75px;
		height: 92px;
		margin: 0 auto;
		margin-bottom: 25px;
	}
	h5{
		color: #17b212;
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;
		font-size: 36px;
		position: relative;
		display: inline-block;
	}
	h5.sec-color{
		color: $main_color;
	}
	p{
		font-size: 15px;
		line-height: 23px;
		font-weight: 600;
		color: #67778e;
	}
}

.single-number h5::after {
	content: '+';
	position: absolute;
	right: -22px;
	top: 0px;
}


.single-number-two{
	position:relative;
	padding: 50px;
	text-align: center;
	h5{
		color: $main_color;
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		font-size: 50px;
		position: relative;
		display: inline-block;
	}

	p{
		color: #2a384c;
		@include font-one-7;
		font-size: 16px;
	}
	&:after{
		position: absolute;
		width: 1px;
		height: 80px;
		right: 0;
		top: 50%;
		content: '';
		background: url(../../media/images/icons/seperator.png);
		transform: translateY(-50%);


	}
}
.single-number-two.border-remove{
	&:after{
		display: none;


	}

}

.single-number h5::after {
	content: '+';
	position: absolute;
	right: -22px;
	top: 0px;
}

.border-top2{
	.container{
		border-top: 1px solid #eef4fd;
	}
}

.number-2 {
	padding-bottom: 50px;
}

@include mq(1200px, 1429px) {
	
}


@include mq-max(1199px) {
	.single-number-two {
		padding: 20px;
		h5{
			font-size: 44px;
			margin-bottom: 6px;
		}
	}
}

@include mq-max(575px) {
	.single-number-two:after {
		width: 80%;
		height: 1px;
		right: 50%;
		top: inherit;
		bottom: 0;
		transform: translateX(50%);
		border: 1px solid #197beb;
		opacity: .1;
	}
}