.slider-wrapper {
	.item {
		background-size: cover!important;
		min-height: 100vh
	}
}

.slider-text{
	h1{
		color: $main_color;
		margin-bottom: 10px;
		font-size:60px;
		line-height: 70px;
		@include  font-two-7;
		font-weight: 400;
		span{
			display: block;
			font-weight: 700;
		}

	}
	h4{
		font-size: 60px;
		color: $main_color;
		margin: 0;
		@include  font-two-4 ;
	}
	p {
		font-size: 20px;
		margin-bottom: 25px;
		color: #197beb;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		line-height: 40px;
		margin-top: 10px;
		max-width: 519px;
	}


}
a.btn-one{
	font-size: 20px;
	color: #fff;
	background: #3f3f3f;
	padding: 13px 44px;
	border-radius: 29px;
	margin-top: 10px;
	&:hover{
		background: $main_color;
	}
}

.slider-img {
	padding-top: 60px;
	text-align: center !important;
}
.owl-carousel .owl-item .slider-img img{
	max-width: 100%;
	width:auto;
}
.slider-wrapper .owl-theme .owl-dots .owl-dot.active span {
	width: 10px;
	height: 32px;
}

.slider-wrapper .owl-theme .owl-dots .owl-dot span {
	width: 16px;
	margin: 0px 4px;
	background: #1d1b1b;
	display: inline-block;
	border-radius: 3px;
	width: 10px;
	height: 14px;
}
.slider-wrapper .owl-theme .owl-dots .owl-dot {
	display: block;
}
.slider-wrapper .owl-theme .owl-nav + .owl-dots {
	margin-top: 10px;
	position: absolute;
	top: 50%;
	right: 150px;
}

.delay {

	-webkit-animation-delay: .1s;
	-moz-animation-delay: .1s;
}
.slider-text{
	h4{

		-webkit-animation-delay: .3s;
		-moz-animation-delay: .3s;
	}
	h1{

		-webkit-animation-delay: .5s;
		-moz-animation-delay: .5s;

	}
	p{

		-webkit-animation-delay: .7s;
		-moz-animation-delay: .7s;

	}
	a{

		-webkit-animation-delay: .8s;
		-moz-animation-delay: .8s;

	}
}
.slider-one{
	.slider-text{
		padding: 275px 0;
	}
}

/* ----------------------------------------------------------------
	Slider Style Two
-----------------------------------------------------------------*/


a.btn-one {
	font-size: 18px;
	color: $main_color;
	background: transparent;
	padding: 13px 28px;
	margin-top: 10px;
	text-transform: capitalize;
	border : 1px solid $main_color;
	border-radius: 50px;
	@include font-two-4;
	margin-right: 10px;
	box-shadow: 0.968px 3.881px 8.19px 0.81px rgba(109, 109, 109, 0.26);
	&:hover{

		background: $main_color;
		color:  #fff;
	}

}

a.btn-two {
	font-size: 18px;
	color: #fff;
	background: $main_color;
	padding: 13px 28px;
	margin-top: 10px;
	text-transform: capitalize;
	border : 1px solid $main_color;
	border-radius: 50px;
	@include font-two-4;
	margin-right: 10px;
	box-shadow: 0.968px 3.881px 8.19px 0.81px rgba(109, 109, 109, 0.26);
	&:hover{

		background: $main_color;
		color:  #fff;
	}

}





/* ----------------------------------------------------------------
	Slider Style Four
-----------------------------------------------------------------*/

.slider-four {
	margin-top: -100px;
	.slider-text{
		padding: 320px 0 256px;
	}
}

@include mq(1200px, 1423px) {
	.slider-one {
		.slider-text {
			padding: 200px 0 205px;
		}
	}
	.slider-four {
		.slider-text {
			padding: 256px 0 185px;
		}
	}
}

@include mq(992px, 1199px) {
	.slider-one {
		.slider-text {
			padding: 135px 0 140px;
			h1{
				font-size: 50px;
				line-height: 60px;
			}
		}
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		right: 90px;
	}

	// slider four
	.slider-four {
		.slider-text {
			padding: 266px 0 199px;
			h1{
				font-size: 50px;
				line-height: 60px;
			}
		}
	}
}

@include mq-max(991px) {
	.slider-one {
		.slider-text {
			padding: 135px 0 140px;
			h1{
				font-size: 50px;
				line-height: 60px;
			}
		}
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		right: 90px;
	}
	// slider four
	.slider-four {
		.slider-text {
			padding: 288px 0 180px;
			h1{
				font-size: 40px;
				line-height: 50px;
			}
			p {
				font-size: 18px;
				margin-bottom: 18px;
				line-height: 34px;
			}
			a{
				font-size: 16px;
			}
		}
	}
}

@include mq-max(767px) {
	.slider-one {
		.slider-text {
			padding: 135px 0 140px;
			h1{
				font-size: 39px;
				line-height: 48px;
			}
			p{
				font-size: 18px;
				line-height: 29px;
				margin-bottom: 18px;
			}
			.btn-two,
			.btn-one {
				font-size: 15px;
				padding: 11px 24px;
			}
		}
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		right: 30px;
	}
	// slider four
	.slider-four {
		.slider-text {
			padding: 288px 0 180px;
			h1{
				font-size: 35px;
				line-height: 45px;
			}
			p {
				font-size: 16px;
				margin-bottom: 18px;
				line-height: 30px;
				margin-top: 15px;
			}
			a{
				font-size: 14px;
				padding: 12px 22px;
			}
		}
	}
}
@include mq-max(575px) {
	.slider-one {
		.slider-text {
			// padding: 135px 0 140px;
			h1{
				font-size: 29px;
				line-height: 39px;
			}
			p{
				font-size: 15px;
				line-height: 29px;
				margin-bottom: 18px;
			}
			.btn-two,
			.btn-one {
				font-size: 14px;
				padding: 10px 21px;
			}
		}
	}
	.slider-wrapper .owl-theme .owl-nav + .owl-dots {
		display: none;
	}
	// slider four
	.slider-four {
		.slider-text {
			padding: 239px 0 152px;
			h1{
				font-size: 25px;
				line-height: 35px;
			}
			p {
				font-size: 15px;
				margin-bottom: 18px;
				line-height: 30px;
				margin-top: 15px;
			}
			a{
				font-size: 14px;
				padding: 9px 22px;
			}
		}
	}
}

