.slider-image {
	width: 100%;
	max-width: 50%;
}

.single-feature-slider{
	align-items: center;
	display: flex;
	justify-content: space-between;
	height: 490px;
	.box{
		padding: 50px;
		width: 100%;
		border: 1px solid #f3f3f3;
		margin-bottom: 5px;
		border-radius: 26px;
		margin-right: 6px;
		h4{
			font-size:24px;
			@include font-one-7;
			color: $sub_head;
			text-transform: uppercase;
			span{
				color: $main_two!important;

			}

		}
		p{


		}
		ul li{
			font-size: 16px;
			padding: 5px 0;
			color: #5f7492;
			padding-left: 25px;
			position: relative;

			&:before{
				content: '\f00c';
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				left: 0;
				color: $main_two;
				position: absolute;
			}

		}

	}
}
.feature-slider-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-feature {
	width: 100%;
	max-width: 50%;
}

.fix-box {
	background-repeat: no-repeat!important;
	background-attachment: fixed!important;
	background-position: 50% 50%!important;
	vertical-align: top;
	padding: 800px 30px 30px 30px;
}

.bg-1{
	background: #f8f8f8;
}
.bg-2{
	background: #fff;
}


.single-feature-slider-wrapper {
	width: 50%;
	float: left;
}