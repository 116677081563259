.dot {
	height: 13px;
	width: 13px;
	border-radius: 50%;
	background: #197beb;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
}
.dot-circle {
	width: 34px;
	height: 34px;
	background-image: url(../../media/images/icons/marrow.png);
	background-size: 100%;
	background-position: center;
	position: absolute;
    left: 12%;
    top: 25%;
	background-repeat: no-repeat;
	transform: rotate(360deg);


}
.dot-circle::after {
	content: '1';
	background: #fff;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 32px;
	text-align: center;
	font-size: 11px;
	line-height: 20px;
	border-radius: 50px;
	top: -17px;
	transition: all .8s;
}


.map-area .tab-content > .tab-pane {
	display: block;
	opacity: 1;
}
.map-area {
	width: 60%;
	float: left;
	position: relative;
}
.map-location {
	width: 40%;
	float: left;
	box-shadow: -1.414px -1.414px 5px 0px rgba(102, 101, 101, 0.07);
	height: 406px;
	overflow-y: scroll;
}
.map-location .nav-tabs {
	border-bottom: none;
	background: #fff;
}
.map-location .nav-tabs .nav-item {
	margin-bottom: -1px;
	width: 100%;
	border-top: 1px solid #efefef;
	position: relative;
}
.map-location .nav-tabs .nav-item .nav-link {
	border: none;
	border-radius: 0;
	border-top: 5px solid #fff;
	padding: 19px 21px 10px;
}
.map-location .nav-tabs .nav-link.active{
	border: none;
	border-top: 5px solid $main_two;

}

.map-location .nav-tabs .nav-item a span{
	@extend .main-color;
	font-size: 18px;
	@include font-one-6;
}
.map-location .nav-tabs .nav-item a p{
	color: #777777;
	font-size: 16px;
	@include font-three-4;
}

.map-location .nav-tabs .nav-item a:hover p{
	color: #777777;
}

.map-location .nav-tabs .nav-link:hover span {
	color: #197beb;
}

.map-location .nav-tabs .nav-item a:hover span {
	color:  $main_two;
}

.number {
	position: absolute;
	right: 25px;
	top: 37%;
	color: #1772ff;
}

.map-location .nav-tabs .nav-link.active span{
	color: $main_two;
}


#scroll-map::-webkit-scrollbar{
width:16px;
background-color:red;
}

.dot-circle.num-2 {
	left: 50%;
}

.active  .dot-circle {

	background-image: url(../../media/images/icons/marrow2.png);
	transform: rotate(0deg);
	@include transition(all .8s);

}
.active  .dot-circle .dot {

	background: $main_two;

}
.dot-circle.num-4 {
    top: 27%;
    left: 75%;
}
.dot-circle.num-1 {
    left: 28%;
    top: 70%;
}
.dot-circle.num-2 {
	left: 56%;
	top: 24%;
}
.dot-circle.num-5 {
	left: 32%;
	top: 9%;
}
.dot-circle.num-6 {
	left: 79%;
    top: 75%;
}
.dot-circle.num-1::after {
	content: '2';

}
.dot-circle.num-2::after {
	content: '3';

}
.dot-circle.num-4::after {
	content: '4';

}
.dot-circle.num-5::after {
	content: '5';

}

/* .active  .dot-circle::after {
	background: #17b212;
	color: #fff;
	@include transition(all .8s);
} */


@include mq(1200px, 1429px) {
	.map-location .nav-tabs .nav-item a {
		span {
			font-size: 16px;
		}
		p{
			font-size: 15px;
		}
	}
}

@include mq(992px, 1199px) {
	.map-location {
		height: 314px;
		.nav-tabs {
			.nav-item {
				a {
					span {
						font-size: 16px;
					}
					p{
						font-size: 15px;
					}
				}
			}
			.nav-link.active {
				border-top-width: 3px;
			}
		}
	}
}
@include mq-max(991px) {
	.map-location {
		height: 297px;
		width: 100%;
		.nav-tabs {
			.nav-item {
				a {
					span {
						font-size: 16px;
					}
					p{
						font-size: 15px;
					}
				}
			}
			.nav-link.active {
				border-top-width: 3px;
			}
		}
	}
	.map-area{
		width: 100%;
	}
}