.bb-breadcrumb{
	background: #fff;
	padding-top: 370px;
	margin-bottom: 0;
	background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
	h2{
		font-size: 60px;
		color: $main_color;
		@include font-three-8;
		span{
			font-weight: 400;

		}
	}
	.des {
		font-weight: 400;
		@extend .font-heading;
		max-width: 500px;
		font-size: 18px;
		margin-bottom: 0;
	}
	.breadcrumb-inner{
		padding-top: 200px;
		padding-bottom: 17px;
		p{
			font-size: 16px;
			@include font-three-6;
			color: $main_two;
			a{
				color: $main_color;
				margin-right: 8px;
			}
		}
	}
}

@include mq-max(991px) {
	.bb-breadcrumb {
		padding-top: 300px;
		h2 {
			font-size: 47px;
		}
		.breadcrumb-inner {
			padding-top: 100px;
		}
	}
}
@include mq-max(575px) {
	.bb-breadcrumb {
		padding-top: 270px;
		h2 {
			font-size: 34px;
		}
	}
}