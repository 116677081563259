.plan-1 {
    background-image: url(../../media/images/bg/line_bg2.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;

    .block-content {
		max-width: 620px;
    }
}

/* --------------------------------------------------
            ==  Progressbar Part  ==
--------------------------------------------------- */


.six-sec-ease-in-out {
    -webkit-transition: width 6s ease-in-out;
    -moz-transition: width 6s ease-in-out;
    -ms-transition: width 6s ease-in-out;
    -o-transition: width 6s ease-in-out;
    transition: width 6s ease-in-out;
}

.progress {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c5effd+0,ffffff+100 */
    background: rgb(197, 239, 253);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(197, 239, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(197, 239, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(197, 239, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c5effd', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */
}

.progress {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 5px;
    box-shadow: 0px 1px 11.05px 1.95px rgba(13, 37, 110, 0.06);
}

.progress.vertical {
    width: 16px;
    height: 360px;
    float: right;

}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #304efd;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.sin-prog-bar {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 64px;
    margin-right: 16px;
}

.sin-prog-bar span {
    position: absolute;
    left: -55px;
    bottom: 78px;
    transform: rotate(-90deg);
    font-size: 15px;
    color: #6e8394;
    font-weight: 400;
    width: 133px;
    display: inline;
    text-transform: uppercase;
}

.bar-wrap {
    margin-top: 60px;
    display: none;

    h6 {
        font-size: 15px;
        color: #3263f9;
        margin-bottom: 100px;
        text-transform: uppercase;
    }
}

.bar-wrap.active {
    display: block;
}


.toggle-btn-1 {
    width: 120px;
    height: 35px;
    border-radius: 50px;
    position: relative;
    border: 4px solid #fff;
    cursor: pointer;
    -webkit-transition: background-color .40s ease-in-out;
    -o-transition: background-color .40s ease-in-out;
    transition: background-color .40s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-align: right;
    background: #0b69ec;
    text-align: center;
    box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28), inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);

    .round-btn {
        width: 22px;
        height: 22px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 78%;
        top: 17px;
        margin-top: -15px;
        -webkit-transition: all .30s ease-in-out;
        -o-transition: all .30s ease-in-out;
        transition: all .30s ease-in-out;
    }

    .round-btn.active {
        left: 2px;
    }

    .cb-value {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 9;
        cursor: pointer;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    p {
        font-size: 11px;
        color: #fff;
        display: inline;
        line-height: 0;
        text-transform: uppercase;
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 14px;
        width: 100%;
        @include transition(all .3s);
    }

    p.active {
        visibility: visible;
        opacity: 1;
        @include transition(all .3s);
    }
}

.planning-table>span {
    color: #4c83d9;
    float: left;
    margin-top: 7px;
}

.planning-table-inner .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    border-color: transparent;
    overflow: hidden;
    width: 100%;
    background-image: url(../../media/images/bg/plan-btn-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
	background-position: top;
	display: flex;
    justify-content: space-between;
}

.planning-table-inner .nav-tabs .nav-item.show .nav-link,
.planning-table-inner .nav-tabs .nav-link.active {
    border-color: transparent;
    background: transparent;
}

.planning-table-inner .nav-tabs .nav-link:focus,
.planning-table-inner .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.planning-table-inner {
    margin-top: 30px;

}

.planning-table .nav-item span {
    margin-top: 32px;
    color: #c6cace;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    float: left;
}

.planning-table {
	box-shadow: 0px 0px 24px 0px rgba(78, 91, 107, 0.1);
    border-radius: 5px;
    background: #fff;
    padding: 50px;
	.nav-item {
		.active {
			span {
				color: #5c9cfd;
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.nav-link {
		display: block;
		padding: .5rem 0;
		text-align: left;
		position: relative;
		height: 100%;
	}
}


.planning-table-inner .nav-tabs .nav-link.active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 20px;
    height: 20px;
    @include transition(all .3s);
    background-image: url(../../media/images/icons/button.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateX(-50%);
}

.plannig-table-content {
    text-align: center;
    margin-top: 30px;

    ul {
        margin-bottom: 36px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 30px;

        li {
            color: #8198aa;
            font-size: 14px;
            margin-bottom: 20px;
            text-align: left;

            span {
                float: right;
            }

            i {
                float: left;
                margin-right: 10px;
            }

            &:hover {
                color: #085ddf;
            }
        }
    }

    >span {
        color: #7f8793;
        font-size: 12px;
        @include font-one-4;
        text-align: center;
        display: block;
    }

    .btn-3 {
        margin-top: 25px;
    }
}

.total {
    text-align: center;
    font-size: 20px;
    @include font-three-7;
    color: #3263f9;

    span {
        font-size: 14px;
        color: #8198aa;
    }

    display: none;
}

.total.active {
    display: block;
}

@include mq(1200px, 1429px) {
    .sin-prog-bar {
        width: 59px;
        margin-right: 13px;

        span {
            left: -58px;
        }
    }
}
@include mq-max(767px) {
	.sin-prog-bar {
		width: 42px;
		margin-right: 15px;
	}
	.progress.vertical {
		width: 11px;
		height: 260px;
		margin-right: 3px;
	}
}

@include mq-max(575px) {
	.planning-table {
		padding: 30px 20px;
	}
	.sin-prog-bar {
		width: 25px;
		margin-right: 6px;
		span{
			font-size: 13px;
		}
	}
	.progress.vertical {
		width: 3px;
		height: 260px;
		margin-right: 0px;
	}
}