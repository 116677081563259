/*!
  Theme Name: Comercio
  Theme URI: themeim.com
  Author: ThemeIM
  Description: Hosting Providers
  Version: 1.0.0
  Tags: Domain, Hosting, vps hosting, wordpress hosting
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Mobile Menu
    ## Dropdown menu
    ## Mega menu area
    ## Header 2
    ## Navbar Three
    ## Slider
    ## Slider Style two
    ## Slider Style three
    ## Product Banner
    ## Product Filter
    ## Product Filter Style 2
    ## Product three
    ## Features
    ## Banner and  Product area
    ## Banner and  Product area 2
    ## Countdown
    ## Small Product
    ## category
    ## logo_carousel
    ## testimonial
    ## breadcrumb
    ## Shop Page
    ## Product Page
    ## Cart Page
    ## Blog Page
    ## Blog sidebar
    ## Contact page
    ## Quickview
    ## Starting popup news letter
    ## Subscribe
    ## Instagram
    ## Footer
    ## Footer style 2
    ## Backtotop
    ## Responsive




  --------------------------------------------------------------*/


@import "base/base-dir";
@import "elements/elements";

