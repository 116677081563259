$font_primary: 'OpenSans', sans-serif;
$font_heading: 'Montserrat', sans-serif;


.font-primary{
    font-family: $font_primary;
}

.font-heading{
    font-family: $font_heading;
}