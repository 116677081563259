/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "base/header-dir";



/*--------------------------------------------------------------
  ##  Slider
  --------------------------------------------------------------*/
@import "slider";
@import "banner_5";

/*--------------------------------------------------------------
  ##  Breadcrumb
  --------------------------------------------------------------*/
@import "breadcrumb";


/*--------------------------------------------------------------
  ##  Feature 1
  --------------------------------------------------------------*/
@import "feature_1";


/*--------------------------------------------------------------
  ##  Feature 2
  --------------------------------------------------------------*/
@import "feature_2";


/*--------------------------------------------------------------
  ##  Feature 4
  --------------------------------------------------------------*/
@import "feature_4";

/*--------------------------------------------------------------
  ##  Video  1
  --------------------------------------------------------------*/
@import "video_1";

/*--------------------------------------------------------------
  ##  Success Nimber 1
  --------------------------------------------------------------*/
@import "succ_number_1";


/*--------------------------------------------------------------
  ##  Pricing 1
  --------------------------------------------------------------*/
@import "pricing_1";

/*--------------------------------------------------------------
  ##  Pricing  2
  --------------------------------------------------------------*/
@import "pricing_2";

/*--------------------------------------------------------------
  ##  Pricing  4
  --------------------------------------------------------------*/
  @import "pricing_4";

/*--------------------------------------------------------------
  ##  Slider Feature
  --------------------------------------------------------------*/
@import "slider_feature";


/*--------------------------------------------------------------
  ##  Data center
  --------------------------------------------------------------*/
@import "data_center";



/*--------------------------------------------------------------
  ##  Testimonial 1
  --------------------------------------------------------------*/
@import "testimonial_1";


/*--------------------------------------------------------------
  ##  Testimonial 2
  --------------------------------------------------------------*/
@import "testimonial_2";


/*--------------------------------------------------------------
  ##  Client 1
  --------------------------------------------------------------*/
@import "client_1";

/*--------------------------------------------------------------
  ##  About 1
  --------------------------------------------------------------*/
@import "about";

/*--------------------------------------------------------------
  ##  Service 1
  --------------------------------------------------------------*/
@import "service_1";

/*--------------------------------------------------------------
  ##  Service 2
  --------------------------------------------------------------*/
@import "service_2";


/*--------------------------------------------------------------
  ##  Widget
  --------------------------------------------------------------*/
@import "widgets";



/*--------------------------------------------------------------
  ##  Feature 2
  --------------------------------------------------------------*/
@import "feature_3";

/*--------------------------------------------------------------
  ##  Feature tab Plan
  --------------------------------------------------------------*/
  @import "feature_tab_plan";

/*--------------------------------------------------------------
  ##  Blog 1
  --------------------------------------------------------------*/
@import "blog_1";

/*--------------------------------------------------------------
  ##  Blog 2
  --------------------------------------------------------------*/
  @import "blog_2";

  /*--------------------------------------------------------------
  ##  Blog List
  --------------------------------------------------------------*/
  @import "blog_list";

  /*--------------------------------------------------------------
  ##  Blog Detail
  --------------------------------------------------------------*/
  @import "blog_detail";

/*--------------------------------------------------------------
  ##  Plan 1
  --------------------------------------------------------------*/
@import "plan_1";


/*--------------------------------------------------------------
  ##  Sticky area css
  --------------------------------------------------------------*/
@import "sticky_area";

/*--------------------------------------------------------------
  ##  Process  one
  --------------------------------------------------------------*/
@import "process_1";


/*--------------------------------------------------------------
  ##  Link area
  --------------------------------------------------------------*/
@import "link";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";

/*--------------------------------------------------------------
  ##  Footer Two
  --------------------------------------------------------------*/
@import "footer_2";


@import "inner";

@import "contact";

@import "how_it_work";

@import "faq_1";