.service-1{
	.inner-content-box{
		padding-left: 25px;
	}
	.btn-3{
		margin-top: 25px;
	}
}
.single-service-wrap{
	display: flex;
	flex-wrap: wrap;
	margin: -20px -10px 0;
	.single-service-col{
		width: 100%;
		max-width: 50%;
		padding: 0 10px;
		padding-top: 20px;
	}
}
.single-service {
	text-align: center;
	background: #fff;
	padding: 30px 20px 25px;
	box-shadow: 0px 0px 18px 0px rgba(78, 91, 107, 0.09);
	h6{
		font-size: 18px;
		color: #2a384c;
		position: relative;
		margin-bottom: 13px;
		margin-top: 20px;
		padding-bottom: 15px;
		&:hover{
			color: #0b67f4;
		}
		&:after{
			content: '';
			position: absolute;
			left: 50%;
			bottom: 0px;
			width: 40px;
			height: 2px;
			background: #a7bdff;
			transform: translateX(-50%);
		}
	}
	p{
		font-size:14px;
		color: $para_color_2;
		line-height: 26px;
		font-weight: 500;
	}

}

.btn-3 {
	font-size: 15px;
	color: #fff;
	padding: 7px 22px;
	border-radius: 26px;
	font-weight: 500;
	z-index: 50;
	background: #3263f9;
	text-transform: uppercase;
	border: 3px solid #f7fbfe;
	box-shadow: 0px 1px 7.6px 0.4px rgba(105, 105, 105, 0.28),inset 0px 2px 11.57px 1.43px rgba(13, 149, 31, 0.2);

}


.service_4 .single-service{
	width: 100%;
}

@include mq-max(1429px) {
	.service-1 .inner-content-box {
		padding-left: 0px;
	}
}

@include mq-max(1199px) {
	.service-1 .inner-content-box {
		padding-bottom: 60px;
	}
}

@include mq-max(575px) {
	.single-service-wrap .single-service-col {
		max-width: 100%;
	}
}