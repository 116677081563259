.blog-starter {
	margin: 0 -15px;
	.item-wrap{
		padding: 0 15px;
	}
}
.play-button{
	a{
		background: #0b67f4;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		text-align: center;
		line-height: 62px;
		color: #fff;
	}
}


.banner-ico {
	position: absolute;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
	animation: bounce 1s ease-in-out 0s infinite alternate;
}

.signal {
	width: 90px;
	pointer-events: none;
	height: 90px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	opacity: 0;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	background-color: #0b67f4;
	margin-left: -45px;
	margin-top: -45px;
	z-index: -5;
}
.signal {
	-webkit-animation: animationSignal cubic-bezier(0,.55,.55,1) 2s;
	animation: animationSignal cubic-bezier(0,.55,.55,1) 2s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-delay: .78s;
	animation-delay: .78s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
.signal2 {
	width: 90px;
	pointer-events: none;
	height: 90px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	opacity: 0;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	background-color: #0b67f4;
	margin-left: -45px;
	margin-top: -45px;
	z-index: -5;
}
.signal2 {
	-webkit-animation: animationSignal cubic-bezier(0,.55,.55,1) 2s;
	animation: animationSignal cubic-bezier(0,.55,.55,1) 2s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-delay: .7s;
	animation-delay: .7s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;

}
.play-button {
	position: relative;
	width: 63px;
	float: left;
}

.video-area{
	margin-top: 40px;
	margin-left: 12px;

}

.video-title {
	margin-top: 19px;
	display: inline-block;
	margin-left: 16px;
	font-size: 14px;
	color: #0b67f4;
	@include font-one-6;
}

@-webkit-keyframes animationSignal {
	0% {
		opacity:0;
		-webkit-transform:scale(0);
		transform:scale(0)
	}
	1% {
		opacity:.14
	}
	20% {
		opacity:.14
	}
	60% {
		-webkit-transform:scale(1);
		transform:scale(1);
		opacity:0
	}
}

@keyframes animationSignal {
	0% {
		opacity:0;
		-webkit-transform:scale(.5);
		transform:scale(.5)
	}
	1% {
		opacity:.14
	}
	20% {
		opacity:.14
	}
	60% {
		-webkit-transform:scale(1);
		transform:scale(1);
		opacity:0
	}
}

@-webkit-keyframes animationSignal2 {
	0% {
		opacity:0;
		-webkit-transform:scale(0);
		transform:scale(0)
	}
	1% {
		opacity:.29
	}
	20% {
		opacity:.29
	}
	60% {
		-webkit-transform:scale(1);
		transform:scale(1);
		opacity:0
	}
}
@keyframes animationSignal2 {
	0% {
		opacity:0;
		-webkit-transform:scale(0);
		transform:scale(0)
	}
	1% {
		opacity:.29
	}
	20% {
		opacity:.29
	}
	60% {
		-webkit-transform:scale(1);
		transform:scale(1);
		opacity:0
	}
}


.single-blog{
	box-shadow: 0px 0px 16px 0px rgba(57, 68, 81, 0.07);
	padding-bottom:20px;
	.sin-blog-content{
		padding: 25px;
		.cat-links{
			margin-bottom:10px;
			a{
				color: #0b67f4;
				font-size: 14px;
				@include font-one-6;
				&:hover{
					color:#16a929;
				}
			}
		}
		.entry-title{
			margin-bottom:12px;
			font-size:20px;
			color: $blog_title;
			@include font-one-7;
			a{
				color: $blog_title;
				&:hover{
					color:#16a929;
				}
			}

		}
		.meta{
			margin-bottom:20px;
			@include font-three-3;
			a{
				color: #5b6470;
				font-size: 14px;
				@include font-three-6;
				margin-right: 10px;
				i{
					margin-right: 5px;

				}
				&:last-child{
					margin-right: 0;
				}
				&:hover{
					color:#16a929;
				}
			}
			a:first-child{
				padding-left: 0;
			}
		}
		> a{
			font-size: 14px;
			color:  #0b67f4;
			@include font-one-6;
			text-transform: capitalize;
			&:hover{
				color:#16a929;
			}
		}
	}

}
@include mq-max(575px) {
	.single-blog .sin-blog-content .meta a {
		font-size: 13px;
	}
	.single-blog .sin-blog-content {
		padding: 14px 20px;
	}
}