.testimonial_1{
	position: relative;
}



.single-testimonial{
	text-align: center;
	padding: 0 40px;
	p{
		color: #6c798c;
		font-size: 15px;
		font-style: italic;
		@include font-three-6;
		margin-bottom: 17px;
		margin-top: 10px;

	}
	.designation{
		margin-top: 0;
		line-height: 20px;
	}
	span{
		font-size: 14px;
		color: $main_two;
		@include font-three-6;
		position: relative;
		margin-left: 26px;
		&:before{
			position: absolute;
			content: '';
			top: 10px;
			left: -25px;
			width: 18px;
			height: 1px;
			background: #aac8d2;
		}
	}
}

@include mq-max(1429px) {
	.single-testimonial {
		padding: 0 20px;
	}
}


@include mq-max(757px) {
	.single-testimonial {
		padding: 0 0px;
	}
}