.hosting_plan_tab{
    .nav-tabs{
        justify-content: center;
        border: 1xp solid #dceafe;
        margin-top: -15px;
        li{
            margin-bottom: 0;
            margin-top: 15px;
            a{
                border-radius: 0;
                background: #fff;
                border: 0;
                box-shadow: 0px 3px 8.5px 1.5px rgba(13, 43, 134, 0.07);
                @extend .font-heading;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 14px;
                color: #2a384c;
                margin-right: 20px;
                padding: 10px 20px;
                &:hover,
                &.active{
                    @extend .main-two-bg;
                    color: #fff;
                }
            }
            &:first-child{
                a{
                    border-radius: 5px 0 0 0;
                    
                }
            }
            
            &:last-child{
                a{
                    border-radius: 0 5px 0 0;
                    margin-right: 0;
                }
            }
        }
    }
}
.hosting_plan_content_item{
    max-width: 765px;
    margin: 40px auto 0;
    .detail-item{
        display: flex;
        align-items: center;
        padding: 40px 0;
        border-bottom: 1px dashed rgba($main-color, .33);
        position: relative;
        .item-img{
            width: 100%;
            max-width: 20%;
            &:after{
                position: absolute;
                content: '';
                bottom: 0;
                left: 55px;
                height: 50%;
                width: 1px;
                border-right: 1px dashed rgba($main-color, .5);
            }
            img{
                position: relative;
                z-index: 3;
            }
        }
        .item-detail{
            width: 100%;
            max-width: 80%;
            padding-left: 30px;
            h4{
                font-size: 18px;
                font-weight: 700;
                position: relative;
                padding-bottom: 15px;
                margin-bottom: 0;
                &:after{
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    @extend .main-color-bg;
                    width: 20px;
                    height: 1px;
                }
            }
            p{
                margin: 20px 0 0;
                font-size: 14px;
            }
        }
        &.img-right{
            .item-img{
                order: 2;
                &:after{
                    left: inherit;
                    bottom: inherit;
                    top: 0;
                    right: 102px;
                }
            }
            .item-detail{
                text-align: right;
                padding-left: 0;
                padding-right: 30px;
                h4{
                    &:after{
                        left: inherit;
                        right: 0;
                    }
                }
            }
        }
        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

@include mq-max(757px) {
    .hosting_plan_content_item .detail-item .item-img {
        max-width: 35%;
    }
}

@include mq-max(575px) {
    .hosting_plan_content_item .detail-item {
        
        flex-wrap: wrap;
        .item-img {
            max-width: 100%;
            margin-bottom: 20px;
            &:after{
                content: none;
            }
        }
        .item-detail{
            max-width: 100%;
            padding-left: 0;
        }
        &.img-right .item-img {
            order: inherit;
            text-align: right;
        }
    }
}