.single-process-1{
	margin-top: 35px;
	display: flex;
	.icon{
		padding-right: 25px;
		span{
			font-size: 50px;
			color: #edeef0;
			@include font-three-7;
			display: block;
			text-align: right;
		}
	}
	.process-1-content{
		width: 100%;
		max-width: 100%;
		h6{
			font-size: 18px;
			color : $main_color;
			@include font-one-6;
			transition: all .3s;

		}
		p{
			color: #808c9e;
			font-size: 14px;
			@include font-one-5;
			max-width: 272px;
		}
	}
	&:hover{
		.process-1-content{
			h6{
				color : $main_two;
				transition: all .3s;
			}

		}

	}
}

.process-1 {
	margin-top: -50px;
	z-index: 55;
	position: relative;
	.inner-box{
		box-shadow: 0px -1px 14.56px 1.44px rgba(167, 175, 250, 0.17);
		padding: 50px;
		background: #fff;
		border-radius: 10px;
	}

}

@include mq-max(575px) {
	.process-1 {
		.section-head-3 {
			font-size: 20px;
		}
		.inner-box {
			padding: 35px;
		}
	}
	

	.single-process-1{
		flex-wrap: wrap;
		.icon{
			padding-right: 0px;
			margin-bottom: 20px;
			width: 100%;
			display: flex;
			span{
				display: inline-block;
				line-height: 50px;
				margin-left: auto;
			}
		}
	}
}